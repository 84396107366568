"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Database = void 0;
const with_async_ittr_1 = require("idb/with-async-ittr");
const utils_1 = require("../utils");
var Version;
(function (Version) {
    Version[Version["New"] = 0] = "New";
    Version[Version["V1"] = 10] = "V1";
    Version[Version["V2"] = 20] = "V2";
})(Version || (Version = {}));
class Database {
    constructor(name) {
        this.db = (0, utils_1.createDeferred)();
        this.name = name !== null && name !== void 0 ? name : 'bf_sdk';
        this.init();
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            const db = yield (0, with_async_ittr_1.openDB)(this.name, Version.V2, {
                upgrade(db, oldVersion, _newVersion, transaction) {
                    var _a, e_1, _b, _c;
                    return __awaiter(this, void 0, void 0, function* () {
                        if (oldVersion === Version.New) {
                            db.createObjectStore('devicekeys', { keyPath: 'id', autoIncrement: true })
                                .createIndex('sessionUUID', 'sessionUUID');
                            db.createObjectStore('issues', { keyPath: 'id', autoIncrement: true })
                                .createIndex('sessionUUID', 'sessionUUID');
                            db.createObjectStore('logs', { keyPath: 'id', autoIncrement: true })
                                .createIndex('sessionUUID', 'sessionUUID');
                            db.createObjectStore('sessions', { keyPath: 'uuid' });
                        }
                        if (oldVersion === Version.V1) {
                            const issues = transaction.objectStore('issues');
                            try {
                                for (var _d = true, issues_1 = __asyncValues(issues), issues_1_1; issues_1_1 = yield issues_1.next(), _a = issues_1_1.done, !_a;) {
                                    _c = issues_1_1.value;
                                    _d = false;
                                    try {
                                        const cursor = _c;
                                        const issue = Object.assign({}, cursor.value);
                                        issue.data.text = issue.data.markdown;
                                        delete issue.data.markdown;
                                        cursor.update(issue);
                                    }
                                    finally {
                                        _d = true;
                                    }
                                }
                            }
                            catch (e_1_1) { e_1 = { error: e_1_1 }; }
                            finally {
                                try {
                                    if (!_d && !_a && (_b = issues_1.return)) yield _b.call(issues_1);
                                }
                                finally { if (e_1) throw e_1.error; }
                            }
                        }
                    });
                }
            });
            this.db.resolve(db);
        });
    }
    get() {
        return this.db.promise;
    }
}
exports.Database = Database;

const aprox = function(v1, v2, epsilon) {
    if (epsilon == null) {
        epsilon = 0.001;
    }
    return Math.abs(v1 - v2) < epsilon;
};

export const getAspectRatio = (width, height) => {
    if (!width || !height) {
        return `aspect-square`;
    }
    
    const ratio = width / height;

    // Check for square ratio
    if (aprox(ratio, 1, 0.1)) {
        return `aspect-square`;
    } 
    
    // Check for 4:3 ratio
    if (aprox(ratio, 4/3, 0.05)) {
        return `aspect-[4/3]`;
    }
    
    // Check for 3:4 ratio
    if (aprox(ratio, 3/4, 0.05)) {
        return `aspect-[3/4]`;
    }
    
    // Check for 5:4 ratio
    if (aprox(ratio, 5/4, 0.05)) {
        return `aspect-[5/4]`;
    }
    
    // Check for 4:5 ratio
    if (aprox(ratio, 4/5, 0.05)) {
        return `aspect-[4/5]`;
    }
    
    if (ratio > 1) {
        return `aspect-[16/10]`;
    }
    
    return `aspect-[10/16]`;
}

export const getCalendarDurationPerAspectRatio = (aspectRatio) => {
    return aspectRatio == "aspect-[10/16]" ? '02:30' : aspectRatio == "aspect-[16/10]" ? '01:15' : '01:30';
}

export const getHourDurationPerAspectRatio = (aspectRatio) => {
    return aspectRatio == "aspect-[10/16]" ? 2.5 : aspectRatio == "aspect-[16/10]" ? 1.25 : 1.5;
}
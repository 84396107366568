import appConfig from "configs/app.config";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Api from "../services/Api";
import Cookies from 'universal-cookie';
import { useSelector } from "react-redux";
import { CompanyContext } from "./CompanyContext";
import axios from "axios";

const SessionContext = React.createContext({
	session: undefined,
	user: undefined,
	actions: [],
	updateUserData: () => void 0,
	updateBalance: () => void 0
});

const SessionProvider = ({ children }) => {

    const locale = useSelector((state) => state.locale.currentLang);
	const [session, setSession] = useState({ isLoggedIn: false, user: undefined });
	const [dummyGetBalance, setDummyGetBalance] = useState();

	const actions = {
		login: async (token) => {
			try {
				localStorage.setItem("token", token);
				await updateUserData();
			} catch (e) {
				setSession({ isLoggedIn: false, user: undefined });
				localStorage.removeItem("token");
				localStorage.removeItem("user");
				console.error(e);
			}
		},
		logout: async () => {
			try {
				await Api.post("/logout", {}, {}, {});
				setSession({ isLoggedIn: false, user: undefined });
				localStorage.removeItem("token");
				localStorage.removeItem("user");
			} catch (e) {
				console.error(e);
			}
		},
	};

	const updateUserData = async () => {
		await Api.get(`/users/me?include=roleUsers,roleUsers.role,image,companies.role,companies.image,companies.vistaTemplates,companies.user,subscriptionTokens`, {}).then(async ({ data: user }) => {
			try {
				await Api.get(`/subscriptions?include=items.product,items.price`, {}, {}).then((response) => {
					if (response?.data?.stripe_status == "canceled" && new Date(response?.data?.ends_at) < new Date()) {
						user = {...user, subscription: undefined};
					} else {
						user = {...user, subscription: response.data};
					}
					setSession({ isLoggedIn: true, user: user });
				});
			} catch (err) {
				setSession({ isLoggedIn: true, user: user });
			}

			try {
				let company_id = _getActualCompanyID();
				if (company_id) {
					await Api.get(`/subscriptions/balance${company_id ? `?company_id=${company_id}` : ``}`, {}, {}).then((response) => {
						user = {...user, balance: response.data};
						setSession({ isLoggedIn: true, user: user });
					});
				}
			} catch (err) {
				setSession({ isLoggedIn: true, user: user });
			};
			
			const cookies = new Cookies();
			const referral = cookies.get("referral");
			if (referral) {
				if (new Date(user?.created_at) + 24 * 3600 * 1000 > new Date()) {
					Api.put(`/users/${user?.id}`, {}, { referral_code: referral }).then((response) => {
						cookies.remove("referral");
					});
				}
			}
			try {
				if (!user?.referrer_url && !user?.land_url && new Date(user?.created_at) > new Date("2024-05-29 13:00:00")) {
					Api.put(`/users/${user?.id}`, {}, { 
						referrer_url: cookies.get("referrer_url") || `null`, 
						land_url: cookies.get("land_url") || `null` 
					}).then((response) => {});
				}
			} catch (err) {};
			try {
				if (!user?.language) {
					if (locale) {
						Api.put(`/users/${user?.id}`, {}, { language: locale }).then((response) => {});
					}
				}
			} catch (err) {};
			try {
				if (!user?.timezone) {
					const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
					if (timezone) {
						Api.put(`/users/${user?.id}`, {}, { timezone: timezone }).then((response) => {
						});
					}
				}
			} catch (err) {};
			try {
				window.Intercom('boot', {
					app_id: 'p63otiu3',
					name: user?.name,
					email: user?.email,
					user_id: user?.id,
					created_at: Math.ceil(new Date(user?.created_at).getTime() / 1000),
					company_count: user?.companies?.length,
					own_company_count: user?.companies?.filter(item => item.role.name == 'admin')?.length,
					invited_company_count: user?.companies?.filter(item => item.role.name != 'admin')?.length,
					subscription_plan: user?.subscription?.items?.[0]?.product?.name,
					subscription_status_list: user?.subscription ? user?.subscription?.stripe_status : 'no_subscription',
					language: user?.language ? user?.language : "en",
					credit_card_last_four: user?.pm_last_four,
					credit_card_type: user?.pm_type
				});
			} catch (err) {}
			// try {
			// 	window?.posthog.identify(
			// 		user?.id, 
			// 		{ email: user?.email, name: user?.name }
			// 	);
			// } catch (err) {}
			// If account created
			// try {
			// 	if (new Date(user?.created_at).getTime() + 1000 * 60 * 30 > new Date().getTime() && !localStorage.getItem(`notify_proven`)) {
			// 		axios.get(`https://www.cloudflare.com/cdn-cgi/trace`).then(({data: data}) => {
			// 			data = data.trim().split('\n').reduce(function(obj, pair) {
			// 				pair = pair.split('=');
			// 				return obj[pair[0]] = pair[1], obj;
			// 			}, {});
			// 			axios.post(`https://api.provesrc.com/webhooks/track/60ac48fd80f2f160dea6eae76b7ae3ac`, {
			// 				"email": user?.email,
			// 				"timestamp": new Date().getTime(), 
			// 				"firstName": user?.name || undefined, 
			// 				"ip": data?.ip,
			// 				"countryCode": data?.loc,
			// 			}).catch(() => {
							
			// 			});
			// 		}).catch(() => {

			// 		})
			// 		localStorage.setItem(`notify_proven`, 1);
			// 	}
			// } catch (err) {}
			try {
				let weight = 1;
				if (user?.subscription?.stripe_status == "active") weight = 2;
				if (user?.subscription?.items?.[0]?.product?.name == "team") weight = 3;
				if (user?.subscription?.items?.[0]?.product?.name == "agency") weight = 4;
				window.$sleek.setUser({
					mail: user?.email, 
					id: user?.id,
					name: user?.name,
					img: user?.image?.url,
					weight: weight,
				});
			} catch (err) {}
			setDummyGetBalance(Math.random());
		}).catch((err) => {
			setSession({ isLoggedIn: false, user: undefined })
			localStorage.removeItem("token");
			localStorage.removeItem("user");
		})
	};

	const updateBalance = async () => {
		if (session?.user) {
			try {
			   let company_id = _getActualCompanyID();
			   if (!company_id) {
					company_id = session?.user?.companies?.[0]?.id;
			   }
			   if (company_id) {
				   Api.get(`/subscriptions/balance${company_id ? `?company_id=${company_id}` : ``}`, {}, {}).then((response) => {
					   const balance = response.data;
					   let newUser = session?.user;
					   newUser.balance = balance;
					   setSession({ isLoggedIn: true, user: newUser });
					   try {
							window.Intercom('update', {
								"balance_words_spent": balance?.words_spent,
								"balance_words_allowed": balance?.words_allowed,
								"balance_companies_spent": balance?.companies_spent,
								"balance_companies_allowed": balance?.companies_allowed,
								"balance_users_spent": balance?.users_spent,
								"balance_users_allowed": balance?.users_allowed,
								"balance_postGroups_spent": balance?.postGroups_spent,
								"balance_postGroups_allowed": balance?.postGroups_allowed,
								"balance_words_renews_at": Math.ceil(new Date(balance?.words_renews_at).getTime() / 1000),
							});
					   } catch (err) {}
				   });
			   }
		   } catch (err) {};
		}
	}

	const _getActualCompanyID = () => {
		let company_id;
		if (localStorage.getItem(`actualCompanyID${session?.user?.id}`)) {
			company_id = localStorage.getItem(`actualCompanyID${session?.user?.id}`);
		}
		return company_id;
	}

	useEffect(() => {
		updateBalance();
	}, [dummyGetBalance]);

	useEffect(() => {
		const token = localStorage.getItem("token");
		const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
		if (token && user) {
			setSession({ isLoggedIn: true, user: JSON.parse(localStorage.getItem("user")) });
			updateUserData(token);
		}
	}, []);

	useEffect(() => {
		if (session?.user) {
			localStorage.setItem("user", JSON.stringify(session?.user));
		}
	}, [session]);

	return (
		<SessionContext.Provider value={{
			session, 
			actions, 
			updateUserData,
			updateBalance,
			user: session?.user,
			isLoggedIn: session?.isLoggedIn
		}}>
			{children}
		</SessionContext.Provider>
	);
};

export { SessionContext, SessionProvider };
import { Badge, Button, Dialog, Notification, Radio, Spinner, toast } from 'components/ui'
import React, { useContext, useEffect, useState } from 'react'
import ParticleBackground from 'react-particle-backgrounds'
import { particleBackgroundSettings, particleBackgroundSettingsButton } from 'configs/particle-background.config';
import { BsStars } from 'react-icons/bs';
import { TbCircleCheck, TbCircleCheckFilled } from 'react-icons/tb';
import CardDialog from 'views/subscription/CardDialog';
import Api from 'services/Api';
import { formatNumber } from 'utils/formatNumber';
import { HiRocketLaunch } from 'react-icons/hi2';
import { Trans } from 'react-i18next';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SessionContext } from 'contexts/SessionContext';
import { ModalContext } from 'contexts/ModalContext';

export default function PlusModal() {
  
  const stripe = useStripe();
  const elements = useElements();

  const { updateUserData } = useContext(SessionContext);
  const { closeModal } = useContext(ModalContext);

  const [ cardOpened, setCardOpened ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ billingFrequency, setBillingFrequency ] = useState('year')
  const [ item, setItem ] = useState();
  const [ subscriptionCreated, setSubscriptionCreated ] = useState(false);

  const onSubmit = async (event) => {
    event && event?.preventDefault();
    setIsSubmitting(true);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    if (error) {
      setIsSubmitting(false);
      toast.push(<Notification title={error.message} type="warning" />, { placement: 'top-center' });
      return;
    }

    Api.post(`/subscriptionTokens/plus`, {}, {
      payment_method_id: paymentMethod?.id || undefined,
      recurring: billingFrequency
    }).then((response) => {
      setSubscriptionCreated(true);
      updateUserData();
      toast.push(<Notification title="Your subscription has been successfully modified." type="success" />, { placement: 'top-center' });
    }).catch((err) => {
      toast.push(<Notification title={err.message} type="danger" />, { placement: 'top-center' });
    }).finally(() => {
      setIsSubmitting(false);
    });
  }

  const handleOnClick = () => {
    setCardOpened(true);
  }

  const handleOnCloseCardDialog = () => {
    setCardOpened(false);
    if (subscriptionCreated) {
      closeModal();
    }
  }
  
  useEffect(() => {
    setIsLoading(true);
    Api.get(`/products?include=prices`).then(({ data: products }) => {
      setItem(products.find(e => e.name == `followr-plus`));
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  return (<>
    {!cardOpened
      ? <div className='relative'>
        <div className='bg-gradient-primary rounded-2xl overflow-hidden relative max-h-[90svh] overflow-y-auto'>
          <ParticleBackground settings={particleBackgroundSettingsButton} className="absolute pointer-events-none" />
          <div className='w-full h-full flex flex-col gap-8 lg:gap-10 items-center justify-center p-4 lg:p-8 py-8 lg:py-12 relative z-[1]'>
            <h2 className='mega-title text-white flex items-center gap-2'>
              <img 
                src="/img/logo/logo-dark-full.png" 
                className='h-8'
              />
              <span className='plus'>Plus</span>
              <BsStars className='stars' />
            </h2>
            <h3 className='text-white text-center text-lg lg:text-2xl font-black'>
              Take your content creation to the next level
            </h3>
            <p className='text-gray-300 text-center text-sm -mt-4 lg:-mt-6 font-bold'>
              Upgrade your entire creative process with Followr <span className='plus'>Plus</span>. Experience total enhancements with the latest and best AI models available.
            </p>
            <div className='grid lg:grid-cols-2 gap-2'>
              <div className='flex items-center gap-1.5 px-4 relative'>
                <div className='relative'>
                  <span className='block absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[80%] h-[80%] bg-white rounded-full' />
                  <TbCircleCheckFilled 
                    className='text-3xl text-green-500 relative z-[1]'
                  />
                </div>
                <span className='text-white text-xs font-bold'>Enhanced content creation tools</span>
              </div>
              <div className='flex items-center gap-1.5 px-4 relative'>
                <div className='relative'>
                  <span className='block absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[80%] h-[80%] bg-white rounded-full' />
                  <TbCircleCheckFilled 
                    className='text-3xl text-green-500 relative z-[1]'
                  />
                </div>
                <span className='text-white text-xs font-bold'>Innovative idea generation support</span>
              </div>
              <div className='flex items-center gap-1.5 px-4 relative'>
                <div className='relative'>
                  <span className='block absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[80%] h-[80%] bg-white rounded-full' />
                  <TbCircleCheckFilled 
                    className='text-3xl text-green-500 relative z-[1]'
                  />
                </div>
                <span className='text-white text-xs font-bold'>Improved image quality rendering</span>
              </div>
              <div className='flex items-center gap-1.5 px-4 relative'>
                <div className='relative'>
                  <span className='block absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[80%] h-[80%] bg-white rounded-full' />
                  <TbCircleCheckFilled 
                    className='text-3xl text-green-500 relative z-[1]'
                  />
                </div>
                <span className='text-white text-xs font-bold'>Reliable virtual assistance</span>
              </div>
            </div>

            <div className='flex flex-col gap-0 items-center'>
              <p className='text-white font-semibold text-sm'>For only</p>
              <h1 className='mega-title !text-5xl group-[.is-active]:!text-white text-right text-white'>
                {isLoading
                  ? <Spinner size="48" />
                  : <>${formatNumber((item?.prices?.filter(e => e.recurring == billingFrequency)[0])?.amount / 100, 2)}</>
                }
              </h1>
              <div className='flex gap-2 mt-4'>
                <div className={`transition-all cursor-pointer flex items-center justify-between text-white gap-2 ${billingFrequency == `month` && `p-1 px-2.5 bg-white font-semibold !text-gray-900 rounded-full`}`} >
                  <span onClick={() => setBillingFrequency(`month`)}>
                    <Trans i18nKey={`subscription.monthly`}>Monthly</Trans>
                  </span>
                </div>
                <div className={`transition-all cursor-pointer flex items-center justify-between text-white gap-2 ${billingFrequency == `year` && `p-1 px-2.5 bg-white font-semibold !text-gray-900 rounded-full`}`} >
                  <span className='flex items-center gap-2 w-full' onClick={() => setBillingFrequency(`year`)}>
                    <span><Trans i18nKey={`subscription.annualy`}>Annualy</Trans></span> 
                    <Button variant="ai" className='-mr-1 font-bold rounded-lg !p-1 !px-2.5 inline-block !h-auto !text-xs flex gap-1.5 items-center bg-primary w-auto'>
                      <HiRocketLaunch />
                      <span>-35%</span>
                    </Button>
                  </span>
                </div>
              </div>
            </div>

            <div className='flex justify-center gap-2 mt-2'>
              <Button 
                variant="ai"
                icon={<BsStars />}
                onClick={handleOnClick}
              >
                Upgrade now
              </Button>
            </div>
          </div>
        </div>
        {/* <img src="/img/others/behind-wall-top.png" className='absolute top-0 translate-y-[-71.5%] left-[10%] w-[402w%] drop-shadow-xl' /> */}
      </div>
      : <CardDialog 
        isOnlyContent={false}
        cardDialogOpened={cardOpened}
        setCardDialogOpened={() => handleOnCloseCardDialog()}
        handleSubmitStripe={onSubmit}
        isSubmittingCard={isSubmitting}
        subscriptionCreated={subscriptionCreated}
        isPlus={true}
      />
    }
  </>)
}

import { Alert, Avatar, Badge, Button, Card, Dialog, Skeleton, Tooltip } from 'components/ui';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { HiOutlineCheckCircle, HiOutlineDownload, HiOutlinePhotograph, HiOutlineVideoCamera } from 'react-icons/hi';
import getFileType, { getFileTypeImageVideo } from 'utils/fileType';
import { Draggable } from '@fullcalendar/interaction';
import HiOutlineGif from 'components/shared/HiOutlineGif';
import { UploadContext } from 'contexts/UploadContext';
import { HiOutlineChatBubbleBottomCenterText, HiOutlineTrash } from 'react-icons/hi2';
import { MediaContext } from 'contexts/MediaContext';
import { getAspectRatio, getCalendarDurationPerAspectRatio } from 'utils/getAspectRatio';
import { SocialIcon } from 'react-social-icons';
import { Loading, UsersAvatarGroup } from 'components/shared';
import capitalize from 'components/ui/utils/capitalize';
import { TbShare3 } from 'react-icons/tb';
import { CopyContext } from 'contexts/CopyContext';
import useDarkMode from 'utils/hooks/useDarkMode';
import { useNavigate } from 'react-router-dom';
import acronym from 'utils/acronym';
import useTwColorByName from 'utils/hooks/useTwColorByName';
import { Trans } from 'react-i18next';
import useVistaCreate from 'utils/hooks/useVistaCreate';
import MagicEditButton from 'components/posts_previews/components/MagicEditButton';
import ConfigPanelPostTypes from 'views/post_playground/ConfigPanelPostTypes';
import ConfirmModal from 'components/shared/ConfirmModal';
import { ModalContext } from 'contexts/ModalContext';
import { AIContext } from 'contexts/AIContext';

const MediaElement = ({ 
  id = "",
  className = "",
  src = "", 
  originalSrc = "", 
  aspectRatio = "", 
  forceType = "", 
  hideLayout = false,
  text = "", 
  networks = [],
  mediaDescription = "",
  isStock = false,
  isDownloadable = true,
  isEditable = true,
  stockUserProfileLink,
  stockUserProfileImage,
  stockUserName,
  stockPlatform,
  stockPlatformLink,
  stockPlatformAttributionImage,
  stockDownloadEndpoint,
  selected = false,
  isDraggable = false,
  isSelectable = false,
  onError = () => void 0,
  handleOnSelect = () => void 0,
  handleOnUnselect = () => void 0,
  name = "",
  asset = undefined,
  aiImageModel = "",
  showPublishButton = true,
  user = undefined
}) => {
  const bgColor = useTwColorByName();
  const mediaRef = useRef();
  const { openModal, closeModal } = useContext(ModalContext);
  const { aiModels } = useContext(AIContext);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const {addFilesAsURLUploadModal, urlToFileUploadModal, presendFileToAPIUploadModal } = useContext(UploadContext);
  const {deleteAssetMyMedia} = useContext(MediaContext);
  const {deleteAssetMyCopies} = useContext(CopyContext);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDark] = useDarkMode();
  const [isLoadingEditOpen, setIsLoadingEditOpen] = useState(false);
  const [isLoadingAsset, setIsLoadingAsset] = useState(true);
  const [showDescription, setShowDescription] = useState(false);
  const navigate = useNavigate();
  const videoRef = useRef();
  const isInitialMount = useRef(true);
  const { initEditor } = useVistaCreate();
  
  const aiImageModelObj = aiModels?.find(e => e?.model == aiImageModel);
  const userObj = user || asset?.user || undefined;
  
  if (asset && !asset?.original) {
    asset.original = asset?.image?.url || asset?.video?.url;
  }
  if (asset && !asset?.src) {
    asset.src = asset?.image?.thumbnail?.url || asset?.image?.url || asset?.video?.url;
  }
  
  let type = getFileType(asset?.original ? asset?.original : name ? name : src);
  if (forceType) type = forceType;
  if (!src && text) type = "text";
  if (asset?.type == "text") type = "text";
  
  // if (asset && asset?.type) {
  //     type = asset?.type;
  // }
  
  if (asset && type != "text") {
    src = asset?.src;
    originalSrc = asset?.original;
  }
  
  if (!aspectRatio && asset) {
    if (asset?.image?.url) {
      aspectRatio = getAspectRatio(asset?.image?.width, asset?.image?.height)
    }
    if (asset?.video?.url) {
      aspectRatio = getAspectRatio(asset?.video?.width, asset?.video?.height)
    }
  }
  
  var isPlaying = videoRef?.current?.currentTime > 0 && !videoRef?.current?.paused && !videoRef?.current?.ended && videoRef?.current?.readyState > videoRef?.current?.HAVE_CURRENT_DATA;
  
  const setStockDownloaded = () => {
    if (isStock && stockDownloadEndpoint) {
      fetch(stockDownloadEndpoint).then((response) => response);
    }
  }
  
  const onAssetDownload = () => {
    setStockDownloaded();
    addFilesAsURLUploadModal(originalSrc);
    setIsDownloading(true);
    setTimeout(() => {
      setIsDownloading(false);
    }, 5000);
  }
  
  const handleOnClickDelete = () => {
    // setIsDeleteModalOpen(true);
    openModal(
      <ConfirmModal 
      type={"danger"}
      title={`Delete asset`}
      onCancel={closeModal}
      onConfirm={onAssetDelete}
      confirmButtonColor={"red-700"}
      >
      <p>Are you sure you want to delete this asset? This action can't be undone.</p>
      {asset?.posts?.length && asset?.posts?.filter(e => e.status != "published")?.length && 
        <Alert type="danger" className="mt-4">This asset is related to an <i>unpublished</i> post.</Alert>
      }
      </ConfirmModal>,
      500
    );
  }
  
  const handleOnOpenEditor = () => {
    setIsLoadingEditOpen(true);
    initEditor(
      asset?.id ? asset : undefined,
      !asset?.id ? originalSrc : undefined,
      !asset?.id ? src : undefined
    ).catch(() => {
      setIsLoadingEditOpen(false);
    }).finally(() => {
      setIsLoadingEditOpen(false);
    });
    
    setTimeout(() => {
      setIsLoadingEditOpen(false);
    }, 10000);
  }
  
  const onDeleteModalClose = () => {
    setIsDeleting(false);
    setIsDeleteModalOpen(false);
    closeModal();
  }
  
  const onAssetView = () => {
    setDialogIsOpen(true)
  }
  
  const onAssetViewClose = (e) => {
    setDialogIsOpen(false)
  }
  
  const onAssetDelete = () => {
    setIsDeleting(true);
    if (type == "text") {
      deleteAssetMyCopies(id || asset?.id).then((response) => {
        onDeleteModalClose();
        setIsDeleting(false);
      });
    } else {
      deleteAssetMyMedia(id || asset?.id).then((response) => {
        onDeleteModalClose();
        setIsDeleting(false);
      });
    }
  }
  
  const onAssetPublish = (network) => {
    let _url = originalSrc || src;
    let _thumbnail = src || originalSrc;
    let _asset = asset ? asset : {
      image: getFileTypeImageVideo(_url) == "image" ? {
        url: _url,
        thumbnail: {
          url: _thumbnail
        }
      } : undefined,
      video: getFileTypeImageVideo(_url) == "video" ? {
        url: _url
      } : undefined,
    };
    navigate(`/calendar?postModal=${Math.random()}`, { state: { asset: _asset, network: network } });
  }
  
  const onAssetSelect = () => {
    setStockDownloaded();
    handleOnSelect(originalSrc, asset);
  }
  
  const onAssetUnselect = () => {
    handleOnUnselect(originalSrc, asset);
  }
  
  const isHTML = (str) => {
    var doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  }
  
  useEffect(() => {
    if (mediaRef.current && isDraggable) {
        isInitialMount.current = false;

        let currAssets;

        if (isStock) {
            const _url = originalSrc || src;
            const _thumbnail = src || originalSrc;

            currAssets = {
                image: getFileTypeImageVideo(_url) === "image" ? {
                    url: _url,
                    thumbnail: {
                        url: _thumbnail
                    }
                } : undefined,
                video: getFileTypeImageVideo(_url) === "video" ? {
                    url: _url
                } : undefined,
            };
        } else {
            const { id, vista_project_id, original } = asset || {};
            currAssets = id ? asset : {
                id,
                vista_project_id: vista_project_id || null,
                image: getFileTypeImageVideo(original) === "image" ? {
                    url: original,
                    thumbnail: {
                        url: asset?.src
                    }
                } : undefined,
                video: getFileTypeImageVideo(original) === "video" ? {
                    url: original
                } : undefined,
            };
        }

        if (currAssets) {
            new Draggable(mediaRef.current, {
                eventData: {
                    title: 'New event',
                    duration: getCalendarDurationPerAspectRatio(aspectRatio),
                    post_group: {
                        posts: [{
                            preferences: {
                                media_product_type: "FEED"
                            },
                            assets: [currAssets]
                        }]
                    }
                }
            });
        }
    }
  }, [mediaRef, src, isDraggable, isStock, asset, originalSrc]);
  
  if (!src && !text && !asset?.type) return <></>;
  if (!src && !originalSrc && (asset?.type == "image" || asset?.type == "video" || asset?.type == "gif")) return <></>;
  if (type == "text") return <></>;
  
  return (<>
    <Card
      key={asset?.id}
      ref={mediaRef}
      onMouseOver={e => videoRef?.current && !isPlaying && setTimeout(() => videoRef.current.play(), 160)}
      onMouseOut={e => videoRef?.current && videoRef.current.pause()}
      className={`group relative transition-all cursor-zoom-in ${isDraggable && `!cursor-pointer hover:drop-shadow-[0_10px_8px_rgba(0,0,0,0.2)] hover:rotate-[2deg]`}  ${selected && `border-[5px] rounded-2xl border-primary-600 shadow`} ${className}`}
      onClick={() => onAssetView()}
      bodyClass={`!p-0 ${isLoadingAsset && `!min-h-[150px]`}`}
    >
    {isLoadingAsset && 
      <Skeleton 
      variant="block"
      className="w-full h-full absolute left-0 top-0 z-[1]"
      />
    }
    {type == "image" && (
      <img src={src} onError={onError} onLoad={() => setIsLoadingAsset(false)} className={`w-full rounded-lg ${!showDescription && `group-hover:opacity-[0.85]`} transition-all`} />
    )}
    {type == "gif" && (
      <img src={originalSrc} onError={onError} onLoad={() => setIsLoadingAsset(false)} className={`w-full rounded-lg ${!showDescription && `group-hover:opacity-[0.85]`} transition-all`} />
    )}
    {type == "video" && (
      <video
        ref={videoRef}
        muted={true}
        src={src}
        preload="metadata"
        autoPlay={false}
        controls={false}
        onLoadedMetadata={() => setIsLoadingAsset(false)}
        className={`w-full rounded-lg ${!showDescription && `group-hover:opacity-[0.85]`} transition-all `}
      />
    )}
    
    {type == "image" && !hideLayout && (
      <div className="absolute top-3 right-4 drop-shadow z-[11]">
        <Tooltip title={<Trans i18nKey={'mediaElement.imageLabel'} />} placement="bottom-end">
          <HiOutlinePhotograph className="text-white text-xl" />
        </Tooltip>
      </div>
    )}
    {type == "gif" && !hideLayout && (
      <div className="absolute top-3 right-4 drop-shadow z-[11] rounded-lg">
        <Tooltip title={<Trans i18nKey={'mediaElement.gifLabel'} />} placement="bottom-end">
          <HiOutlineGif className="text-white text-xl" />
        </Tooltip>
      </div>
    )}
    {type == "video" && !hideLayout && (
      <div className="absolute top-3 right-4 drop-shadow z-[11]">
        <Tooltip title={<Trans i18nKey={'mediaElement.videoLabel'} />} placement="bottom-end">
          <HiOutlineVideoCamera className="text-white text-xl" />
        </Tooltip>
      </div>
    )}

    {aiImageModelObj && !hideLayout && (
      <div className="absolute top-3.5 right-12 drop-shadow z-[13] !cursor-default">
        <Tooltip title={aiImageModelObj?.fullName} placement="bottom-end">
          <img src={aiImageModelObj?.image} className={`w-4 h-4 ${aiImageModelObj?.driver == `ideogram` || aiImageModelObj?.driver == `openai` ? `invert` : ``}`} />
        </Tooltip>
      </div>
    )}
    
    {asset?.posts && asset?.posts?.length > 0 && !hideLayout && (
      <Badge className="absolute top-3 left-4 z-[11] bg-red-50 text-red-600 font-semibold" content={<Trans i18nKey={'mediaElement.usedLabel'} />} />
    )}
    
    {!hideLayout && <div className="absolute left-0 top-0 w-full h-[60%] z-10 flex flex-col justify-between p-4 pb-2 bg-gradient-to-t from-transparent via-transparent to-gray-900/60 rounded-lg"></div>}
    
    {!hideLayout && <div className="absolute left-0 top-0 w-full h-full z-[12] flex flex-col justify-between p-4 pb-2 bg-gradient-to-b from-transparent via-transparent to-gray-900/90 rounded-lg">
      {isStock && stockPlatformLink && (
        <div className={`opacity-0 pointer-events-none group-hover:opacity-[1] group-hover:pointer-events-normal flex gap-1.5 !text-[10px] absolute top-3 left-4 !text-white mt-0.5`}>
        {!stockPlatformAttributionImage ? (
          <>
          <Avatar
          shape="circle"
          size={16}
          src={stockUserProfileImage || ""}
          className={`${bgColor(stockUserName)} !text-[10px]`}
          >
          {acronym(stockUserName)}
          </Avatar>
          <span>
          {<Trans i18nKey={'mediaElement.byLabel'} />}{' '}
          <a href={stockUserProfileLink || `#`} className="underline" target="_blank">
          {stockUserName}
          </a>{' '}
          {<Trans i18nKey={'mediaElement.onLabel'} />}{' '}
          <a href={stockPlatformLink} className="underline" target="_blank">
          {stockPlatform}
          </a>
          </span>
          </>
        ) : (
          <>
          <a href={stockPlatformLink} className="underline" target="_blank">
          <img src={stockPlatformAttributionImage} className="h-[12px] w-auto mt-0.5" />
          </a>
          </>
        )}
        </div>
      )}
      
      {asset?.id ? (
        <div className="mb-4 flex items-center gap-1 h-5">
        {[...new Set([asset?.social_network_type, ...(asset?.children?.length ? asset?.children?.map(item => item?.social_network_type) : [])])]?.filter(e => e != null)?.map((network) => (
          <Tooltip title={capitalize(network)}>
          <SocialIcon network={network} className="!w-5 !h-5" fgColor={isDark ? `#F6F6F6` : undefined} />
          </Tooltip>
        ))}
        </div>
      ) : (
        <div></div>
      )}
      <div className="flex items-center justify-between mt-2 z-[12] relative">
      {!!userObj ? (
        <UsersAvatarGroup
        avatarProps={{ size: 25 }}
        className="hidden lg:inline-block"
        users={[userObj, ...new Set((asset?.children || [])?.map(e => e.user))].filter((value, index, self) => index === self.findIndex((t) => (t?.id === value?.id))) || []}
        />
      ) : (
        <div></div>
      )}
      <div className="flex items-center">
      {!!mediaDescription && 
        <Tooltip placement='top' title={`Description`}>
        <Button
        shape="round"
        variant="plain"
        size="sm"
        className="!text-white !bg-transparent"
        onClick={(e) => { e.stopPropagation(); setShowDescription(true); }}
        icon={<HiOutlineChatBubbleBottomCenterText />}
        />
        </Tooltip>
      }
      {!!asset?.id && !isSelectable && (
        <Tooltip title={<Trans i18nKey={'mediaElement.deleteLabel'} />}>
        <Button
        shape="round"
        variant="plain"
        size="sm"
        className="!text-white !bg-transparent"
        onClick={(e) => { e.stopPropagation(); handleOnClickDelete(); }}
        icon={<HiOutlineTrash />}
        />
        </Tooltip>
      )}
      {isEditable && !isSelectable && !isStock && 
        <Tooltip placement='bottom' title={<Trans i18nKey={`mediaElement.editLabel`} />}>
        <MagicEditButton 
        size="xs" 
        asset={asset} 
        />
        </Tooltip>
      }
      {!isSelectable && isDownloadable && !asset?.id && (
        <Tooltip title={<Trans i18nKey={'mediaElement.downloadLabel'} />}>
        <Button
        shape="circle"
        variant="plain"
        size="sm"
        className="!text-white !bg-transparent"
        onClick={(e) => { e.stopPropagation(); onAssetDownload(); }}
        disabled={isDownloading}
        icon={!isDownloading ? <HiOutlineDownload /> : <Loading type={'element'} />}
        />
        </Tooltip>
      )}
      {!isSelectable && showPublishButton && (
        <span onClick={(e) => e.stopPropagation()}>
        <ConfigPanelPostTypes
        postType={undefined}
        setPostType={(postType) => onAssetPublish(postType)}
        menuClassName="!max-h-[160px] !z-[150] zoom-90 max-w-[200px]"
        placement="top-end"
        hideChecks={true}
        renderTitle={
          <Tooltip title={<Trans i18nKey={'mediaElement.publishLabel'} />} wrapperClass="ml-1.5">
          <Button
          variant="solid"
          size="xs"
          shape="round"
          className="!rounded-xl"
          icon={<TbShare3 />}
          />
          </Tooltip>
        }
        />
        </span>
      )}
      {isSelectable && (
        <Tooltip title={<Trans i18nKey={'mediaElement.selectLabel'} />} wrapperClass="ml-1.5">
        {!selected 
          ? <Button
          shape="circle"
          variant=""
          size="xs"
          onClick={(e) => { e.stopPropagation(); onAssetSelect(); }}
          icon={<HiOutlineCheckCircle />}
          >
          {<Trans i18nKey={'mediaElement.selectLabel'} />}
          </Button>
          : <Button
          shape="circle"
          variant="solid"
          size="xs"
          onClick={(e) => { e.stopPropagation(); onAssetUnselect(); }}
          icon={<HiOutlineCheckCircle />}
          >
          {<Trans i18nKey={'mediaElement.selectedLabel'} />}
          </Button>
        }
        
        </Tooltip>
      )}
      </div>
      </div>
      </div>}
      
      <div onClick={(e) => e.stopPropagation()} className={`absolute pointer-events-none cursor-default cursor-normal opacity-0 rounded-lg z-[100] text-white ${showDescription && `!pointer-events-auto opacity-100`} left-0 top-0 w-full h-full flex justify-center items-center bg-black/90`}>
      <div className='flex justify-end items-center p-2 px-4 absolute top-0 left-0 w-full'>
      <span 
      className='text-2xl cursor-pointer' 
      onClick={(e) => { e.stopPropagation(); setShowDescription(false); }}
      >
      &times;
      </span>
      </div>
      <p className='text-center text-sm p-4 cursor-text overflow-y-auto max-h-full'>
      {mediaDescription}
      </p>
      </div>
      </Card>      
      
      <Dialog
      isOpen={dialogIsOpen}
      onClose={onAssetViewClose}
      onRequestClose={onAssetViewClose}
      closable={type != "text"}
      className={`! group !w-full !h-full !h-auto bg-transparent flex items-center justify-center shadow-none`}
      overlayClassName="flex items-center justify-center !z-[19000000]"
      contentClassName={`p-0 m-0 rounded-xl !overflow-y-visible !overflow-x-visible  shadow-none ${type != "text" ? `bg-transparent dark:bg-transparent flex flex-col lg:flex-row items-center justify-center w-full h-full` : `!bg-white dark:!bg-gray-800`}`}
      closeButtonClassName="!text-white drop-shadow !right-4 !top-2"
      >   
      {(type == "image" || type == "gif") && (
        <img src={originalSrc} className={`object-fit object-contain w-full h-full`}/>
      )}
      {type == "video" && (
        <video src={originalSrc} preload="metadata" autoPlay={false} controls={true} className={`w-full h-full object-fit object-contain `} />
      )}
      </Dialog>
      </>);
    }
    
    export default MediaElement
    


import { useEffect } from "react";

const useOnClickOutside = (ref, callback = () => void 0, ...dependencies) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            } 
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, ...dependencies]);
}
  
export default useOnClickOutside;
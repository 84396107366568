import BannedScreen from "components/layout/BannedScreen";
import React, { useContext } from "react";
import { SessionContext } from "./SessionContext";

const BannedContext = React.createContext({});

const BannedProvider = ({ children }) => {

  const { user, isLoggedIn } = useContext(SessionContext);

  let bannedModeOn = false;

  try {
    bannedModeOn = 
      !!isLoggedIn && 
      !!user?.id && 
      !!user?.banned_at &&
      new Date(user?.banned_at) < new Date()
  } catch (err) {}

  return (
    <BannedContext.Provider value={{}}>
      {bannedModeOn
        ? <BannedScreen />
        : children
      }
    </BannedContext.Provider>
  )
}

export { BannedContext, BannedProvider };
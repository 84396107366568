import { Badge, Button, Input, Tabs } from "components/ui";
import TabContent from "components/ui/Tabs/TabContent";
import TabList from "components/ui/Tabs/TabList";
import TabNav from "components/ui/Tabs/TabNav";
import React, { useCallback, useEffect, useState } from "react";
import { HiOutlineAdjustments, HiOutlineDocumentText, HiOutlinePhotograph, HiOutlineSearch, HiOutlineVideoCamera, HiOutlineBookmark, HiDeviceTablet, HiOutlineDeviceTablet } from "react-icons/hi";
import { getPexelsImages, getPexelsVideos, getPixabayImages, getPixabayVideos, getUnsplashImages } from "utils/stockImages";
import FilterBadge from "./FilterBadge";
import FollowrExploreCopy from "./FollowrExploreCopy";
import FollowrExploreMedia from "./FollowrExploreMedia";
import MediaElement from "./MediaElement";
import MediaUpload from "./MediaUpload";

const FollowrExplore = ({ mediaContainerRef }) => {

    return (
        <div className="-m-4">
            <Tabs defaultValue="tabMedia" variant="pill" className="w-full">
                <TabList className="w-full border-b border-gray-200 dark:border-gray-700 border-solid">
                    <TabNav value="tabMedia" className="flex-grow grow flex-1 !text-sm m-0 rounded-none py-[13px] flex items-center justify-center gap-1 px-0">
                        <HiOutlinePhotograph className='text-base' />
                        <span className='!text-[12px]'>Media</span>
                    </TabNav>
                    <TabNav value="tabCopy" className="flex-grow grow flex-1 !text-sm m-0 rounded-none py-[13px] flex items-center justify-center gap-1 px-0">
                        <HiOutlineDocumentText className='text-base' />
                        <span className='!text-[12px]'>Copy</span>
                    </TabNav>
                </TabList>
                <div className="p-4">
                    <TabContent value="tabMedia" className="gap-4 flex flex-col">
                        <FollowrExploreMedia mediaContainerRef={mediaContainerRef} />
                    </TabContent>
                    <TabContent value="tabCopy" className="gap-4 flex flex-col">
                        <FollowrExploreCopy mediaContainerRef={mediaContainerRef} />
                    </TabContent>
                </div>
            </Tabs>
        </div>
    )
}

export default FollowrExplore;
import React, { useState, useEffect, useContext } from 'react'
import { CompanyContext } from 'contexts/CompanyContext'
import Api from 'services/Api'
import useVistaCreate, { VISTA_CDN } from 'utils/hooks/useVistaCreate'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { HiOutlinePhotograph, HiOutlineSave } from 'react-icons/hi'
import { HiOutlineVideoCamera } from 'react-icons/hi2'
import FormDesription from './FormDesription'
import { Trans } from 'react-i18next'
import { Button, Checkbox, Notification, toast } from 'components/ui'
import { LuSave } from 'react-icons/lu'
import { SessionContext } from 'contexts/SessionContext'
import { Loading } from 'components/shared'
import useDbCreativeTemplates from 'utils/hooks/useDbCreativeTemplates'
import { PriorityModalContext } from 'contexts/PriorityModalContext'

const CreativeTemplates = ({ isModal = false }) => {

    const { closeModalPriority } = useContext(PriorityModalContext);
    const { company } = useContext(CompanyContext);
    const { updateUserData } = useContext(SessionContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ customTemplates, setCustomTemplates ] = useState([]);
    const [ selectedTemplatesIds, setSelectedTemplatesIds ] = useState([]);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const { getCustomMediaTemplates } = useVistaCreate();
    const { getTemplates } = useDbCreativeTemplates();

    const refresh = async () => {
        setIsLoading(true);
        let _customTemplates = await getCustomMediaTemplates().then(({ items: templates }) => templates);
        const _dbCustomTemplates = await getTemplates();
        _customTemplates = _customTemplates.filter(e => _dbCustomTemplates?.map(_db => _db?.external_id?.toLowerCase())?.includes(e?.id?.toLowerCase()))
        _customTemplates = _customTemplates.map(e => ({...e, id: _dbCustomTemplates.find(_db => _db?.external_id == e.id)?.id}))
        setSelectedTemplatesIds(company?.vistaTemplates?.map(e => e?.id)?.length ? company?.vistaTemplates?.map(e => e?.id) : _customTemplates?.map(e => e?.id));
        setCustomTemplates(_customTemplates);
        setIsLoading(false);
    }

    const handleOnClick = (id) => {
        if (!!selectedTemplatesIds.find(e => e == id)) {
            setSelectedTemplatesIds(selectedTemplatesIds.filter(e => e != id));
        } else {
            setSelectedTemplatesIds([...selectedTemplatesIds, id]);
        }
    }

    const handleOnSave = async () => {
        setIsSubmitting(true);
        try {
            await Api.put(`/companies/${company?.id}`, {}, { vista_template_ids: selectedTemplatesIds.length ? selectedTemplatesIds : null })
            updateUserData();
            isModal && closeModalPriority()
            toast.push(<Notification title="Templates saved successfully" type="success" />);
        } catch (err) {
            toast.push(<Notification title={err?.message || err} type="danger" />);
        }
        setIsSubmitting(false);
    }

    useEffect(() => {
        refresh();
    }, [company?.id]);

    return (<>
        {isLoading && <Loading type='element' />}
        {!!customTemplates?.length && 
            <div className="w-full flex flex-col gap-8">
                <FormDesription
                    className="flex-1"
                    title={<Trans i18nKey={`creativeTemplates.creativeTemplates`}>Creative Templates</Trans>}
                    desc={<Trans i18nKey={`creativeTemplates.creativeTemplatesDescription`}>Here you can choose what creative templates will be used when generating posts using AI.<br/>The logos, images, titles and texts on these templates will be replaced with {company?.name} related content when generating posts.</Trans>}
                />
                <div className='flex flex-col lg:flex-row items-center justify-between gap-4'>
                    <label 
                        className='flex items-center cursor-pointer' 
                        htmlFor='selectall'
                    >
                        <Checkbox
                            id="selectall"
                            className="mb-0 mr-2"
                            checked={selectedTemplatesIds.length >= customTemplates.length}
                            onChange={(value) => value 
                                ? setSelectedTemplatesIds(customTemplates.map(e => e.id)) 
                                : setSelectedTemplatesIds([])
                            }
                        />
                        <span className='text-sm'>Select all</span>
                    </label>
                    <div className='flex-1 flex gap-4 justify-end items-center'>
                        <span className='text-sm'>
                            Templates ({selectedTemplatesIds.length}/{customTemplates.length})
                        </span>
                        <Button 
                            variant="solid"
                            icon={<LuSave />}
                            onClick={() => handleOnSave()}
                            loading={isSubmitting}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                <ResponsiveMasonry className="w-full" columnsCountBreakPoints={{ 350: 2, 475: 3, 750: 4, 900: 5, 1200: 6 }}>
                    <Masonry gutter='1rem'>
                        {customTemplates.map((item, key) => (
                            <div onClick={() => handleOnClick(item?.id)} className={`cursor-pointer flex flex-col gap-1 items-center justify-center`}>
                                <div className={`group relative overflow-hidden rounded-2xl transition-all shadow-sm hover:shadow-2xl ${!!selectedTemplatesIds.find(e => e == item?.id) && `outline outline-4 outline-primary-600`}`}>
                                    <img 
                                        src={`${VISTA_CDN}${item?.previewImageUrls?.[0]}`}
                                        className="w-full transition-all"
                                    />
                                    {!!item?.previewScreenVideoUrl &&
                                        <video 
                                            src={`${VISTA_CDN}${item?.previewScreenVideoUrl}`}
                                            controls={false} 
                                            autoPlay={false} 
                                            preload={"auto"} 
                                            className="transition-all opacity-0 group-hover:opacity-100 absolute left-0 top-0 w-full h-full object-contain z-[2]"
                                            onMouseOver={e => e.target.play()}
                                            onMouseOut={e => e.target.pause()}
                                        />
                                    }
                                    
                                    <Checkbox
                                        className="mb-0 absolute left-4 top-4 pointer-events-none"
                                        checked={!!selectedTemplatesIds.find(e => e == item?.id)}
                                    />
                                </div>
                                {/* <b className="font-bold text-gray-900 dark:text-gray-100">{item?.format}</b> */}
                            </div>
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        }
    </>)
}

export default CreativeTemplates

import React, { useContext, useEffect, useState } from 'react'
import { Button, Dialog, Dropdown, Input, Notification, Progress, toast } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { HiOutlineChevronDown, HiOutlineChip, HiOutlineLightningBolt, HiOutlinePlusCircle } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/formatNumber';
import { BsCheckCircleFill, BsCircle, BsLayers } from 'react-icons/bs';
import { MdLayers } from 'react-icons/md';
import { HiOutlineSquare2Stack, HiOutlineSquare3Stack3D, HiOutlineUser, HiPlus } from 'react-icons/hi2';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import Api, { API_HOST } from 'services/Api';
import { SessionContext } from 'contexts/SessionContext';
import MySocialIcon from 'components/shared/MySocialIcon';
import { TbLoader3 } from 'react-icons/tb';
import { ModalContext } from 'contexts/ModalContext';
import InputModern from 'components/ui/Input/InputModern';
import { CalendarActiveSocialNetworkContext } from 'contexts/CalendarActiveSocialNetworkContext';
import capitalize from 'components/ui/utils/capitalize';
import { Trans } from 'react-i18next';

const FacebookIntegrationModal = () => {

    const { closeModalPriority } = useContext(PriorityModalContext);
    const { company } = useContext(CompanyContext);
    const { facebookPageSelectModalOpened } = useContext(CalendarActiveSocialNetworkContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isCollapsed, setIsCollapsed ] = useState(true);

    const network = "facebook";

    const handleSubmit = () => {
        window.open(`${API_HOST}/companies/${company?.id}/socialNetworks/${network}?token=${localStorage.getItem(`token`)}`);
        setIsLoading(true);
    }
    
    useEffect(() => {
        if (facebookPageSelectModalOpened == true) {
            closeModalPriority();
        }
    }, [facebookPageSelectModalOpened]);

    return (
        <>
            <div className='flex flex-col gap-7 pt-4'>
                <img src={`/img/socials-3d/${network}.png`} className='w-28 mx-auto drop-shadow' />
                {/* <h3 className='text-gray-900 mega-title text-center'><Trans i18nKey={`facebookIntegration.title`}>Facebook Integration</Trans></h3> */}
                
                {/* <div className='p-6 rounded-2xl border border-gray-200 dark:border-gray-700 bg-primary-600 !text-white !text-sm whitespace-pre-wrap'>
                    <big className='font-semibold'>⚠️&nbsp; Important!</big>
                    <br/>
                    <br/>
                    Meta’s automatic integrations are not yet available. However, you can gain access right now by initiating a chat with us. We'll help you set up the Meta’s integration promptly. Thank you for your patience!
                    <br/>
                    <br/>
                    <Button onClick={() => window?.Intercom('show')} size="md">Chat With Us</Button>
                </div> */}

                {/* <p className='text-center !text-sm'><Trans i18nKey={`facebookIntegration.quickAndEasy`}>Connecting your Facebook Page is quick and easy.</Trans></p> */}
                <p className='text-left !text-sm'>
                    <b className='block text-center'><Trans i18nKey={`facebookIntegration.permissions`}>Followr will request the following permissions</Trans>: <span onClick={() => setIsCollapsed(!isCollapsed)} className='text-primary-600 cursor-pointer'>{isCollapsed ? `View` : `Hide`} permissions</span></b>
                    <div className={`overflow-hidden transition-all rounded-2xl border border-gray-200 dark:border-gray-600 p-4 px-8 !text-sm bg-gray-50 dark:bg-gray-800 mt-4 ${isCollapsed && `hidden !h-0`}`}>
                        <ul className={`!list-disc`}>
                            <li><b>email</b>: Grants access to the user's primary email address associated with their Facebook account.</li>
                            <li><b>public_profile</b>: Provides access to the user's public profile information, such as name, profile picture, and other publicly available details.</li>
                            <li><b>pages_messaging</b>: Allows the app to send and receive messages on behalf of the user's Facebook Pages.</li>
                            <li><b>read_insights</b>: Enables the app to access analytics and insights data for the user's Facebook Pages.</li>
                            <li><b>pages_read_user_content</b>: Allows the app to read the content posted by the user on their Facebook Pages.</li>
                            <li><b>pages_read_engagement</b>: Grants access to engagement data (e.g., likes, comments) on the user's Facebook Pages.</li>
                            <li><b>publish_to_groups</b>: Allows the app to post content on behalf of the user to their Facebook Groups.</li>
                            <li><b>pages_manage_cta</b>: Enables the app to manage call-to-action buttons on the user's Facebook Pages.</li>
                            <li><b>pages_manage_instant_articles</b>: Allows the app to manage Instant Articles on the user's Facebook Pages.</li>
                            <li><b>pages_manage_posts</b>: Grants the app permission to manage posts on the user's Facebook Pages.</li>
                            <li><b>pages_manage_engagement</b>: Enables the app to manage user engagement (e.g., comments, likes) on the user's Facebook Pages.</li>
                            <li><b>pages_show_list</b>: Allows the app to display a list of the user's Facebook Pages they manage.</li>
                            <li><b>publish_video</b>: Enables the app to publish videos on behalf of the user.</li>
                            <li><b>instagram_basic</b>: Provides basic access to the user's Instagram account information.</li>
                            <li><b>instagram_manage_messages</b>: Grants access to manage messages on the user's Instagram account.</li>
                            <li><b>instagram_manage_comments</b>: Enables the app to manage comments on the user's Instagram account.</li>
                            <li><b>pages_manage_metadata</b>: Allows the app to manage metadata (e.g., title, description) for the user's Facebook Pages.</li>
                        </ul>
                    </div>
                </p>
                <p className='text-center !text-sm'><Trans i18nKey={`facebookIntegration.dueToLimitations`}>Due to Facebook limitations, you can't connect your Facebook Personal Profile, you can only connect a Facebook Page.</Trans></p>
                <p className='text-center !text-sm'><Trans i18nKey={`facebookIntegration.makeSureConnected`}>Make sure that you are connected to the right Facebook account before connecting to Followr.</Trans></p>
            </div>

            <div className='flex justify-end gap-2 p-4 -m-6 w-auto mt-10 border-t border-gray-200 dark:border-gray-700'>
                <Button onClick={closeModalPriority}><Trans i18nKey={`facebookIntegration.cancel`}>Cancel</Trans></Button>
                <Button loading={isLoading} variant="solid" onClick={handleSubmit} icon={<img src={`/img/socials/${network}.svg`} className="!h-4 !w-4 shrink-0" />}>&nbsp; <Trans i18nKey={`facebookIntegration.connectWith`}>Connect with Facebook</Trans></Button>
            </div>
        </>
    )
}

export default FacebookIntegrationModal

import { Button } from "components/ui";
import InputModern from "components/ui/Input/InputModern";
import { CompanyContext } from "contexts/CompanyContext";
import { AIContext } from "contexts/AIContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { HiOutlineCpuChip } from "react-icons/hi2";

import usePrompts from "utils/hooks/usePrompts";

const AskAIToWrite = ({ inputRef, value, fullText, selectedText, selectionStartEnd, setValue, setMenuOpened }) => {

    const [ placeholder, setPlaceholder ] = useState("");
    const [ about, setAbout ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    const { getByQueryChat } = useContext(AIContext);
    const { company } = useContext(CompanyContext);
    let chunkLength = 0;

    const placeholderOptions = company?.interests;

    const handleOnClick = async () => {
        setIsLoading(true);
        const query = `
Write 280 characters about ${about ? `: ${about}` : `something interest related to the company.
        
Text: `}`;
        getByQueryChat(query).then((response) => {
            const initialLeftPart = String(value)?.substring(0, selectionStartEnd.start);
            const initialRightPart = String(value)?.substring(selectionStartEnd.finish, String(value)?.length);    
            setValue(`${initialLeftPart}${response}${initialRightPart}`);
        }).finally(() => {
            setIsLoading(false);
            setMenuOpened(false);
        })
    }

    useEffect(() => {
        setPlaceholder(placeholderOptions[Math.floor(Math.random() * placeholderOptions?.length)]);
    }, []);

    return (
        <div className="flex flex-col gap-2">
            <InputModern 
                autoFocus
                textArea
                value={about}
                onChange={(e) => setAbout(e.target.value)}
                placeholder={`Try "${placeholder}"`}
            />
            <div className="items-center w-full">
                <Button loading={isLoading} disabled={isLoading} onClick={handleOnClick} className="w-full" variant="solid" icon={<HiOutlineCpuChip />}>&nbsp; Generate</Button>
            </div>
        </div>
    )
}

export default AskAIToWrite;
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageWriterSanitizer = void 0;
const common_1 = require("@bugfender/common");
class StorageWriterSanitizer {
    sanitize(message) {
        switch (message.type) {
            case 'device-key': {
                const isValidValue = ['boolean', 'number', 'string'].includes(typeof message.data.value);
                message.data.key = (0, common_1.mapToString)(message.data.key);
                if (!isValidValue) {
                    message.data.value = (0, common_1.mapToString)(message.data.value);
                }
                break;
            }
            case 'issue': {
                message.data.title = (0, common_1.mapToString)(message.data.title);
                message.data.text = (0, common_1.mapToString)(message.data.text);
                break;
            }
            case 'log-entry': {
                const strings = ['tag', 'method', 'file', 'text', 'url'];
                const hasLine = 'line' in message.data.log && typeof message.data.log.line !== 'undefined';
                const isValidLine = hasLine && typeof message.data.log.line === 'number';
                const hasLevel = 'level' in message.data.log && typeof message.data.log.level !== 'undefined';
                const isValidLevel = hasLevel && common_1.LOG_LEVELS.includes(message.data.log.level);
                strings.forEach(key => {
                    const hasValue = key in message.data.log && typeof message.data.log[key] !== 'undefined';
                    if (hasValue) {
                        try {
                            message.data.log[key] = (0, common_1.mapToString)(message.data.log[key]);
                        }
                        catch (_a) {
                            delete message.data.log[key];
                        }
                    }
                });
                if (hasLine && !isValidLine) {
                    try {
                        message.data.log.line = (0, common_1.mapToNumber)(message.data.log.line);
                    }
                    catch (_a) {
                        delete message.data.log.line;
                    }
                }
                if (hasLevel && !isValidLevel) {
                    try {
                        message.data.log.level = (0, common_1.mapToNumber)(message.data.log.level);
                        if (!common_1.LOG_LEVELS.includes(message.data.log.level)) {
                            delete message.data.log.level;
                        }
                    }
                    catch (_b) {
                        delete message.data.log.level;
                    }
                }
                break;
            }
        }
        return message;
    }
}
exports.StorageWriterSanitizer = StorageWriterSanitizer;

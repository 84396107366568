import React, { useContext } from 'react'
import { Button, Dropdown } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { HiOutlineChevronDown, HiOutlineCog, HiOutlinePlusCircle } from 'react-icons/hi';
import { SessionContext } from 'contexts/SessionContext';
import { useNavigate } from 'react-router-dom';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import OutOfCompaniesModal from './OutOfCompaniesModal';
import { ModalContext } from 'contexts/ModalContext';

const CompanyToggle = ({
    className = "ml-3",
    RenderTitle = undefined,
    showCreateNewCompany = true,
    showConfig = true,
    placement = "bottom-start"
}) => {

    const { company, setCompany } = useContext(CompanyContext);
    const { user } = useContext(SessionContext);
    const { openModalPriority } = useContext(PriorityModalContext);
    const navigate = useNavigate();
    const { closeModal } = useContext(ModalContext);
    const companies = user?.companies;

    const handleClickCreateNewCompany = () => {
        setCompany(undefined);
        closeModal();
    }

    const handleClickCompany = (_company) => {
        setCompany(_company);
        closeModal();
    }

    if (!companies || !companies?.length) return;

    return (
        <div className={`hidden md:flex ${className}`}>
            <Dropdown 
                placement={placement}
                menuClass="max-h-[300px] overflow-y-auto max-w-[220px] md:max-w-none md:min-w-[250px]"
                renderTitle={
                    RenderTitle != undefined 
                        ? RenderTitle
                        : <div className='flex'>
                            <Button variant="twoTone" className={`flex gap-2 items-center justify-between !px-3 min-w-[150px] !h-10 ${(!company || company?.role?.name != "admin") ? `!rounded-l-2xl !rounded-r-2xl` : `!rounded-l-2xl !rounded-r-none`}`}>
                                {company ?
                                    <div className='flex items-center gap-2'>
                                        {company?.image?.url  
                                            ? <img src={company?.image?.url} className="h-6 rounded" />
                                            : <div className='flex items-center justify-center h-6 w-6 rounded-full bg-primary-600 text-white'>{company?.name[0]}</div>
                                        }
                                        <span>{company?.name ? company?.name : <i className='font-normal'>New company</i>}</span>
                                    </div>
                                    :
                                    <div className='flex items-center gap-2'>
                                        <HiOutlinePlusCircle className='h-6 text-2xl' />
                                        <span>Create new company</span>
                                    </div>
                                }
                                <HiOutlineChevronDown />
                            </Button>
                        </div>
                }
            >
                {showCreateNewCompany && <Dropdown.Item eventKey="new_item" className="!bg-transparent !px-2 py-2 w-full" onClick={() => handleClickCreateNewCompany()}>
                    <Button variant="solid" className="flex gap-2 items-center !px-4 w-full">
                        <HiOutlinePlusCircle className='h-6 md:text-2xl' />
                        <span>Create new company</span>
                    </Button>
                </Dropdown.Item>}
                {companies && companies.filter(item => item.role.name == 'admin')?.length > 0 && <>
                    {showCreateNewCompany && <Dropdown.Item variant="divider" />}
                    <div className='px-3 py-2 font-bold'>MY COMPANIES</div>
                </>}
                {companies && companies.filter(item => item.role.name == 'admin').map((item, key) => (
                    <Dropdown.Item className={`!h-10 py-2 ${company?.id == item.id ? `!bg-primary-50 dark:!bg-gray-800` : ``}`} key={key} onClick={() => handleClickCompany(item)}>
                        {item?.image?.url  
                            ? <img src={item?.image?.url} className="h-5 w-5 object-cover rounded" />
                            : <div className='flex items-center justify-center h-5 w-5 rounded-full bg-primary-600 text-white'>{item?.name[0]}</div>
                        }
                        <span>{item?.name ? item?.name : <i className='font-normal'>New company</i>}</span>
                    </Dropdown.Item>
                ))}
                {companies && companies.filter(item => item.role.name !== 'admin')?.length > 0 && <>
                    <Dropdown.Item variant="divider" />
                    <div className='px-3 py-2 font-bold'>OTHER COMPANIES</div>
                </>}
                {companies && companies.filter(item => item.role.name !== 'admin').map((item, key) => (
                    <Dropdown.Item className={`!h-10 py-2 ${company?.id == item.id ? `!bg-primary-50 dark:!bg-gray-800` : ``}`} key={key} onClick={() => handleClickCompany(item)}>
                        {item?.image?.url  
                            ? <img src={item?.image?.url} className="h-5 w-5 object-cover rounded" />
                            : <div className='flex items-center justify-center h-5 w-5 rounded-full bg-primary-600 text-white'>{item?.name[0]}</div>
                        }
                        <span>{item?.name ? item?.name : <i className='font-normal'>New company</i>}</span>
                    </Dropdown.Item>
                ))}
            </Dropdown>
            {company && company?.role?.name == "admin" && showConfig &&  
                <Button variant="twoTone" onClick={() => navigate(`/company-settings/profile`)} className="flex gap-2 items-center justify-between !px-3 !h-10 !rounded-r-2xl !rounded-l-none !border-l !border-primary-600/10 !w-auto !min-w-none dark:!border-l dark:border-gray-800 dark:!border-t-0 dark:!border-r-0 dark:!border-b-0">
                    <HiOutlineCog className='!text-xl' />
                </Button>
            }
        </div>
    )
}

export default CompanyToggle
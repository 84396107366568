import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CompanyContext } from "./CompanyContext";
import { SessionContext } from "./SessionContext";
import FacebookPageSelectModal from "components/layout/SocialIntegrationModals/FacebookPageSelectModal";
import LinkedInPageSelectModal from "components/layout/SocialIntegrationModals/LinkedInPageSelectModal";
import InstagramPageSelectModal from "components/layout/SocialIntegrationModals/InstagramPageSelectModal";
import { Notification, toast } from "components/ui";
import { capitalize } from "lodash";
import { checkIfIsExpired } from "utils/checkIfIsExpired";
import { checkIfIsIntegrated } from "utils/checkIfIsIntegrated";
import ConnectASocialNetworkModal from "components/layout/ConnectASocialNetworkModal";
import SocialNetworkErrorModal from "components/layout/SocialIntegrationModals/SocialNetworkErrorModal";
import { socialNetworks } from "views/calendar/SocialNetworkSidePanel";

const CalendarActiveSocialNetworkContext = React.createContext({
    calendarActiveSocialNetwork: undefined,
    setCalendarActiveSocialNetwork: (calendarActiveSocialNetwork) => void 0,
    setInstagramPageSelectModalOpened: (value) => void 0,
    setFacebookPageSelectModalOpened: (value) => void 0,
    setLinkedInPageSelectModalOpened: (value) => void 0,
    setSocialNetworkErrorOpened: (value) => void 0,
    instagramPageSelectModalOpened: false,
    facebookPageSelectModalOpened: false,
    linkedInPageSelectModalOpened: false
});

const CalendarActiveSocialNetworkProvider = ({ children }) => {

    const { user, updateUserData } = useContext(SessionContext);
    const { company, companySocials } = useContext(CompanyContext);
    const [ instagramPageSelectModalOpened, setInstagramPageSelectModalOpened ] = useState(false);
    const [ facebookPageSelectModalOpened, setFacebookPageSelectModalOpened ] = useState(false);
    const [ linkedInPageSelectModalOpened, setLinkedInPageSelectModalOpened ] = useState(false);
    const [ calendarActiveSocialNetwork, setCalendarActiveSocialNetwork ] = useState([]);
    const [ socialNetworkErrorOpened, setSocialNetworkErrorOpened ] = useState(false);

    useEffect(() => {
        if (!!calendarActiveSocialNetwork?.length && !!company?.id) {
            localStorage.setItem(`calendarActiveSocialNetwork${company?.id}`, JSON.stringify(calendarActiveSocialNetwork));
        }
    }, [calendarActiveSocialNetwork, company?.id]);

    useEffect(() => {
        var _activeSocialNetworks = [];
        
        if (localStorage.getItem(`calendarActiveSocialNetwork${company?.id}`)) {
            _activeSocialNetworks = JSON.parse(localStorage.getItem(`calendarActiveSocialNetwork${company?.id}`));
        } else {
            _activeSocialNetworks = socialNetworks;
        }

        // _activeSocialNetworks = _activeSocialNetworks.filter((network) => companySocials.filter(e => e.type == network && !checkIfIsExpired(e))?.length > 0);

        // if (!company || companySocials?.filter(e => _activeSocialNetworks.includes(e.type))?.length == 0) {
        //     setCalendarActiveSocialNetwork([]);
        //     if (companySocials) {
        //         if (checkIfIsIntegrated('instagram', companySocials) && !checkIfIsExpired(companySocials?.filter(e => e.type == 'instagram')?.[0])) _activeSocialNetworks.push('instagram');
        //         if (checkIfIsIntegrated('facebook', companySocials) && !checkIfIsExpired(companySocials?.filter(e => e.type == 'facebook')?.[0])) _activeSocialNetworks.push('facebook');
        //         if (checkIfIsIntegrated('twitter', companySocials) && !checkIfIsExpired(companySocials?.filter(e => e.type == 'twitter')?.[0])) _activeSocialNetworks.push('twitter');
        //         if (checkIfIsIntegrated('linkedin', companySocials) && !checkIfIsExpired(companySocials?.filter(e => e.type == 'linkedin')?.[0])) _activeSocialNetworks.push('linkedin');
        //         if (checkIfIsIntegrated('tiktok', companySocials) && !checkIfIsExpired(companySocials?.filter(e => e.type == 'tiktok')?.[0])) _activeSocialNetworks.push('tiktok');
        //         if (checkIfIsIntegrated('discord', companySocials) && !checkIfIsExpired(companySocials?.filter(e => e.type == 'discord')?.[0])) _activeSocialNetworks.push('discord');
        //         if (checkIfIsIntegrated('medium', companySocials) && !checkIfIsExpired(companySocials?.filter(e => e.type == 'medium')?.[0])) _activeSocialNetworks.push('medium');
        //         if (checkIfIsIntegrated('pinterest', companySocials) && !checkIfIsExpired(companySocials?.filter(e => e.type == 'pinterest')?.[0])) _activeSocialNetworks.push('pinterest');
        //         if (checkIfIsIntegrated('youtube', companySocials) && !checkIfIsExpired(companySocials?.filter(e => e.type == 'youtube')?.[0])) _activeSocialNetworks.push('youtube');
        //         if (checkIfIsIntegrated('telegram', companySocials) && !checkIfIsExpired(companySocials?.filter(e => e.type == 'telegram')?.[0])) _activeSocialNetworks.push('telegram');
        //     }
        // }
        setCalendarActiveSocialNetwork(_activeSocialNetworks);
    }, [companySocials])

    useEffect(() => {
        var url = new URL(window.location.href);
        if (window.location.href.includes("/socialNetworkCreated")) {
            var network = url.searchParams.get("type");
            if (network == "linkedin") {
                setLinkedInPageSelectModalOpened(true);
            }
            if (network == "facebook") {
                setFacebookPageSelectModalOpened(true);
            }
            if (network == "instagram") {
                setInstagramPageSelectModalOpened(true);
            }
        }
        if (url.searchParams.get("status") == "socialNetworkFailed") {
            var network = url.searchParams.get("social_network_type");
            setSocialNetworkErrorOpened(true);
        }
    }, []);

    useEffect(() => {
        if (localStorage.getItem("social_network_updated")) {
            const network = localStorage.getItem("social_network_updated");
            if (network == "instagram" && !instagramPageSelectModalOpened) {
                localStorage.removeItem("social_network_updated");
            }
            if (network == "facebook" && !facebookPageSelectModalOpened) {
                localStorage.removeItem("social_network_updated");
            }
            if (network == "linkedin" && !linkedInPageSelectModalOpened) {
                localStorage.removeItem("social_network_updated");
            }
        }
    }, [instagramPageSelectModalOpened, facebookPageSelectModalOpened, linkedInPageSelectModalOpened]);

    return (
        <CalendarActiveSocialNetworkContext.Provider value={{
            calendarActiveSocialNetwork: calendarActiveSocialNetwork, 
            setCalendarActiveSocialNetwork: setCalendarActiveSocialNetwork, 
            setInstagramPageSelectModalOpened: setInstagramPageSelectModalOpened, 
            setFacebookPageSelectModalOpened: setFacebookPageSelectModalOpened, 
            setLinkedInPageSelectModalOpened: setLinkedInPageSelectModalOpened,
            setSocialNetworkErrorOpened: setSocialNetworkErrorOpened,
            instagramPageSelectModalOpened: instagramPageSelectModalOpened,
            facebookPageSelectModalOpened: facebookPageSelectModalOpened,
            linkedInPageSelectModalOpened: linkedInPageSelectModalOpened, 
        }}>
            {children}
            {instagramPageSelectModalOpened && <InstagramPageSelectModal />}
            {facebookPageSelectModalOpened && <FacebookPageSelectModal />}
            {linkedInPageSelectModalOpened && <LinkedInPageSelectModal />}
            {socialNetworkErrorOpened && <SocialNetworkErrorModal />}
        </CalendarActiveSocialNetworkContext.Provider>
    );
};

export { CalendarActiveSocialNetworkContext, CalendarActiveSocialNetworkProvider };
import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from 'contexts/CompanyContext'
import { socialNetworkFullPrompts } from '../utils/hooks/useCopyGenerator';
import Api from 'services/Api';
import { SessionContext } from 'contexts/SessionContext';
import { useLocation } from "react-router-dom";
import { postTypesOptions } from "utils/hooks/useConfigPanel";

const getDefaultPrompts = (prompts = [])  => {
    let _prompts = prompts?.map((e, i) => ({...e, index: i}));
    return _prompts?.filter(e => !!e.default || !_prompts?.filter(c => e.type == c.type && !!c.default).length);
}

const CompanyPromptsContext = React.createContext({
    prompts: [],
    defaultPrompts: []
});

const CompanyPromptsProvider = ({ children }) => {

    const { updateUserData } = useContext(SessionContext);
    const { company } = useContext(CompanyContext);
    const [ prompts, setPrompts ] = useState(company?.social_network_prompts);
    const [ defaultPrompts, setDefaultPrompts ] = useState(getDefaultPrompts(company?.social_network_prompts));
    const location = useLocation();
  
    const followrPrompts = socialNetworkFullPrompts;
  
    useEffect(() => {
      if (company?.id) {
        let newPrompts = [];
        for (var i = 0; i < postTypesOptions?.length; i++) {
          const network = postTypesOptions[i].value;
          if (!company?.social_network_prompts?.filter(e => e.type == network)?.length) {
            newPrompts = [...newPrompts, ...followrPrompts.filter(e => e.type == network)];
          }
        }
        if (newPrompts?.length) {
          Api.put(`/companies/${company?.id}`, {}, { 
            social_network_prompts: [...newPrompts, ...(company?.social_network_prompts || [])] 
          }).then(() => {
            updateUserData();
          });
        }
      }
    }, [company?.id, location.pathname]);
  
    useEffect(() => {
      setPrompts(company?.social_network_prompts);
    }, [company?.social_network_prompts]);
  
    useEffect(() => {
      setDefaultPrompts(getDefaultPrompts(prompts));
    }, [prompts]);

    return (
        <CompanyPromptsContext.Provider value={{
            prompts,
            defaultPrompts
        }}>
            {children}
        </CompanyPromptsContext.Provider>
    )
}

export { CompanyPromptsContext, CompanyPromptsProvider };
import OutOfWordsModal from "components/layout/OutOfWordsModal";
import { CompanyContext } from "contexts/CompanyContext";
import { ModalContext } from "contexts/ModalContext";
import { SessionContext } from "contexts/SessionContext";
import { Configuration, OpenAIApi } from "openai";
import { useContext } from "react";
import { useSelector } from "react-redux";
import Api from "services/Api";
import { replaceAll } from "utils/replaceAll";

export const useTrendingNews = () => {

    const { company } = useContext(CompanyContext);
    const supportedLanguages = ["ar","de","en","es","fr","he","it","nl","no","pt","ru","sv","ud","zh"]; 
    const supportedMkts = ["es-AR", "en-AU", "de-AT", "nl-BE", "fr-BE", "pt-BR", "en-CA", "fr-CA", "es-CL", "da-DK", "fi-FI", "fr-FR", "de-DE", "zh-HK", "en-IN", "en-ID", "it-IT", "ja-JP", "ko-KR", "en-MY", "es-MX", "nl-NL", "en-NZ", "no-NO", "zh-CN", "pl-PL", "en-PH", "ru-RU", "en-ZA", "es-ES", "sv-SE", "fr-CH", "de-CH", "zh-TW", "tr-TR", "en-GB", "en-US", "es-US"];

    const getTrendingNews = async (
        query = "", 
        dateFrom = new Date(new Date().getTime() - 3600 * 24 * 30 * 1000), 
        dateTo = new Date(new Date().getTime() + 1000 * 60 * 2),
        sortBy = "date", // relevance, date
        language = undefined,
        searchIn = "title" // possible values: title OR text
    ) => {

        if (sortBy == 'publishedAt') sortBy = 'date';
        if (sortBy == 'relevancy') sortBy = 'relevance';
        if (sortBy == 'popularity') sortBy = 'relevance';
        
        language = !language ? supportedMkts?.filter(e => e.split("-")[1].toLowerCase().includes(company?.language_iso_code?.toLowerCase()))?.[0] : language;
        language = supportedMkts.includes(language) ? language : "en-US";

        const interests = company?.interests?.sort(() => 0.5 - Math.random());
        interests?.map((interest) => {
            if (interest?.length >= 3 && interest?.length < 25 && !query) {
                query = interest;
            }
        });
        
        const response = await Api.get(`/news/bing?q=${query}&sortBy=${sortBy}&mkt=${language}&count=60&originalImg=1`, {}).then((response) => response);

        return response?.data?.map((item) => {
            return {
                urlToImage: item?.image?.contentUrl || replaceAll(item?.image?.thumbnail?.contentUrl, "&pid=News", ""),
                title: item?.name,
                description: item?.description,
                source: { name: item?.provider?.[0]?.name },
                url: item?.url
            }
        });
    }

    const getTrendingNewsConnexun = async (
        query = "", 
        dateFrom = new Date(new Date().getTime() - 3600 * 24 * 30 * 1000), 
        dateTo = new Date(new Date().getTime() + 1000 * 60 * 2),
        sortBy = "published", // relevance, published
        language = undefined,
        searchIn = "title" // possible values: title OR text
    ) => {

        if (sortBy == 'publishedAt') sortBy = 'published';
        if (sortBy == 'relevancy') sortBy = 'relevance';
        if (sortBy == 'popularity') sortBy = 'relevance';

        if (query?.length > 25) query?.substring(0, 25);
        
        language = !language ? company?.language_iso_code : language;
        language = supportedLanguages.includes(language) ? language : "en";

        dateFrom = new Date(dateFrom).toISOString();
        dateTo = new Date(dateTo).toISOString();

        const interests = company?.interests?.sort(() => 0.5 - Math.random());
        interests.map((interest) => {
            if (interest?.length >= 3 && interest?.length < 25 && !query) {
                query = interest;
            }
        });
        
        const response = await Api.get(`/news/connexun?qField=${searchIn}&search=${query}&sortBy=${sortBy}&searchType=OR&languages=${language}&publishedAfter=${dateFrom}&batchSize=30`, {}).then((response) => response);

        return response?.data?.map((item) => {
            return {
                urlToImage: item?.Image,
                title: item?.Title,
                description: item?.Description,
                source: { name: item.Source },
                url: item?.Url
            }
        });
    }

    const getTrendingNewsNewsAPI = async (
        query = "", 
        dateFrom = new Date(new Date().getTime() - 3600 * 24 * 30 * 1000), 
        dateTo = new Date(),
        sortBy = "publishedAt", // relevancy, popularity, publishedAt
        language = undefined,
        searchIn = "title,description,content" // possible values: title, description, content
    ) => {
        
        language = !language ? company?.language_iso_code : language;
        language = supportedLanguages.includes(language) ? language : "en";

        dateFrom = new Date(dateFrom).toISOString().split('T')[0];
        dateTo = new Date(dateTo).toISOString().split('T')[0];

        const interests = company?.interests?.sort(() => 0.5 - Math.random());
        const chunkSize = 4;
        let defaultQueryArrays = [];
        for (let i = 0; i < interests?.length; i += chunkSize) {
            defaultQueryArrays.push(interests.slice(i, i + chunkSize).join(" AND "));
        }

        query = query ? query : `(${defaultQueryArrays.join(") OR (")})`;
        
        const response = await Api.get(`/news?q=${query}&sortBy=${sortBy}&searchIn=${searchIn}&language=${language}&from=${dateFrom}&to=${dateTo}&pageSize=25`, {}).then((response) => response);
        
        return response?.data;
    }

    return { getTrendingNews, getTrendingNewsNewsAPI, getTrendingNewsConnexun }
}


import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Bugfender } from '@bugfender/sdk';

Bugfender.init({
    appKey: 'i8RUuNDyEEvArKYUYxGPaPg9su6COOxL',
    // apiURL: process.env.REACT_APP_API_HOST,
    // baseURL: process.env.REACT_APP_BASE_URL,
    overrideConsoleMethods: false,
    // printToConsole: true,
    registerErrorHandler: false,
    logBrowserEvents: false,
    logUIEvents: false,
    // version: '',
    // build: '',
});

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <App />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React, { memo, useMemo, lazy, Suspense, useContext, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useSelector } from 'react-redux'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import { ModalContext } from 'contexts/ModalContext'
import { Dialog, Notification, toast } from 'components/ui'
import { SessionContext } from 'contexts/SessionContext'
import { UploadContext } from 'contexts/UploadContext'
import UploadModal from './UploadModal'
import MediaUpload from 'views/calendar/MediaUpload'
import { PriorityModalContext } from 'contexts/PriorityModalContext'
import useBus from 'use-bus'
import OutOfUsersModal from './OutOfUsersModal'
import OutOfCompaniesModal from './OutOfCompaniesModal'
import OutOfWordsModal from './OutOfWordsModal'
import { CompanyContext } from 'contexts/CompanyContext'
import Subscription from 'views/subscription'
import capitalize from 'components/ui/utils/capitalize'
import OutOfImagesModal from './OutOfImagesModal'
import PlusModal from 'components/shared/PlusModal'
import { AppSumoContext } from 'contexts/AppSumoContext'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
    const layoutType = useSelector((state) => state.theme.layout.type)

    const { isSumoling } = useContext(AppSumoContext);
    const { isOpened, closeModal, component, dialogWidth, hasCloseButton, isClosable, contentClassName } = useContext(ModalContext);
    const { isOpenedPriority, openModalPriority, closeModalPriority, componentPriority, dialogWidthPriority, hasCloseButtonPriority, isClosablePriority, contentClassNamePriority, priorityModalRef } = useContext(PriorityModalContext);
    const { isOpenedUploadModal } = useContext(UploadContext);
    const { user, session } = useContext(SessionContext);
    const { company } = useContext(CompanyContext);

    useDirection()

    useLocale()

    const AppLayout = useMemo(() => {
        if (session?.isLoggedIn) {
            return layouts[layoutType]
        }
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, session]);

    useBus('subscriptionAddOnModal', (action) => {
        const entity = action.payload.entity;

        toast.push(<Notification type="warning" title={`You need to upgrade your plan in order to use more ${capitalize(entity)}.`} />, { placement: "top-center" });
        
        if (company?.role?.name == "admin" || entity == "companies") {
            if (!isSumoling() && (!user?.subscription || entity == "posts" || entity == "designs" || !user?.pm_last_four)) {
                openModalPriority(<Subscription isModal={true} />, 1200, true, true, `!p-0 !bg-gray-100 dark:!bg-gray-900 zoom-95`);
            } else if (entity == "users") {
                openModalPriority(<OutOfUsersModal />);
            } else if (entity == "companies") {
                openModalPriority(<OutOfCompaniesModal />);
            } else if (entity == "words") {
                openModalPriority(<OutOfWordsModal />);
            } else if (entity == "images") {
                openModalPriority(<OutOfImagesModal />);
            } else if (entity == "premium_images") {
                openModalPriority(<PlusModal />, 650, true, true, "relative !overflow-visible !overflow-y-visible !p-0 !bg-transparent");
            }
        }
    }, [company?.id, user?.pm_last_four, user?.subscription?.stripe_status]);

    function appHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--safe-area-px', `0px`);
        // doc.style.setProperty('--safe-area-px', `${document?.getElementsByTagName("main")?.[0]?.offsetHeight - window.innerHeight + 64}px`);
        // doc.style.setProperty('--vh', `${window.innerHeight}px`);
    }
    useEffect(() => {
        window.addEventListener('resize', appHeight);
        appHeight();
    }, [document?.getElementsByTagName("main")?.[0]?.offsetHeight])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />

            <Dialog 
                isOpen={isOpened} 
                onClose={closeModal} 
                onRequestClose={closeModal} 
                closeButtonClassName={`${!hasCloseButton ? `hidden` : ``}`} 
                overlayClassName="flex items-center justify-center" 
                contentClassName={contentClassName}
                width={dialogWidth ?? undefined} 
                closable={isClosable}
            >
                {component}
            </Dialog>

            <Dialog ref={priorityModalRef} isOpen={isOpenedPriority} onClose={closeModalPriority} onRequestClose={closeModalPriority} closeButtonClassName={`${!hasCloseButtonPriority ? `hidden` : ``}`} overlayClassName="flex items-center justify-center z-[10000000]" width={dialogWidthPriority ?? undefined} closable={isClosablePriority} contentClassName={contentClassNamePriority}>
                {componentPriority}
            </Dialog>

            <MediaUpload invisible={true} />

            {/* {companySocials?.length == 0 && !isLoadingCompanySocials && !!company && session?.isLoggedIn && <ConnectASocialNetworkModal />} */}

            {isOpenedUploadModal &&
                <UploadModal />
            }
        </Suspense>
    )
}

export default memo(Layout)

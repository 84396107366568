import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const PriorityModalContext = React.createContext({
    isOpenedPriority: false,
    openModalPriority: (component, width = undefined, hasCloseButton = true, isClosable = true, contentClassName = "") => void 0,
    closeModalPriority: () => void 0,
    componentPriority: <></>,
    dialogWidthPriority: undefined,
    hasCloseButtonPriority: true,
    isClosablePriority: true,
    contentClassNamePriority: "",
    priorityModalRef: undefined
});

const PriorityModalProvider = ({ children }) => {
    
    const [isOpened, setIsOpened] = useState(false);
    const [component, setComponent] = useState(<></>);
    const [dialogWidth, setDialogWidth] = useState();
    const [hasCloseButton, setHasCloseButton] = useState(true);
    const [isClosable, setIsClosable] = useState(true);
    const [contentClassName, setContentClassName] = useState("");
    const priorityModalRef = useRef();

    const openModal = (component, dialogWidth = undefined, hasCloseButton = true, isClosable = true, contentClassName = "") => {
        setComponent(component);
        setIsOpened(true);
        setDialogWidth(dialogWidth);
        setHasCloseButton(hasCloseButton);
        setIsClosable(isClosable);
        setContentClassName(contentClassName);
    }

    const closeModal = () => {
        setComponent(<></>);
        setIsOpened(false);
    }

    return (
        <PriorityModalContext.Provider value={{ 
            isOpenedPriority: isOpened, 
            openModalPriority: openModal, 
            closeModalPriority: closeModal, 
            componentPriority: component, 
            dialogWidthPriority: dialogWidth,
            hasCloseButtonPriority: hasCloseButton,
            isClosablePriority: isClosable,
            contentClassNamePriority: contentClassName,
            priorityModalRef: priorityModalRef    
        }}>
            {children}
        </PriorityModalContext.Provider>
    );
};

export { PriorityModalContext, PriorityModalProvider };
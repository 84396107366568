"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventPrinter = void 0;
const utils_1 = require("../utils");
class EventPrinter {
    constructor(console, printToConsole) {
        this.console = console;
        this.printToConsole = printToConsole;
        this.styles = {
            bold: 'font-weight:bold',
            icon: 'color:#ff5060',
            italic: 'font-style:italic',
            neutral: 'color:#999',
            regular: 'color:#222',
        };
    }
    print(icon, text, xpath, styles) {
        if (!this.printToConsole) {
            return;
        }
        const msg = `%c${icon}%c ${text}`;
        styles = [this.styles.icon, this.styles.regular, ...(styles !== null && styles !== void 0 ? styles : [])];
        if (xpath) {
            this.console.info(msg, ...styles, (0, utils_1.getElByXPath)(xpath));
        }
        else {
            this.console.info(msg, ...styles);
        }
    }
    printDeviceKey(key, value) {
        if (value === null) {
            this.print('◆', `Device key %c"${key}"%c removed`, undefined, [this.styles.italic, this.styles.bold]);
        }
        else {
            this.print('◆', `Device key %c"${key}" %cset%c to %c"${value}"`, undefined, [this.styles.italic, this.styles.bold, this.styles.regular, this.styles.italic]);
        }
    }
    printJsEvent(log) {
        const data = JSON.parse(log.text);
        switch (data.event) {
            case 'DOMContentLoaded':
                this.print('●', 'DOM content %cloaded', undefined, [this.styles.bold]);
                break;
            case 'PageLoaded':
                this.print('●', 'Page %cloaded', undefined, [this.styles.bold]);
                break;
            case 'hashchange':
                this.print('#', `URL %chash changed%c to %c"${data.hash}"`, undefined, [this.styles.bold, this.styles.regular, this.styles.italic]);
                break;
            case 'blur':
            case 'click':
            case 'focus':
            case 'keyup': {
                const icon = data.event === 'click' ? '⊙' : '▭';
                const value = (data.text && data.event === 'keyup') ? ` value=${this.truncate(data.text)}` : '';
                const mapLabel = {
                    blur: 'blurred',
                    click: 'clicked',
                    focus: 'focused',
                    keyup: 'keyup',
                };
                this.print(icon, `Element %c${mapLabel[data.event]}%c${value}%c:`, data.xpath, [this.styles.bold, this.styles.neutral, this.styles.regular]);
                break;
            }
            case 'submit':
                this.print('↑', 'Form %csubmited', data.xpath, [this.styles.bold]);
                break;
            case 'popstate':
            case 'pushstate': {
                this.print('↦', 'Navigation %cstate changed', undefined, [this.styles.bold]);
                break;
            }
            default:
                throw new utils_1.UnreachableCaseError(data);
        }
    }
    truncate(input) {
        const limit = 15;
        if (input.length > limit) {
            return `${input.substring(0, limit)}… [${input.length}]`;
        }
        return input;
    }
}
exports.EventPrinter = EventPrinter;

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIFeatureDetection = void 0;
const utils_1 = require("../utils");
const cache = new Map();
const Cached = (0, utils_1.CacheDecoratorFactory)(cache);
class APIFeatureDetection {
    constructor(indexedDB, localStorage, sessionStorage, broadcastChannel, worker) {
        this.indexedDB = indexedDB;
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
        this.broadcastChannel = broadcastChannel;
        this.worker = worker;
    }
    isBroadcastChannelSupported() {
        return __awaiter(this, void 0, void 0, function* () {
            return typeof this.broadcastChannel === 'function' || (yield this.isIndexedDBSupported()) || this.isLocalStorageSupported();
        });
    }
    isIndexedDBSupported() {
        return new Promise((resolve, _reject) => {
            const openReq = this.indexedDB.open('bf_sdk_test');
            openReq.onerror = () => resolve(false);
            openReq.onsuccess = () => {
                resolve(true);
                const db = openReq.result;
                db.close();
            };
        });
    }
    isLocalStorageSupported() {
        return this.isStorageSupported(this.localStorage);
    }
    isSessionStorageSupported() {
        return this.isStorageSupported(this.sessionStorage);
    }
    isWorkerSupported() {
        return !!this.worker;
    }
    isStorageSupported(storage) {
        try {
            const testKey = 'Bf8taM%GhLkg';
            storage.setItem(testKey, testKey);
            storage.removeItem(testKey);
            return true;
        }
        catch (e) {
            return false;
        }
    }
}
__decorate([
    Cached(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], APIFeatureDetection.prototype, "isBroadcastChannelSupported", null);
__decorate([
    Cached(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], APIFeatureDetection.prototype, "isIndexedDBSupported", null);
__decorate([
    Cached(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Boolean)
], APIFeatureDetection.prototype, "isLocalStorageSupported", null);
__decorate([
    Cached(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Boolean)
], APIFeatureDetection.prototype, "isSessionStorageSupported", null);
__decorate([
    Cached(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Boolean)
], APIFeatureDetection.prototype, "isWorkerSupported", null);
exports.APIFeatureDetection = APIFeatureDetection;

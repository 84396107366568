export const formatSocialPostObj = (
    network,
    id = "",
    user_id = "",
    user_name = "",
    username = "",
    user_image = "",
    created_at = new Date(),
    text = "",
    media = [], 
    like_count = 0,
    comment_count = 0,
    view_count = 0,
    retweet_count = 0,
    title = "",
    link = "",
    embed_link = "",
    media_product_type = "FEED",
    user_verified = false,
    comments = [],
    assets = [],
    preferences = undefined,
    postIndex = 0,
    followers_count = 0,
    comments_to_create= []
) => {
    created_at = new Date(created_at);

    return {
        network: network,
        id: id,
        user_id: user_id,
        user_name: user_name,
        user_image: user_image,
        username: username,
        created_at: created_at,
        text: text,
        media: media, 
        like_count: like_count,
        comment_count: comment_count,
        view_count: view_count,
        retweet_count: retweet_count,
        followers_count: followers_count,
        title: title,
        link: link,
        embed_link: embed_link,
        media_product_type: media_product_type,
        user_verified: user_verified,
        comments: comments,
        assets: assets,
        preferences: preferences,
        postIndex: postIndex,
        comments_to_create: comments_to_create
    }
}

export const formatSocialConversationObj = (
    network,
    id = "",
    created_at = new Date(),
    user_id = "",
    user_name = "",
    username = "",
    user_image = "",
    user_verified = false,
    user_created_at = new Date(),
    user_description = "",
    user_followers_count = 0,
    user_following_count = 0,
    user_post_count = 0,
    user_profile_url = "",
    message = "",
    is_last_message_from_user = false,
    unreadMessages_count = 0,
    last_read_at = undefined,
    assigned_user_id = undefined,
    
) => {
    created_at = new Date(created_at);
    user_created_at = new Date(user_created_at);

    return {
        network: network,
        id: id,
        created_at: created_at,
        user_id: user_id,
        user_name: user_name,
        username: username,
        user_image: user_image,
        user_verified: user_verified,
        user_created_at: user_created_at,
        user_description: user_description,
        user_followers_count: user_followers_count,
        user_following_count: user_following_count,
        user_post_count: user_post_count,
        user_profile_url: user_profile_url,
        message: message,
        is_last_message_from_user: is_last_message_from_user,
        unreadMessages_count: unreadMessages_count,
        last_read_at: last_read_at,
        assigned_user_id: assigned_user_id
    }
}

export const formatSocialMessageObj = (
    network,
    id = "",
    created_at = new Date(),
    user_id = "",
    message = "",
    media = []
) => {
    created_at = new Date(created_at);

    return {
        network: network,
        id: id,
        created_at: created_at,
        user_id: user_id,
        message: message,
        media: media
    }
}

export const formatSocialCommentObj = (
    network,
    id = "",
    created_at = new Date(),
    user_id = "",
    user_name = "",
    username = "",
    user_image = "",
    text = "",
    media = [],
    replies = [],
    last_read_at = undefined,
    assigned_user_id = undefined
) => {
    created_at = new Date(created_at);

    return {
        network: network,
        id: id,
        created_at: created_at,
        user_id: user_id,
        user_name: user_name,
        username: username,
        user_image: user_image,
        text: text,
        media: media,
        replies: replies,
        last_read_at: last_read_at,
        assigned_user_id: assigned_user_id
    }
}

export const getFormatedPosts = (_posts, postGroup) => {
    return _posts?.map((post, index) => {
        return formatSocialPostObj(
            post?.social_network_type,
            post?.id,
            post?.user_id,
            undefined,
            undefined,
            undefined,
            postGroup?.created_at,
            post?.description,
            [],
            undefined,
            undefined,
            undefined,
            undefined,
            post?.title,
            post?.link,
            undefined,
            post?.preferences?.media_product_type,
            undefined,
            undefined,
            post?.assets,
            post?.preferences,
            index
        )
    });
}

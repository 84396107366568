import axios from 'axios';
import { get } from 'lodash';
import { dispatch } from 'use-bus'
import { Bugfender } from '@bugfender/sdk';

export const API_HOST = process.env.REACT_APP_API_HOST;

const createRequest = ({ method, path, params, config, data }) => {
  return {
    method,
    url: path,
    baseURL: `${API_HOST}/api`,
    params,
    data,
    ...config,
  };
};

const executeRequest = (config) => {
  config = { ...config, headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } };
  return axios.request(config);
};

const validateRequest = async (requestConfig) => {
  try {
    const { data } = await executeRequest(requestConfig);
    return data;
  } catch (error) {
    try {
      Bugfender.error(requestConfig, error);
    } catch (err) {}
    if (axios.isCancel(error)) throw new Error(error.message);
    if (error.response && error.response.status === 402) {
      dispatch({ type: 'subscriptionAddOnModal', payload: { entity: error.response.data.entity } });
      throw new RequirePaymentError(error.response.data.message);
    } else if (error.response && error.response.status === 401 && get(error, 'response.data.message') === 'Unauthenticated.') {
      if (!window.location.href.includes("login")) {
        window.location.href = "/login";
      }
      throw new TokenError(error.message);
    } else if (error.response && error.response.status === 404) {
      throw new ResourceNotFound(error.response.data);
    } else if (error.response && error.response.data) {
      throw new ResponseError(error.response.data);
    } else {
      throw new Error(error.message);
    }
  }
};

class RequirePaymentError extends Error {}

class TokenError extends Error {}

class ResponseError extends Error {
  errors;

  constructor({ message, errors }) {
    super(message);
    this.errors = errors;
  }
}

class ResourceNotFound extends ResponseError {}

const Api = {
  get: async (
    path,
    params,
    config = {},
  ) => {
    const requestConfig = createRequest({
      method: 'get',
      path,
      params,
      config,
    });
    return validateRequest(requestConfig);
  },
  post: async (
    path,
    params,
    data,
    config,
  ) => {
    const requestConfig = createRequest({
      method: 'post',
      path,
      params,
      data,
      config,
    });
    return validateRequest(requestConfig);
  },
  put: async (
    path,
    params,
    data,
    config,
  ) => {
    const requestConfig = createRequest({
      method: 'put',
      path,
      params,
      data,
      config,
    });
    return validateRequest(requestConfig);
  },
  patch: async (
    path,
    params,
    data,
    config,
  ) => {
    const requestConfig = createRequest({
      method: 'patch',
      path,
      params,
      data,
      config,
    });
    return validateRequest(requestConfig);
  },
  delete: async (
    path,
    params,
    data,
    config,
  ) => {
    const requestConfig = createRequest({
      method: 'delete',
      path,
      params,
      data,
      config,
    });
    return validateRequest(requestConfig);
  },
  fetch: async (
    path,
    params,
    config = {},
  ) => {
    return await fetch(`${API_HOST}/api${path}&token=${localStorage.getItem('token')}`)
  },
  eventSource: async (
    path
  ) => {
    const sse = new EventSource(`${API_HOST}/api${path}&token=${localStorage.getItem('token')}`,);
    sse.onerror = (err) => {
      sse.close();
    }
    return sse;
  }
};

export default Api;
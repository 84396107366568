const getSocialNetworkProfileLink = (integration) => {
    const network = integration?.type;
    const username = integration?.username;
    const name = integration?.name;
    const page_id = integration?.page_id;
    if (network == "medium") {
        return `https://medium.com/feed/@${username}`
    }
    if (network == "tiktok") {
        return `https://tiktok.com/@${username ? username : name}`;
    }
    if (network == "pinterest") {
        return `https://pinterest.com/${username ? username : name}`;
    }
    if (network == "facebook") {
        return `https://facebook.com/${page_id}`;
    }
    if (network == "twitter") {
        return `https://twitter.com/${username}`;
    }
    // if (network == "linkedin") {
    //     return `https://linkedin.com/${username}`;
    // }
    // if (network == "instagram") {
    //     return null;
    // }
}

export default getSocialNetworkProfileLink

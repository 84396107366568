import { Dropdown } from "components/ui";
import { useState } from "react";
import { LuImage, LuShare2, LuVideo } from "react-icons/lu";
import { designTypes } from "utils/hooks/useVistaCreate";

const DesignTypesDropdownOptions = ({
    network = "",
    allowedTypes = ["image","video"],
    onSelectDesignType = (designType) => void 0
}) => {

    const [ showMore, setShowMore ] = useState(!network ? true : false);

    return (<>
        {[
            ...designTypes.filter(e => e.network == network), 
            ...designTypes.filter(e => e.network != network)
        ].filter(e => !!allowedTypes.includes(e.type))
        .filter(e => e.network == network || showMore == true)
        .map((designTypeObj, key) => (
            <Dropdown.Item onClick={() => onSelectDesignType(designTypeObj.designType)} key={key} disabled={!allowedTypes.find((value) => value == designTypeObj.type)} className="!px-4 !py-2">
                <div className="flex justify-between w-full gap-2 items-center">
                    <div className="flex items-center gap-3 flex-1 min-w-0">
                        {designTypeObj.network 
                            ? <img src={`/img/socials/${designTypeObj.network}.svg`} className="shrink-0 !h-4 !w-4" /> 
                            : <LuShare2 className="text-xl text-primary-600" />
                        }
                        <div className="flex flex-col justify-start items-start -space-y-0.5 flex-1 shrink-0 min-w-0">
                            <span className="text-xs w-full block overflow-hidden text-ellipsis">{designTypeObj.name}</span>
                            <span className="opacity-70 font-normal text-[10px] overflow-hidden text-ellipsis">{designTypeObj.size}</span>
                        </div>
                    </div>
                    {designTypeObj.type == "image" && <LuImage className="text-lg opacity-70 shrink-0" />}
                    {designTypeObj.type == "video" && <LuVideo className="text-lg opacity-70 shrink-0" />}
                </div>
            </Dropdown.Item>
        ))}
        {!showMore &&
            <Dropdown.Item variant="header" className="!px-4 !py-2 cursor-pointer">
                <div onClick={() => setShowMore(true)}>Show more...</div>
            </Dropdown.Item>
        }
    </>);
}

export default DesignTypesDropdownOptions;
import classNames from "classnames";
import { FormContainer, FormItem, Input, Segment } from "components/ui";
import { Field } from "formik";
import React from "react";
import { Trans } from "react-i18next";
import { HiCheckCircle } from "react-icons/hi";

export const communicationStyleOptions = [
    { 
        value: "Formal",
        name: <Trans i18nKey={`companyCommunicationStyle.formal`}>Formal</Trans>, 
        desc: <Trans i18nKey={`companyCommunicationStyle.formalDesc`}>Uses proper grammar and maintains a professional tone.</Trans>, 
        emoji: "🤵",
        onboarding: true 
    },
    { 
        value: "Friendly",
        name: <Trans i18nKey={`companyCommunicationStyle.friendly`}>Friendly</Trans>, 
        desc: <Trans i18nKey={`companyCommunicationStyle.friendlyDesc`}>Uses a more casual tone and may use emojis.</Trans>, 
        emoji: "😀",
        onboarding: true
    },
    { 
        value: "Humorous",
        name: <Trans i18nKey={`companyCommunicationStyle.humorous`}>Humorous</Trans>, 
        desc: <Trans i18nKey={`companyCommunicationStyle.humorousDesc`}>Uses puns or jokes to add humor to the conversation.</Trans>, 
        emoji: "🤣",
        onboarding: true 
    },
    { 
        value: "Informal", 
        name: <Trans i18nKey={`contextMenu.speakingFormatInformal`}>Informal</Trans>, 
        desc: "",
        emoji: "😜",
        onboarding: false
    },   
    { 
        value: "Ironic", 
        name: <Trans i18nKey={`contextMenu.speakingFormatIronic`}>Ironic</Trans>, 
        desc: "",
        emoji: "🙃",
        onboarding: false
    },   
    { 
        value: "Positive", 
        name: <Trans i18nKey={`contextMenu.speakingFormatPositive`}>Positive</Trans>, 
        desc: "",
        emoji: "🤩",
        onboarding: false
    },   
    { 
        value: "Exciting", 
        name: <Trans i18nKey={`contextMenu.speakingFormatExciting`}>Exciting</Trans>, 
        desc: "",
        emoji: "🥳",
        onboarding: false
    },   
    { 
        value: "Angry", 
        name: <Trans i18nKey={`contextMenu.speakingFormatAngry`}>Angry</Trans>, 
        desc: "",
        emoji: "😡",
        onboarding: false
    },   
    { 
        value: "Affectionate", 
        name: <Trans i18nKey={`contextMenu.speakingFormatAffectionate`}>Affectionate</Trans>, 
        desc: "",
        emoji: "🥰",
        onboarding: false
    },   
    { 
        value: "Melancholic", 
        name: <Trans i18nKey={`contextMenu.speakingFormatMelancholic`}>Melancholic</Trans>, 
        desc: "",
        emoji: "🥺",
        onboarding: false
    },   
    { 
        value: "Dramatic", 
        name: <Trans i18nKey={`contextMenu.speakingFormatDramatic`}>Dramatic</Trans>, 
        desc: "",
        emoji: "😱",
        onboarding: false
    },   
    { 
        value: "Scientific", 
        name: <Trans i18nKey={`contextMenu.speakingFormatScientific`}>Scientific</Trans>, 
        desc: "",
        emoji: "🔬",
        onboarding: false
    },   
    { 
        value: "Questioning", 
        name: <Trans i18nKey={`contextMenu.speakingFormatQuestioning`}>Questioning</Trans>, 
        desc: "",
        emoji: "🤔",
        onboarding: false
    },   
    { 
        value: "Creative", 
        name: <Trans i18nKey={`contextMenu.speakingFormatCreative`}>Creative</Trans>, 
        desc: "",
        emoji: "🎨",
        onboarding: false
    },   
    { 
        value: "Descriptive", 
        name: <Trans i18nKey={`contextMenu.speakingFormatDescriptive`}>Descriptive</Trans>, 
        desc: "",
        emoji: "📖",
        onboarding: false
    },   
    { 
        value: "Educational", 
        name: <Trans i18nKey={`contextMenu.speakingFormatEducational`}>Educational</Trans>, 
        desc: "",
        emoji: "🎓",
        onboarding: false
    },   
];

const StepCompanyCommunicationStyle = ({ form, setForm, errors }) => {

    const options = communicationStyleOptions;

    return (
        <div className="w-full">
            <Segment defaultValue={form?.company_language_type} className="gap-4 flex-col w-full justify-center">
                {options?.filter(e => !!e.onboarding).map((item, index) => (
                    <Segment.Item
                        name={item.name}
                        value={item.value}
                        key={item.value}
                    >
                        {({ ref, active, name, value, onSegmentItemClick }) => {
                            return (
                                <div
                                    ref={ref}
                                    className={classNames(
                                        'flex',
                                        'relative',
                                        'ring-1',
                                        'justify-between',
                                        'border',
                                        'rounded-3xl ',
                                        errors?.company_type
                                            ? 'border-red-600'
                                            : 'border-gray-300',
                                        'p-1 lg:py-2 px-2',
                                        'cursor-pointer',
                                        'select-none',
                                        'w-100',
                                        'md:w-full',
                                        'bg-white',
                                        active
                                            ? 'ring-transparent border border-[4px] border-primary-600 !bg-primary-50'
                                            : 'ring-transparent',
                                    )}
                                    onClick={() => {
                                        onSegmentItemClick();
                                        setForm({...form, company_language_type: value});
                                    }}
                                >
                                    <div className="gap-3 lg:gap-6 px-4 py-2 flex flex-row justify-center items-center w-full">
                                        <div className="w-12 h-12 bg-primary-600/30 border-4 border-primary-600/10 flex items-center justify-center rounded-full text-2xl">
                                            {item.emoji}
                                        </div>
                                        <div className="gap-0 flex flex-col flex-1">
                                            <h6 className="text-left mega-title !text-base -mt-1 !text-gray-900">{name}</h6>
                                            <p className="!text-xs lg:!text-sm text-left">{item.desc}</p>
                                        </div>
                                        <HiCheckCircle className={`text-gray-200 text-2xl ${active && `!text-primary-600`}`} />
                                    </div>
                                </div>
                            )
                        }}
                    </Segment.Item>
                ))}
            </Segment>
            {errors?.company_language_type &&
                <p className="text-center text-red-600">{errors?.company_language_type}</p>
            }
        </div>
    )
}

export default StepCompanyCommunicationStyle;
import classNames from "classnames";
import { FormContainer, FormItem, Input, Segment } from "components/ui";
import { Field } from "formik";
import React, { useEffect } from "react";
import { HiCheckCircle } from "react-icons/hi";

export const languageOptions = [
    { "iso_code": "en", "value": "English (US)", "image": "/img/flags/us.png", "disabled": false },
    { "iso_code": "en", "value": "English (GB)", "image": "/img/flags/gb.png", "disabled": false },
    { "iso_code": "es", "value": "Spanish", "image": "/img/flags/es.png", "disabled": false },
    { "iso_code": "es", "value": "Spanish (LATAM)", "image": "/img/flags/es.png", "disabled": false },
    { "iso_code": "es", "value": "Spanish (MX)", "image": "/img/flags/mx.png", "disabled": false },
    { "iso_code": "fr", "value": "French", "image": "/img/flags/fr.png", "disabled": false },
    { "iso_code": "de", "value": "German", "image": "/img/flags/de.png", "disabled": false },
    { "iso_code": "it", "value": "Italian", "image": "/img/flags/it.png", "disabled": false },
    { "iso_code": "pt", "value": "Portuguese", "image": "/img/flags/pt.png", "disabled": false },
    { "iso_code": "pt", "value": "Portuguese (Brazil)", "image": "/img/flags/br.png", "disabled": false },
    { "iso_code": "nl", "value": "Dutch", "image": "/img/flags/nl.png", "disabled": false },
    { "iso_code": "ru", "value": "Russian", "image": "/img/flags/ru.png", "disabled": false },
    { "iso_code": "ja", "value": "Japanese", "image": "/img/flags/jp.png", "disabled": false },
    { "iso_code": "ko", "value": "Korean", "image": "/img/flags/kr.png", "disabled": false },
    { "iso_code": "zh", "value": "Chinese", "image": "/img/flags/cn.png", "disabled": false },
    { "iso_code": "zh", "value": "Chinese (Simp)", "image": "/img/flags/cn.png", "disabled": false },
    { "iso_code": "ar", "value": "Arabic", "image": "/img/flags/ae.png", "disabled": false },
    { "iso_code": "hi", "value": "Hindi", "image": "/img/flags/in.png", "disabled": false },
    { "iso_code": "fil", "value": "Filipino", "image": "/img/flags/ph.png", "disabled": false },
    { "iso_code": "id", "value": "Indonesian", "image": "/img/flags/id.png", "disabled": false },
    { "iso_code": "ms", "value": "Malay", "image": "/img/flags/my.png", "disabled": false },
    { "iso_code": "vi", "value": "Vietnamese", "image": "/img/flags/vn.png", "disabled": false },
    { "iso_code": "th", "value": "Thai", "image": "/img/flags/th.png", "disabled": false },
    { "iso_code": "tr", "value": "Turkish", "image":"/img/flags/tr.png", "disabled": false },
    { "iso_code": "sv", "value": "Swedish", "image": "/img/flags/se.png", "disabled": false },
    { "iso_code": "no", "value": "Norwegian", "image": "/img/flags/no.png", "disabled": false },
    { "iso_code": "da", "value": "Danish", "image": "/img/flags/dk.png", "disabled": false },
    { "iso_code": "pl", "value": "Polish", "image": "/img/flags/pl.png", "disabled": false },
    { "iso_code": "cs", "value": "Czech", "image": "/img/flags/cz.png", "disabled": false },
    { "iso_code": "sk", "value": "Slovak", "image": "/img/flags/sk.png", "disabled": false },
    { "iso_code": "hu", "value": "Hungarian", "image": "/img/flags/hu.png", "disabled": false },
    { "iso_code": "ro", "value": "Romanian", "image": "/img/flags/ro.png", "disabled": false },
    { "iso_code": "bg", "value": "Bulgarian", "image": "/img/flags/bg.png", "disabled": false },
    { "iso_code": "sr", "value": "Serbian", "image": "/img/flags/rs.png", "disabled": false },
    { "iso_code": "hr", "value": "Croatian", "image": "/img/flags/hr.png", "disabled": false },
    { "iso_code": "el", "value": "Greek", "image": "/img/flags/gr.png", "disabled": false },
    { "iso_code": "cat", "value": "Catalan", "image": "/img/flags/cat.png", "disabled": false },
    { "iso_code": "he", "value": "Hebrew", "image": "/img/flags/il.png", "disabled": false },
    { "iso_code": "sl", "value": "Slovenian", "image": "/img/flags/sl.png", "disabled": false },
    { "iso_code": "ge", "value": "Georgian", "image": "/img/flags/ge.png", "disabled": false },
    { "iso_code": "mk", "value": "Macedonian", "image": "/img/flags/mk.png", "disabled": false },
];

const StepCompanyLanguage = ({ form, setForm, errors }) => {

    const title = "What language do you use?"
    const subtitle = "Choose your preferred language to ensure a seamless experience using Followr. Don't worry, you can always change it later.";

    useEffect(() => {
        if (!form?.company_language) {
            let lang = languageOptions[0]?.value;
            let code = languageOptions[0]?.iso_code;
            if (navigator?.language) {
                let _code = navigator?.language?.split("-")?.[0];
                if (languageOptions?.filter(e => e.iso_code == _code)?.length > 0) {
                    code = _code;
                    lang = languageOptions?.filter(e => e.iso_code == _code)?.[0]?.value;
                }
            } 
            setForm({...form, company_language: lang, company_language_iso_code: code});
        }
    }, [])
    
    return (
        <div className="h-auto">
            <Segment defaultValue={form?.company_language ?? 'English (US)'} value={form?.company_language} className="!block text-center max-h-none px-0 overflow-y-auto lg:!max-h-[none]">
                {languageOptions.map((item, index) => (
                    <Segment.Item
                        value={item.value}
                        key={item.value}
                        disabled={item.disabled}
                    >
                        {({ ref, active, value, onSegmentItemClick, disabled }) => {
                            return (
                                <div
                                    ref={ref}
                                    className={classNames(
                                        'inline-flex',
                                        'whitespace-nowrap',
                                        'ring-1',
                                        'justify-between',
                                        'items-center m-1',
                                        'border',
                                        'rounded-xl',
                                        errors?.company_language
                                            ? 'border-red-600'
                                            : 'border-gray-300',
                                        'py-2 px-2',
                                        'cursor-pointer',
                                        'select-none',
                                        'w-fit',
                                        'bg-white',
                                        active
                                            ? 'ring-cyan-500 border-cyan-500 !bg-primary-100'
                                            : 'ring-transparent',
                                        disabled
                                            ? 'opacity-50 cursor-not-allowed'
                                            : 'hover:ring-cyan-500 hover:border-cyan-500'
                                    )}
                                    onClick={() => {
                                        onSegmentItemClick();
                                        setForm({...form, company_language: value, company_language_iso_code: item?.iso_code});
                                    }}
                                >
                                    <div className="gap-1.5 flex items-center">
                                        <img src={item.image} className="w-3 h-3 lg:w-4 lg:h-4 rounded-full object-cover object-center shadow" />
                                        <p className="!text-xs leading-0 !m-0 !text-gray-800 flex-1">{item.value}</p>
                                        {active && (
                                            <HiCheckCircle className="text-cyan-500 text-lg" />
                                        )}
                                    </div>
                                </div>
                            )
                        }}
                    </Segment.Item>
                ))}
            </Segment>
            {errors?.company_language &&
                <p className="text-center text-red-600">Please select an option</p>
            }
        </div>
    )
}

export default StepCompanyLanguage;
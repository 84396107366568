import { getAspectRatio } from "./getAspectRatio";
import { proxyUrl } from "./proxy";

const UNSPLASH_API_ENDPOINT = "https://api.unsplash.com";
const UNSPLASH_CLIENT_ID = process.env.REACT_APP_UNSPLASH_CLIENT_ID;

const PEXELS_API_ENDPOINT = "https://api.pexels.com/v1";
const PEXELS_VIDEO_API_ENDPOINT = "https://api.pexels.com/videos";
const PEXELS_API_KEY = process.env.REACT_APP_PEXELS_API_KEY;

const PIXABAY_API_ENDPOINT = "https://pixabay.com/api";
const PIXABAY_API_KEY = process.env.REACT_APP_PIXABAY_API_KEY;

const GIPHY_API_ENDPOINT = "https://api.giphy.com/v1/gifs/search";
const GIPHY_RANDOM_API_ENDPOINT = "https://api.giphy.com/v1/gifs/random";
const GIPHY_API_KEY = process.env.REACT_APP_GIPHY_API_KEY;

export const getAllStockMedia = async (count = 10, page = 1, query = "", orientation = "", allowedTypes = ["image", "video"]) => {
    let promises = [];
    if (allowedTypes.includes("image")) promises = [...promises, getUnsplashImages(count, query, orientation)];
    if (allowedTypes.includes("image")) promises = [...promises, getPexelsImages(count, page, query, orientation)];
    if (allowedTypes.includes("image")) promises = [...promises, getPixabayImages(count, page, query, orientation)];
    if (allowedTypes.includes("gif")) promises = [...promises, getGiphyImages(count, page, query, orientation)];
    if (allowedTypes.includes("video")) promises = [...promises, getPexelsVideos(count, page, query, orientation)];
    let data = await Promise.allSettled(promises);
    data = data?.filter(e => !!e.value).map(e => e.value)?.flat();
    return data;
}

// orientation: landscape, portrait, squarish
export const getUnsplashImages = async (count = 10, query = "", orientation = "") => {

    if (orientation == "square") orientation = "squarish";

    try {
        let data = await fetch(`${UNSPLASH_API_ENDPOINT}/photos/random?client_id=${UNSPLASH_CLIENT_ID}&count=${count}${query ? `&query=${query}` : ``}${orientation ? `&orientation=${orientation}`: ``}`, {
            method: 'GET'
        }).then(response => {
            try {
                return response.json()
            } catch (err) { console.error(err) }
            return []
        });
    
        if (data?.length > 0) {
            data = data.map((item) => { 
                return  {
                    ...item, 
                    src: item.urls.raw + "&w=250", 
                    large: item.urls.regular, 
                    original: item.urls.raw + "&w=1720", 
                    description: item.description,  
                    aspectRatio: getAspectRatio(item.width, item.height),
                    isStock: true,
                    stockUserProfileLink: item?.user?.links?.html + `?utm_source=followr&utm_medium=referral`,
                    stockUserProfileImage: item?.user?.profile_image?.small,
                    stockUserName: item?.user?.name,
                    stockPlatform: "Unsplash",
                    stockPlatformLink: `https://unsplash.com/?utm_source=followr&utm_medium=referral`,
                    stockDownloadEndpoint: item?.links?.download_location + `&client_id=${UNSPLASH_CLIENT_ID}`
                }
            });
            return data;
        }
    } catch (err) { console.error(err) }

    return [];
}

export const getGiphyImages = async (count = 20, page = 1, query = "", orientation = "") => {

    try {
        let data = await fetch(`${query ? GIPHY_API_ENDPOINT : GIPHY_RANDOM_API_ENDPOINT}?api_key=${GIPHY_API_KEY}&limit=${count}&offset=${(page-1) * count}${query ? `&q=${query}` : ``}&aspect=1:1`, {
            method: 'GET'
        }).then(response => {
            try {
                return response.json()
            } catch (err) { console.error(err) }
            return []
        });
    
        if (data && data?.data?.length > 0) {
            data = data.data.map((item) => { 
              const width = parseInt(item.images.downsized.width);
              const height = parseInt(item.images.downsized.height);
              const aspectRatio = width / height;

              return  {
                ...item, 
                src: item.images.fixed_width_still.url, 
                large: item.images.downsized.url, 
                original: item.images.downsized.url,
                aspectRatio: width === height ? `aspect-square` : aspectRatio > 1 ? `aspect-[16/9]` : `aspect-[9/16]`,
                isStock: true,
                stockUserProfileLink: "",
                stockUserProfileImage: "",
                stockUserName: "",
                stockPlatform: "GIPHY",
                stockPlatformLink: `https://giphy.com//?utm_source=followr&utm_medium=referral`,
                stockPlatformAttributionImage: `/img/others/giphy-attribution.png`,
                stockDownloadEndpoint: "",
              }
            });

            if (orientation) {
              if (orientation === "square") {
                  data = data.filter(item => item.aspectRatio === "aspect-square");
              } else if (orientation === "landscape") {
                  data = data.filter(item => item.aspectRatio === "aspect-[16/9]");
              } else if (orientation === "portrait") {
                  data = data.filter(item => item.aspectRatio === "aspect-[9/16]");
              }
          }

            return data;
        }
    } catch (err) { console.error(err) }
    
    return [];
}


// orientation: landscape, portrait, square
export const getPexelsVideos = async (count = 10, page = 1, query = "", orientation = "") => {

    try {
        let data = await fetch(`${PEXELS_VIDEO_API_ENDPOINT}/search?per_page=${count}&page=${page}${query ? `&query=${query}` : ``}${orientation ? `&orientation=${orientation}` : ``}`, {
            method: 'GET',
            headers: { 'Authorization': PEXELS_API_KEY }
        }).then(response => {
            try {
                return response.json()
            } catch (err) { console.error(err) }
            return []
        });
    
        if (data) {
            data = data.videos.map((item) => { return  {
                ...item, 
                src: item.video_files[1].link, 
                large: item.video_files[1].link, 
                original: item.video_files[1].link,
                aspectRatio: parseInt(item.width) == parseInt(item.height) ? `aspect-square` : parseInt(item.width) > parseInt(item.height) ? `aspect-[16/10]` : `aspect-[10/16]`,
                isStock: true,
                stockUserProfileLink: item?.user?.url,
                stockUserProfileImage: "",
                stockUserName: item?.user?.name,
                stockPlatform: "Pexels",
                stockPlatformLink: `https://www.pexels.com/?utm_source=followr&utm_medium=referral`,
                stockDownloadEndpoint: "",
            }});
            return data;
        }
    } catch (err) { console.error(err) }

    return [];
}

// orientation: landscape, portrait, square
export const getPexelsImages = async (count = 10, page = 1, query = "", orientation = "") => {

    try {
        let data = await fetch(`${PEXELS_API_ENDPOINT}/search?per_page=${count}&page=${page}${query ? `&query=${query}` : ``}${orientation ? `&orientation=${orientation}` : ``}`, {
            method: 'GET',
            headers: { 'Authorization': PEXELS_API_KEY }
        }).then(response => {
            try {
                return response.json()
            } catch (err) { console.error(err) }
            return []
        });
    
        if (data) {
            data = data.photos.map((item) => { return  {
                ...item, 
                srcs: item?.src,
                src: item?.src?.original + `?auto=compress&cs=tinysrgb&w=350`, 
                large: item?.src?.original + `?auto=compress&cs=tinysrgb&${getAspectRatio(item.width, item.height) == "aspect-[16/10]" ? `h` : `w` }=712`, 
                original: item?.src?.original + `?auto=compress&cs=tinysrgb&w=1920&h=1920`,
                aspectRatio: getAspectRatio(item.width, item.height),
                isStock: true,
                stockUserProfileLink: item?.photographer_url,
                stockUserProfileImage: "",
                stockUserName: item?.photographer,
                stockPlatform: "Pexels",
                stockPlatformLink: `https://www.pexels.com/?utm_source=followr&utm_medium=referral`,
                stockDownloadEndpoint: "",
            }});
            return data;
        }
    } catch (err) { console.error(err) }

    return [];
}


// orientation: landscape, portrait, square
export const getPixabayImages = async (count = 10, page = 1, query = "", orientation = "landscape") => {

    if (orientation == "square") return [];
    if (orientation == "portrait") orientation = "vertical";
    if (orientation == "landscape") orientation = "horizontal";

    try {
        let data = await fetch(`${PIXABAY_API_ENDPOINT}/?key=${PIXABAY_API_KEY}&image_type=photo&per_page=${count}&page=${page}${query ? `&q=${query}` : ``}&orientation=${orientation}`, {
            method: 'GET',
        }).then(response => {
            try {
                return response.json()
            } catch (err) { console.error(err) }
            return []
        });
    
        if (data) {
            data = data.hits.map((item) => { return  {
                ...item, 
                src: proxyUrl(item.webformatURL),
                large: proxyUrl(item.largeImageURL),
                original: proxyUrl(item.largeImageURL),
                aspectRatio: parseInt(item.imageWidth) == parseInt(item.imageHeight) ? `aspect-square` : parseInt(item.imageWidth) > parseInt(item.imageHeight) ? `aspect-[16/10]` : `aspect-[10/16]`,
                isStock: true,
                stockUserProfileLink: item?.pageURL,
                stockUserProfileImage: item?.userImageURL,
                stockUserName: item?.user,
                stockPlatform: "Pixabay",
                stockPlatformLink: `https://www.pixabay.com/?utm_source=followr&utm_medium=referral`,
                stockDownloadEndpoint: ""
            }});
            return data;
        }
    } catch (err) { console.error(err) }

    return [];
}

// orientation: landscape, portrait, square
export const getPixabayVideos = async (count = 10, page = 1, query = "", orientation = "landscape") => {

    if (orientation == "square") return;
    if (orientation == "portrait") orientation = "vertical";
    if (orientation == "landscape") orientation = "horizontal";

    let data = await fetch(`${PIXABAY_API_ENDPOINT}/videos?key=${PIXABAY_API_KEY}&per_page=${count}&page=${page}${query ? `&q=${query}` : ``}&orientation=${orientation}`, {
        method: 'GET',
    }).then(response => {
        try {
            return response.json()
        } catch (err) { console.error(err) }
        return []
    });

    if (data) {
        data = data.hits.map((item) => { return  {
            ...item, 
            src: item.videos.medium.url, 
            original: item.videos.medium.url 
        }});
        return data;
    }

    return [];
}

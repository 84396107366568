import React, { useState } from 'react'
import { Spinner } from 'components/ui'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { GuardSpinner, MagicSpinner, TraceSpinner } from 'react-spinners-kit'

const DefaultLoading = (props) => {
    const {
        loading,
        children,
        spinnerClass,
        className,
        asElement: Component,
        customLoader,
        isMagic = true
    } = props

    return loading ? (
        <Component
            className={classNames(
                !customLoader && 'flex items-center justify-center h-full',
                className
            )}
        >
            {customLoader ? (
                <>{customLoader}</>
            ) : (
                isMagic 
                    ? <MagicSpinner 
                        loading={loading}
                        size={40}
                        color={"#00a9c0"}
                    /> 
                    : <Spinner className={spinnerClass} size={40} />
            )}
        </Component>
    ) : (
        <>{children}</>
    )
}

const CoveredLoading = (props) => {
    const {
        loading,
        children,
        spinnerClass,
        className,
        asElement: Component,
        customLoader,
    } = props

    return (
        <Component className={classNames(loading ? 'relative' : '', className)}>
            {children}
            {loading && (
                <div className="w-full h-full bg-white dark:bg-gray-800 dark:bg-opacity-60 bg-opacity-50 absolute inset-0" />
            )}
            {loading && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                    <MagicSpinner 
                        loading={loading}
                        size={40}
                        color={"#00a9c0"}
                    /> 
                </div>
            )}
        </Component>
    )
}

const ElementLoader = (props) => {

    const {
        children,
        spinnerClass,
        className,
        asElement: Component,
        customLoader,
        size = 40,
    } = props

    return (
        <div className={`mx-auto text-center w-full flex items-center justify-center ${className}`}>
            <MagicSpinner 
                loading={true}
                size={parseInt(size)}
                color={"#00a9c0"}
            /> 
        </div>
    )
}

const Loading = (props) => {
    switch (props.type) {
        case 'element':
            return <ElementLoader {...props} />
        case 'default': 
            return <DefaultLoading {...props} />
        case 'cover':
            return <CoveredLoading {...props} />
        default:
            return <DefaultLoading {...props} />
    }
}

Loading.defaultProps = {
    loading: false,
    type: 'default',
    asElement: 'div',
}

Loading.propTypes = {
    loading: PropTypes.bool,
    spinnerClass: PropTypes.string,
    type: PropTypes.oneOf(['default', 'cover', 'element']),
    customLoader: PropTypes.node,
}

export default Loading

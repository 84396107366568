export const particleBackgroundSettingsButton = {
    particle: {
        particleCount: 25,
        color: "#fff",
        minSize: 1,
        maxSize: 3
    },
    velocity: {
        minSpeed: 0.1,
        maxSpeed: 0.3
    },
    opacity: {
        minOpacity: 0,
        maxOpacity: 0.3,
        opacityTransitionTime: 10000
    }
}

export const particleBackgroundSettingsMd = {
    particle: {
        particleCount: 50,
        color: "#fff",
        minSize: 1,
        maxSize: 3
    },
    velocity: {
        minSpeed: 0.1,
        maxSpeed: 0.3
    },
    opacity: {
        minOpacity: 0,
        maxOpacity: 0.3,
        opacityTransitionTime: 10000
    }
}

export const particleBackgroundSettings = {
    particle: {
        particleCount: 100,
        color: "#fff",
        minSize: 1,
        maxSize: 4
    },
    velocity: {
        minSpeed: 0.1,
        maxSpeed: 0.4
    },
    opacity: {
        minOpacity: 0,
        maxOpacity: 0.3,
        opacityTransitionTime: 10000
    }
}

export const particleBackgroundSettingsPrimary = {
    particle: {
        particleCount: 125,
        color: "#00a9c0",
        minSize: 1,
        maxSize: 4
    },
    velocity: {
        minSpeed: 0.1,
        maxSpeed: 0.4
    },
    opacity: {
        minOpacity: 0,
        maxOpacity: 0.3,
        opacityTransitionTime: 10000
    }
}
import { CompanyContext } from "contexts/CompanyContext";
import { useContext } from "react";
import { getAllStockMedia } from "utils/stockImages";

export const useStockMedia = () => {

    const { company } = useContext(CompanyContext);

    const getStockMedia = async (count = 10, page = 1, query = "", orientation = "", allowedTypes = ["image", "video", "gif"]) => {
        
        let data = [];
        if (!query) query = company?.interests?.sort(() => 0.5 - Math.random())?.pop() || "social media";

        data = await getAllStockMedia(Math.ceil(count / allowedTypes.length), page, query, orientation, allowedTypes);
        
        return data.sort(() => Math.random() - 0.5);
    }

    return { getStockMedia }
}


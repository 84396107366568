
const getVideoDuration = async (uri) => new Promise(resolve => {
    var vid = document.createElement('video');
    vid.src = uri;
    vid.ondurationchange = function() {
        resolve(this.duration);
        vid.remove();
    };
});

export default getVideoDuration;

import PlusBadge from 'components/shared/PlusBadge';
import { Button, Dropdown } from 'components/ui';
import React from 'react'
import { Trans } from 'react-i18next';
import { BsStars } from 'react-icons/bs';
import useImageGenerationQuantity from 'utils/hooks/useImageGenerationQuantity';

export default function AIImageGenerateButton({
  isLoading,
  query,
  generateImages
}) {

  const maxSelection = 4;
  const maxAllowedSelection = useImageGenerationQuantity();

  return (
    <Dropdown
      placement="top-end"
      disabled={isLoading || !query?.trim()}
      parentClassName="w-full"
      renderTitle={
        <Button 
          loading={isLoading}
          disabled={isLoading || !query?.trim()}
          variant="ai" 
          size="md" 
          className="w-full flex gap-2 items-center !text-sm flex-1 justify-center !rounded-t-none"
          icon={<BsStars />}
        >
          <Trans i18nKey={`aiImage.generate`}>Generate</Trans>
        </Button>
      }
    >
      {[...Array(maxSelection).keys()].map((key) => (
        key+1 <= maxAllowedSelection 
          ? <Dropdown.Item 
            onClick={() => generateImages(key+1)} 
            className="py-2"
          >
            Generate {key+1} image{key != 0 && `s`}
          </Dropdown.Item>
          : <Dropdown.Item 
            onClick={(e) => e?.stopPropagation()} 
            className="py-2 flex items-center justify-between gap-6"
          >
            <span className='opacity-30'>Generate {key+1} image{key != 0 && `s`}</span>
            <PlusBadge 
              wrapperClassName={`shrink-0 -mr-0.5`}
              className={`text-2xl`} 
              feature={`Multiple AI Image Generation`}
              placement="right"
            />
          </Dropdown.Item>
          
      ))}
    </Dropdown>
  );
}

export default function acronym(name = '') {
    const shortName = name?.toUpperCase()?.match(/\b(\w)/g)

    if (shortName) {
        if (shortName?.length > 2) {
            shortName?.splice(1, 1);
        }
        return shortName?.join('')
    }

    return name
}

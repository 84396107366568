export function stripHTML(myString) {
   if (!myString) myString = "";
   myString = String(myString);
   return myString.replace( /(<([^>]+)>)/ig, '');
}

export function stripHTMLReplaceWithNewLines(myString) {
   if (!myString) myString = "";
   myString = String(myString);
   myString = myString.replaceAll(`</p>`, '\n')?.replaceAll(`</h1>`, `\n`)?.replaceAll(`</h2>`, `\n`)?.replaceAll(`</blockquote>`, `\n`)?.replaceAll(`<br>`, `\n`)?.replaceAll(`<br/>`, `\n`);
   return stripHTML(myString);
}
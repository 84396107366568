import React, { useContext, useEffect, useState } from 'react'
import { Button, Dialog, Dropdown, Input, Notification, Progress, toast } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { HiOutlineChevronDown, HiOutlineChip, HiOutlineLightningBolt, HiOutlinePlusCircle } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/formatNumber';
import { BsCheckCircleFill, BsCircle, BsLayers } from 'react-icons/bs';
import { MdLayers } from 'react-icons/md';
import { HiOutlineSquare2Stack, HiOutlineSquare3Stack3D, HiOutlineUser, HiPlus } from 'react-icons/hi2';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import Api from 'services/Api';
import { SessionContext } from 'contexts/SessionContext';
import MySocialIcon from 'components/shared/MySocialIcon';
import { TbLoader3 } from 'react-icons/tb';
import { ModalContext } from 'contexts/ModalContext';
import InputModern from 'components/ui/Input/InputModern';
import { Trans } from 'react-i18next';

const MediumIntegrationModal = () => {

    const { updateUserData } = useContext(SessionContext);
    const { company, updateSocials } = useContext(CompanyContext);
    const [ accessToken, setAccessToken ] = useState("");
    const [ isLoading, setIsLoading ] = useState();
    const [ isDialogImageOpen, setIsDialogImageOpen ] = useState(false);
    const { closeModalPriority } = useContext(PriorityModalContext);

    const handleSubmit = () => {
        setIsLoading(true);
        Api.post(`/companies/${company?.id}/socialNetworks`, {}, { type: "medium", social_token: accessToken }).then((response) => {
            updateUserData();
            updateSocials();
            closeModalPriority();
        }).catch((err) => {
            toast.push(<Notification title={err.message} type="danger" />);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <>
            <div className='flex flex-col gap-8'>
                <img src="/img/socials-3d/medium.png" className='w-28 mx-auto drop-shadow' />
                <div className='flex flex-col gap-3'>
                    <h3 className='text-gray-900 mega-title text-center'><Trans i18nKey={`mediumIntegration.title`}>Medium Integration</Trans></h3>
                    <p className='text-center'><Trans i18nKey={`mediumIntegration.subtitle`}>In order to publish on behalf of your Medium account, you will need an access token. An access token grants limited access to a user’s account.</Trans></p>
                    {/* <h6 className='text-gray-700 font-normal'>Follow this easy steps to connect your Medium account to Followr.</h6> */}
                </div>

                <div className="overflow-y-auto flex flex-col gap-2.5">
                    <div className={`flex justify-between items-center gap-4 rounded-2xl py-1.5 px-4 transition-all`}>
                        <div className='flex items-center gap-4'>
                            <div className='w-10 h-10 bg-primary-600/30 border-4 border-primary-600/10 flex items-center justify-center rounded-full overflow-hidden'>
                                <h4 className='mega-title p-0 -mt-1'>1</h4>
                            </div>
                            <div className='w-full flex-1 text-gray-800 dark:text-gray-100'><Trans i18nKey={`mediumIntegration.login`}>Login into your Medium account</Trans>: <a href='https://medium.com/m/signin' target="_blank" className="!text-primary-600">https://medium.com/m/signin</a>.</div>
                        </div>
                    </div>
                    <div className={`flex justify-between items-center gap-4 rounded-2xl py-1.5 px-4 transition-all`}>
                        <div className='flex items-center gap-4'>
                            <div className='w-10 h-10 bg-primary-600/30 border-4 border-primary-600/10 flex items-center justify-center rounded-full overflow-hidden'>
                                <h4 className='mega-title p-0 -mt-1'>2</h4>
                            </div>
                            <div className='w-full flex-1 text-gray-800 dark:text-gray-100'><Trans i18nKey={`mediumIntegration.privacySettings`}>Go to your account privacy settings</Trans>: <a href='https://medium.com/me/settings/security' target="_blank" className="!text-primary-600">https://medium.com/me/settings/security</a>.</div>
                        </div>
                    </div>
                    <div className={`flex justify-between items-center gap-4 rounded-2xl py-1.5 px-4 transition-all`}>
                        <div className='flex items-center gap-4'>
                            <div className='w-10 h-10 bg-primary-600/30 border-4 border-primary-600/10 flex items-center justify-center rounded-full overflow-hidden'>
                                <h4 className='mega-title p-0 -mt-1'>3</h4>
                            </div>
                            <div className='w-full flex-1 text-gray-800 dark:text-gray-100'><Trans i18nKey={`mediumIntegration.tokens`}>Click on "Integration Tokens" at the bottom of the page and create a token.</Trans> <a href="#" className='!text-primary-600' onClick={() => setIsDialogImageOpen(true)}><Trans i18nKey={`mediumIntegration.viewImage`}>View image</Trans></a></div>
                        </div>
                    </div>
                    <div className={`flex justify-between items-center gap-4 rounded-2xl py-1.5 px-4 transition-all`}>
                        <div className='flex items-center gap-4 w-full'>
                            <div className='w-10 h-10 bg-primary-600/30 border-4 border-primary-600/10 flex items-center justify-center rounded-full overflow-hidden'>
                                <h4 className='mega-title p-0 -mt-1'>4</h4>
                            </div>
                            <div className='w-full flex-1 text-gray-800 dark:text-gray-100'>
                                <Input 
                                    className="!w-full bg-gray-100 dark:bg-gray-900 !border-0 !rounded-2xl"
                                    value={accessToken} 
                                    onChange={(e) => setAccessToken(e.target.value)}
                                    placeholder="Copy and paste the created access token here..." 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-end gap-2 p-4 -m-6 w-auto mt-10 border-t border-gray-200 dark:border-gray-700'>
                <Button onClick={closeModalPriority}><Trans i18nKey={`mediumIntegration.cancel`}>Cancel</Trans></Button>
                <Button loading={isLoading} disabled={!accessToken} variant="solid" onClick={handleSubmit} icon={<img src="/img/socials/medium.svg" className="!h-4 !w-4 shrink-0" />}>&nbsp; <Trans i18nKey={`mediumIntegration.connectMedium`}>Connect Medium</Trans></Button>
            </div>
            
            <Dialog 
                contentClassName="!p-0 !overflow-hidden"
                onClose={() => setIsDialogImageOpen(false)}
                isOpen={isDialogImageOpen}
            >
                <img src="/img/others/medium-image.jpg" className='w-full' />
            </Dialog>
        </>
    )
}

export default MediumIntegrationModal

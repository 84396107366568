import { Loading } from "components/shared";
import { Progress } from "components/ui";
import { UploadContext } from "contexts/UploadContext";
import React, { useContext } from "react";
import { Trans } from "react-i18next";
import { AiOutlineLoading } from "react-icons/ai";
import { HiCheckCircle, HiOutlineX } from "react-icons/hi";
import { formatNumber } from "utils/formatNumber";

const UploadModal = () => {

    const { 
        filesUploadModal, 
        closeUploadModal, 
        totalFileQuantityUploadModal, 
        actualFileProgressUploadModal 
    } = useContext(UploadContext);

    const loadeableFilesToUpload = filesUploadModal.filter((f) => !f?.asset?.id);
    
    let percent = (((totalFileQuantityUploadModal - loadeableFilesToUpload?.length) / totalFileQuantityUploadModal) * 100) + (actualFileProgressUploadModal / totalFileQuantityUploadModal)
    percent = percent > 100 ? 100 : percent;
    percent = isNaN(percent) ? 0 : percent;

    return (
        <div className="fixed right-0 bottom-0 lg:right-5 lg:bottom-5 p-6 lg:p-8 px-6 lg:px-10 w-full lg:w-[450px] rounded-t-xl lg:rounded-xl shadow-xl bg-gradient-primary text-white transition-all z-[999990000000000]">
            <HiOutlineX className="absolute right-6 top-4 text-white text-2xl cursor-pointer" onClick={closeUploadModal} />
            <div className="flex items-center gap-8 w-full">
                <div className="">
                    <img src="/img/others/loading-character.png" className="w-20 pointer-events-none" />
                </div>
                <div className={`flex flex-col items-center gap-6 w-full flex-1 transition-all ${loadeableFilesToUpload?.length == 0 ? `opacity-0` : ``}`}>
                    <div className="flex justify-start w-full">
                        <Loading type={`element`} />
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                        <p className="!text-sm text-white font-bold"><Trans i18nKey={`uploadModal.uploading`}>Uploading</Trans> {totalFileQuantityUploadModal} <Trans i18nKey={`uploadModal.file`}>file</Trans>{totalFileQuantityUploadModal > 1 ? 's' : ''}...</p>
                        <div className="flex flex-col gap-1">
                            <Progress percent={Math.ceil(percent)} showInfo={false} />
                            <div className="flex justify-end !text-xs">
                                <span>{(totalFileQuantityUploadModal - loadeableFilesToUpload?.length)} / {totalFileQuantityUploadModal} ({formatNumber(percent, 2)}%)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HiCheckCircle className={`text-8xl text-green-500 absolute right-36 top-1/2 translate-y-[-50%] ${loadeableFilesToUpload?.length == 0 ? `` : `opacity-0`}`} />
        </div>
    );
}

export default UploadModal;
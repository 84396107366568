function get_filesize(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("HEAD", url, true);
    xhr.onreadystatechange = function() {
        if (this.readyState == this.DONE) {
            callback(parseInt(xhr.getResponseHeader("Content-Length")));
        }
    };
    xhr.send();
}

const getFileSize = async (uri) => new Promise(resolve => {
    get_filesize(uri, function(size) {
        resolve(size);
    });
});

export default getFileSize;

import { Button, Dropdown } from "components/ui";
import { PLACEMENT } from "components/ui/utils/constant";
import { useEffect, useState } from "react";
import { BsStars, BsThreeDots } from "react-icons/bs";
import { LuTextCursorInput } from "react-icons/lu";
import { getFileTypeImageVideo } from "utils/fileType";
import { getImageAICaptionURL } from "utils/getImageAICaption";
import useCopyGenerator from "utils/hooks/useCopyGenerator";

const MagicTextOptionsButton = ({
    setValue,
    network,
    isTitle = false,
    post = undefined,
    size = "xs"
}) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const { generateTitleFromContext, generateCopyFromContext, generateCopyFromAssets, generateTitleFromAssets } = useCopyGenerator();

    const showGenerateFromImages = !!post?.assets?.filter(e => getFileTypeImageVideo(e?.image?.url || e?.image?.thumbnail?.url || e?.video?.url) == "image")?.length;
    const showSuggestFromCopy = isTitle && !!post?.text?.trim();
    const showSuggestFromTitle = !isTitle && !!post?.title?.trim();

    const handleOnGenerateFromImages = async () => {
        setIsLoading(true);
        const response = isTitle 
            ? await generateTitleFromAssets(network, post?.assets)
            : await generateCopyFromAssets(network, post?.assets)
        setValue(response);
        setIsLoading(false);
    }

    const handleOnGenerateFromCopy = async () => {
        setIsLoading(true);
        const response = await generateTitleFromContext(network, post?.text)
        setValue(response);
        setIsLoading(false);
    }

    const handleOnGenerateFromTitle = async () => {
        setIsLoading(true);
        const response = await generateCopyFromContext(network, post?.title)
        setValue(response);
        setIsLoading(false);
    }

    if (!showGenerateFromImages && !showSuggestFromCopy && !showSuggestFromTitle) return <></>;

    return (
        <Dropdown
            placement={PLACEMENT.BOTTOM_END}
            parentClassName="!text-sm"
            menuClass="w-auto max-w-[300px] max-h-[300px] overflow-y-auto !list-none dropdow-menu-item"
            disabled={isLoading}
            renderTitle={
                <Button
                    variant="ai"
                    icon={<BsThreeDots />}
                    size={size}
                    className="!rounded-lg"
                    loading={isLoading}
                    disabled={isLoading}
                />
            }
        >
            {showSuggestFromTitle && <Dropdown.Item onClick={handleOnGenerateFromTitle} className="flex items-center gap-2 py-2 px-4 !list-none">
                <LuTextCursorInput className='h-6 md:text-2xl text-fuchsia-500' />
                <span className="!text-sm">Suggest from title</span>
            </Dropdown.Item>}
            {showGenerateFromImages && <Dropdown.Item onClick={handleOnGenerateFromImages} className="flex items-center gap-2 py-2 px-4 !list-none">
                <BsStars className='h-6 md:text-2xl text-purple-500' />
                <span className="!text-sm">Suggest from images</span>
            </Dropdown.Item>}
            {showSuggestFromCopy && <Dropdown.Item onClick={handleOnGenerateFromCopy} className="flex items-center gap-2 py-2 px-4 !list-none">
                <LuTextCursorInput className='h-6 md:text-2xl text-fuchsia-600' />
                <span className="!text-sm">Suggest from copy</span>
            </Dropdown.Item>}
        </Dropdown>
    )
}

export default MagicTextOptionsButton;
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterUIEventsHandler = void 0;
const utils_1 = require("../utils");
const register_events_handler_base_1 = require("./register-events-handler.base");
class RegisterUIEventsHandler extends register_events_handler_base_1.RegisterEventsHandlerBase {
    constructor(commands, window) {
        super(commands, window);
        this.commands = commands;
        this.window = window;
    }
    init() {
        if (!this.window.document.addEventListener) {
            return;
        }
        const options = this.supportsPassiveEvents() ?
            { passive: true, capture: true } :
            true;
        document.addEventListener('click', (e) => {
            if (e.target instanceof Element) {
                this.addJsEventLogEntry(this.getEventData(e.target, 'click'));
            }
        }, options);
        document.addEventListener('focusin', (e) => {
            if (e.target instanceof Element) {
                this.addJsEventLogEntry(this.getEventData(e.target, 'focus'));
            }
        }, options);
        document.addEventListener('focusout', (e) => {
            if (e.target instanceof Element) {
                this.addJsEventLogEntry(this.getEventData(e.target, 'blur'));
            }
        }, options);
        this.window.addEventListener('submit', (e) => {
            if (e.target instanceof Element) {
                this.addJsEventLogEntry(this.getEventData(e.target, 'submit'));
            }
        }, options);
        const debounceByEl = new utils_1.DebounceByElement(400);
        const ignoreList = [
            'Tab', 'Meta', 'MetaLeft', 'MetaRight', 'Alt', 'AltLeft', 'AltRight',
            'Control', 'ControlLeft', 'ControlRight', 'Shift', 'ShiftLeft', 'ShiftRight',
            'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown',
            'PageDown', 'PageUp', 'Home', 'End', 'CapsLock',
        ];
        this.window.addEventListener('keyup', (e) => {
            if (!(e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) ||
                !e.composedPath ||
                ignoreList.includes(e.code)) {
                return;
            }
            const target = e.target;
            const path = e.composedPath();
            debounceByEl.run(target, () => {
                this.sendKeyupLog(target, path);
            });
        }, options);
    }
    sendKeyupLog(target, path) {
        var _a;
        const isPasswordEl = ((_a = target.getAttribute('type')) !== null && _a !== void 0 ? _a : '').toLowerCase() === 'password';
        const shouldSkipKeyLogging = path
            .filter((el) => el instanceof HTMLElement)
            .some((el) => 'bfIgnoreKeypress' in el.dataset);
        const shouldOmitText = isPasswordEl || shouldSkipKeyLogging;
        this.addJsEventLogEntry(Object.assign(Object.assign({}, this.getEventData(target, 'keyup')), { text: shouldOmitText ? undefined : target.value }));
    }
    getEventData(target, eventName) {
        const text = (target.childElementCount <= 1) ? target.textContent : undefined;
        const form = (target.tagName === 'FORM') ? target : null;
        return {
            event: eventName,
            url: (this.window) ? this.window.location.href : '',
            xpath: (0, utils_1.getXPath)(target),
            tag: target.tagName,
            id: target.id,
            text: text !== null && text !== void 0 ? text : undefined,
            action: (form && form.action) ? form.action : '',
        };
    }
}
exports.RegisterUIEventsHandler = RegisterUIEventsHandler;

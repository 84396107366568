import React, { useEffect, useState } from 'react'
import { HiOutlinePlus } from 'react-icons/hi';
import SocialNetworkCircle from './SocialNetworkCircle';

const FilterBadge = (props) => {

    const { 
        children, 
        active,
        onClick, 
        className
    } = props

    return (
        <div onClick={onClick} className={`flex flex-1 justify-center cursor-pointer items-center gap-1 !text-xs rounded-lg border border-gray-200 dark:border-gray-700 border-solid w-fit px-1.5 h-6 ${active ? `bg-primary-600/20 !border-0 text-primary-600 font-semibold` : ``} ${className}`}>
            {children}
        </div>
    );
}

export default FilterBadge

import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Dialog, Dropdown, Input, Notification, Progress, Skeleton, toast } from "components/ui";
import ParticleBackground from 'react-particle-backgrounds'
import { particleBackgroundSettings } from 'configs/particle-background.config';
import { formatNumber } from "utils/formatNumber";
import { CompanyContext } from "contexts/CompanyContext";
import { SessionContext } from "contexts/SessionContext";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineChevronDown, HiOutlineCreditCard, HiOutlinePlusCircle, HiPlus } from "react-icons/hi";
import { HiMinus, HiOutlineDocumentText, HiOutlineUser } from "react-icons/hi2";
import { IoBus, IoBusinessOutline } from "react-icons/io5";
import InputModern from "components/ui/Input/InputModern";
import AddOn from "./AddOn";
import { PriorityModalContext } from "contexts/PriorityModalContext";
import OutOfWordsModal from "components/layout/OutOfWordsModal";
import OutOfUsersModal from "components/layout/OutOfUsersModal";
import OutOfCompaniesModal from "components/layout/OutOfCompaniesModal";
import SelectPlanModal from "components/layout/SelectPlanModal";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import moment from "moment";
import { secondsToTimeLeftString } from "utils/secondsToDHMS";
import Api from "services/Api";
import { Trans } from "react-i18next";
import Subscription from ".";
import OutOfImagesModal from "components/layout/OutOfImagesModal";
import { TbPhotoAi } from "react-icons/tb";
import { AppSumoContext } from "contexts/AppSumoContext";
import { IoIosPartlySunny } from "react-icons/io";

const MySubscription = () => {
    
    const { isSumoling, isPlus, getTier } = useContext(AppSumoContext);
    const { company } = useContext(CompanyContext);
    const { user, updateUserData } = useContext(SessionContext);
    const { openModalPriority } = useContext(PriorityModalContext);
    const [ isPriceDetailsDialogOpen, setIsPriceDetailsDialogOpen ] = useState(false);
    const [ showCancelSubscriptionModal, setShowCancelSubscriptionModal ] = useState(false);
    const [ isLoadingResumeSubscription, setIsLoadingResumeSubscription ] = useState(false);
    const [ totalMonthly, setTotalMonthly ] = useState(0);
    const [ isLoadingTotalMonthly, setIsLoadingTotalMonthly ] = useState(false)
    const [ upcomingInvoice, setUpcomingInvoice ] = useState();
    const navigate = useNavigate();

    const plan = user?.subscription?.items?.filter(e => e.product.type == "plan")?.[0];
    const recurring = user?.subscription?.items?.[0]?.price?.recurring;

    const start = user?.subscription?.trial_ends_at ? new Date(user?.subscription?.trial_ends_at) : new Date(user?.subscription?.created_at);
    let end = start;
    if (recurring == "year") {
        end.setFullYear(new Date().getFullYear());
        if (end < new Date()) {
            end = end.getTime() + 1000 * 3600 * 24 * 365;
        }
    } else {
        end.setFullYear(new Date().getFullYear());
        end.setMonth(new Date().getMonth());
        if (end < new Date()) {
            end = end.getTime() + 1000 * 3600 * 24 * 365 / 12;
        }
    }

    const resumeSubscription = () => {
        setIsLoadingResumeSubscription(true);
        Api.put(`/subscriptions`, { resume: 1 }).then((response) => {
            updateUserData();
            toast.push(<Notification type="success" title="Your subscription has been resumed!" />);
        }).catch((err) => {
            toast.push(<Notification type="danger" title={err?.message} />);
        }).finally(() => {
            setIsLoadingResumeSubscription(false);
        });
    }

    useEffect(() => {
        if (company?.id && (!company || company?.role?.name != "admin")) {
            toast.push(<Notification title="You don't have access to this section on this company." type="warning" />)
            navigate(`/home`);
        } 
    }, [company?.role?.name]);

    useEffect(() => {
        setIsLoadingTotalMonthly(true);
        Api.get(`/invoices/upcoming`, {}).then(({ data: invoice }) => {
            setUpcomingInvoice(invoice);
            setTotalMonthly(invoice?.total / 100 / (recurring == "year" ? 12 : 1));
        }).catch((err) => {

        }).finally(() => {
            setIsLoadingTotalMonthly(false);
        });
    }, [JSON.stringify(user?.subscription || {})]);

    return (
        <div className='flex w-full h-full gap-4 p-4 lg:p-6'>
            <Card className="flex-1 shrink-0 w-full overflow-hidden !m-0" bodyClass="flex flex-col lg:flex-row !p-0 h-auto">
                <div className='p-6 py-10 px-10 bg-gradient-primary flex flex-row lg:flex-col justify-between overflow-hidden relative'>
                    <ParticleBackground settings={particleBackgroundSettings} className="absolute pointer-events-none -m-10" />
                    <div>
                        <h1 className='mega-title text-white'>
                            {!!isSumoling() && !!getTier()
                                ? <>
                                    Tier {getTier()}
                                </>
                                : <>
                                    <Trans i18nKey="subscriptionSideInfo.plan">Plan</Trans>&nbsp;
                                    {plan?.product?.label}
                                </>
                            }
                        </h1>
                        <p className="text-white mt-2">{plan?.product?.description}</p>
                    </div>
                    <img src={`/img/others/super-plan-${plan?.product?.name}.png`} className="hidden lg:block w-[240px] object-contain mx-8 z-10 relative drop-shadow" />
                </div>
                <div className='w-full flex-1 overflow-y-auto'>
                    {!isSumoling() && <>
                        <div className='p-6 lg:p-10 flex flex-col gap-8 w-full'>
                            {user?.subscription?.stripe_status == 'past_due' && 
                                <Alert className="w-full" type="danger" title="Payment error." showIcon customIcon={<HiOutlineCreditCard />}>
                                    <Trans i18nKey={`subscription.cardError`}>We couldn't charge your card. Please, check your card details and try again.</Trans> {!!user?.subscription?.ends_at && `You won't be able to access your account after ${moment(new Date(user?.subscription?.ends_at)).format("MMMM DD, YYYY")}.`}
                                    <br />
                                    <br />
                                    <Link to={`/settings/billing`}><b><Trans i18nKey={`subscription.goToBilling`}>Go to Billing</Trans></b></Link>
                                </Alert>
                            }
                            <div className='flex flex-col lg:flex-row gap-6 w-full items-center justify-between'>
                                {!isLoadingTotalMonthly
                                    ? <div>
                                        <h1 className='mega-title text-5xl'>
                                            <sup className='text-base'>$</sup> 
                                            &nbsp;{formatNumber(totalMonthly, 2)} 
                                            <small className='text-base normal-font'> <Trans i18nKey={`subscription.perMonth`}>per month</Trans></small>
                                        </h1>
                                        <small className='ml-3 block'><Trans i18nKey={`subscription.billed`}>Billed</Trans> {recurring == "year" ? `Annualy` : `Monthly`} {recurring == "year" && `($${formatNumber(totalMonthly * 12, 2)})`}</small>
                                        <small className="ml-3 block text-primary-600 cursor-pointer" onClick={() => setIsPriceDetailsDialogOpen(true)}><Trans i18nKey={`subscription.viewPriceDetails`}>View price details</Trans></small>
                                    </div>
                                    : <div className="flex flex-col gap-2">
                                        <Skeleton variant="rectangle" className="w-[300px] !h-[45px]" />
                                        <Skeleton variant="rectangle" className="w-[300px] !h-[14px]" />
                                        <Skeleton variant="rectangle" className="w-[300px] !h-[14px]" />
                                    </div>
                                }
                                <div className="flex flex-col justify-center items-center gap-1.5">
                                    {!isPlus()  
                                        ? !user?.subscription?.ends_at 
                                            ? <>
                                                <Button variant="solid" onClick={() => openModalPriority(<Subscription isModal={true} />, 1200, true, true, `!p-0 !bg-gray-100 dark:!bg-gray-900 zoom-95`)}>Change Plan</Button>
                                                <span className="cursor-pointer text-red-600 text-xs" onClick={() => setShowCancelSubscriptionModal(true)}>Cancel subscription</span>
                                            </> 
                                            : <>
                                            <div className="flex gap-2 items-center">
                                                <Button variant="twoTone" onClick={() => openModalPriority(<Subscription isModal={true} />, 1200, true, true, `!p-0 !bg-gray-100 dark:!bg-gray-900 zoom-95`)}><Trans i18nKey={`subscription.changePlan`}>Change Plan</Trans></Button>
                                                <Button variant="solid" loading={isLoadingResumeSubscription} disabled={isLoadingResumeSubscription} onClick={() => resumeSubscription()}><Trans i18nKey={`subscription.resumeSubscription`}>Resume Subscription</Trans></Button>
                                            </div>
                                            <span className="text-xs text-center">
                                                <Trans i18nKey={`subscription.yourSubscriptionHasBeenCancelled`}>Your subscription has been cancelled.</Trans>
                                                {/* <br/> */}
                                                {/* <Trans i18nKey={`subscription.youWontBeAbleToAccess`}>You won't be able to access the platform after</Trans> {moment(end).format("MMMM DD, YYYY")}.<br/>{secondsToTimeLeftString(Math.ceil((new Date(end).getTime() - new Date().getTime()) / 1000))} <Trans i18nKey={`subscription.left`}>left.</Trans> */}
                                            </span>
                                            </>
                                        : <>
                                            <span className="cursor-pointer text-red-600 text-xs" onClick={() => setShowCancelSubscriptionModal(true)}>Cancel Followr Plus</span>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <hr />
                    </>}
                    <div className='p-6 lg:p-10 flex flex-col gap-8 w-full'>
                        <div className='flex flex-col gap-2'>
                            <div className='flex items-center justify-between'>
                                <span className='text-base font-bold'>
                                    <Trans i18nKey={`subscription.words`}>Words</Trans>
                                </span>
                                <span className='text-base text-right font-bold'>
                                    {formatNumber(user?.balance?.words_spent)} <span className="text-gray-300 font-normal">/ {formatNumber(user?.balance?.words_allowed)}</span>
                                </span>
                            </div>
                            <Progress percent={(user?.balance?.words_spent / user?.balance?.words_allowed) * 100} showInfo={false} size={'md'} />
                            <div className='flex justify-between items-start'>
                                <div className='flex flex-col'>
                                    <small>{formatNumber(user?.balance?.words_allowed - user?.balance?.words_spent)} <Trans i18nKey={`subscription.remaining`}>remaining</Trans></small>
                                </div>
                                <small><Trans i18nKey={`subscription.wordBalanceReset`}>Word balance resets at</Trans>: {new Date(user?.balance?.words_renews_at).toLocaleDateString()}</small>
                                {/* <Button variant="twoTone" size="sm" className="!text-xs" icon={<HiPlus className='!text-xs' />}>&nbsp;Add Words</Button> */}
                            </div>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <div className='flex items-center justify-between'>
                                <span className='text-base font-bold'>
                                    <Trans i18nKey={`subscription.aiImages`}>AI Images</Trans>
                                </span>
                                <span className='text-base text-right font-bold'>
                                    {formatNumber(user?.balance?.images_spent)} <span className="text-gray-300 font-normal">/ {formatNumber(user?.balance?.images_allowed)}</span>
                                </span>
                            </div>
                            <Progress percent={(user?.balance?.images_spent / user?.balance?.images_allowed) * 100} showInfo={false} size={'md'} />
                            <div className='flex justify-between items-start'>
                                <div className='flex flex-col'>
                                    <small>{formatNumber(user?.balance?.images_allowed - user?.balance?.images_spent)} <Trans i18nKey={`subscription.remaining`}>remaining</Trans></small>
                                </div>
                                <small><Trans i18nKey={`subscription.imageBalanceReset`}>Image balance resets at</Trans>: {new Date(user?.balance?.words_renews_at).toLocaleDateString()}</small>
                                {/* <Button variant="twoTone" size="sm" className="!text-xs" icon={<HiPlus className='!text-xs' />}>&nbsp;Add Words</Button> */}
                            </div>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <div className='flex items-center justify-between'>
                                <span className='text-base font-bold'>
                                    <Trans i18nKey={`subscription.users`}>Users</Trans>
                                </span>
                                <span className='text-base text-right font-bold'>
                                    {formatNumber(user?.balance?.users_spent)} <span className="text-gray-300 font-normal">/ {formatNumber(user?.balance?.users_allowed)}</span>
                                </span>
                            </div>
                            <Progress percent={(user?.balance?.users_spent / user?.balance?.users_allowed) * 100} showInfo={false} size={'md'} />
                            <div className='flex justify-between items-start'>
                                <div className='flex flex-col'>
                                    <small>{formatNumber(user?.balance?.users_allowed - user?.balance?.users_spent)} <Trans i18nKey={`subscription.remaining`}>remaining</Trans></small>
                                    {/* <small className='text-primary-600'><Link to="/company-settings/access">Invite users</Link></small> */}
                                </div>
                                {/* <Button variant="twoTone" size="sm" className="!text-xs" icon={<HiPlus className='!text-xs' />}>&nbsp;Add Users</Button> */}
                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div className='flex items-center justify-between'>
                                <span className='text-base font-bold'>
                                    <Trans i18nKey={`subscription.companies`}>Companies</Trans>
                                </span>
                                <span className='text-base text-right font-bold'>
                                    {formatNumber(user?.balance?.companies_spent)} <span className="text-gray-300 font-normal">/ {formatNumber(user?.balance?.companies_allowed)}</span>
                                </span>
                            </div>
                            <Progress percent={(user?.balance?.companies_spent / user?.balance?.companies_allowed) * 100} showInfo={false} size={'md'} />
                            <div className='flex justify-between items-start'>
                                <div className='flex flex-col'>
                                    <small>{formatNumber(user?.balance?.companies_allowed - user?.balance?.companies_spent)} <Trans i18nKey={`subscription.remaining`}>remaining</Trans></small>
                                    {/* <small className='text-primary-600'><Link to="/onboarding">Create company</Link></small> */}
                                </div>
                                {/* <Button variant="twoTone" size="sm" className="!text-xs" icon={<HiPlus className='!text-xs' />}>&nbsp;Add Companies</Button> */}
                            </div>
                        </div>
                    </div>
                    {!isSumoling() && !isPlus() && <>
                        <hr />
                        <div className='p-6 lg:p-10 flex flex-col gap-8 w-full'>
                            <div className="flex items-center justify-between">
                                <span className='text-base font-bold'><Trans i18nKey={`subscription.myAddOns`}>My Add-ons</Trans></span>
                                <Dropdown
                                    placement="top-end"
                                    menuClass="max-h-[300px] overflow-y-auto"
                                    renderTitle={
                                        <Button className="flex items-center justify-between gap-6" variant="twoTone">
                                            <span><Trans i18nKey={`subscription.newAddOns`}>New Add-Ons</Trans></span>
                                            <HiOutlineChevronDown />
                                        </Button>
                                    }
                                >
                                    <Dropdown.Item className="!py-2 !px-4" onClick={() => !user?.pm_last_four ? openModalPriority(<Subscription isModal={true} />, 1200) : openModalPriority(<OutOfImagesModal limit={false} />)} eventKey="new_item">
                                        <TbPhotoAi className="text-xl text-purple-600" />
                                        <span><Trans i18nKey={`subscription.addImages`}>Add AI-Images</Trans></span>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="!py-2 !px-4" onClick={() => !user?.pm_last_four ? openModalPriority(<Subscription isModal={true} />, 1200) : openModalPriority(<OutOfWordsModal limit={false} />)} eventKey="new_item">
                                        <HiOutlineDocumentText className="text-xl text-fuchsia-500" />
                                        <span><Trans i18nKey={`subscription.addWords`}>Add AI-Words</Trans></span>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="!py-2 !px-4"  onClick={() => !user?.pm_last_four ? openModalPriority(<Subscription isModal={true} />, 1200) : openModalPriority(<OutOfUsersModal limit={false} />)}eventKey="new_item">
                                        <HiOutlineUser className="text-xl text-primary-600" />
                                        <span><Trans i18nKey={`subscription.addUsers`}>Add Users</Trans></span>
                                    </Dropdown.Item>
                                    <Dropdown.Item className="!py-2 !px-4" onClick={() => !user?.pm_last_four ? openModalPriority(<Subscription isModal={true} />, 1200) : openModalPriority(<OutOfCompaniesModal limit={false} />)} eventKey="new_item">
                                        <IoBusinessOutline className="text-xl text-secondary-600" />
                                        <span><Trans i18nKey={`subscription.addCompanies`}>Add Companies</Trans></span>
                                    </Dropdown.Item>
                                </Dropdown>
                            </div>
                            <div className="flex flex-col gap-3">
                                {user?.subscription?.items?.filter(item => item.product.type == "add-on").map((item, key) => (
                                    <AddOn recurring={recurring} key={key} item={item} />
                                ))}
                            </div>
                        </div>
                    </>}
                </div>
            </Card>

            <Dialog
                isOpen={isPriceDetailsDialogOpen}
                onClose={() => setIsPriceDetailsDialogOpen(false)}
                onRequestClose={() => setIsPriceDetailsDialogOpen(false)}
                overlayClassName="flex items-center justify-center w-full"
            >
                <h4 className="mega-title"><Trans i18nKey={`subscription.priceDetails`}>Price Details</Trans></h4>
                <div className="mt-10 flex flex-col divide-y divide-y-gray-200 dark:divide-y-gray-700">
                    {upcomingInvoice?.lines?.data?.map((item, key) => (
                        <div key={key} className="flex justify-between items-center py-4 gap-8">
                            <div>{item?.description}</div>
                            <div className="flex flex-col justify-end items-end">
                                {/* {item?.discount_amounts?.map(i => i.amount)?.length && item?.discount_amounts?.map(i => i.amount)?.reduce((a,b) => a+b) > 0 && <h6 className="leading-[14px] text-right line-through !text-xs opacity-[0.5]">
                                    {formatNumber(item?.amount / 100, 2)} / {recurring}
                                </h6>} */}
                                <h5 className="mega-title leading-[14px] text-right">
                                    <small>$ </small> 
                                    {formatNumber((item?.amount - parseInt(item?.discount_amounts?.[0]?.amount || 0)) / 100, 2)}
                                    <small className="text-gray-600 !text-xs normal-font"> / {recurring}</small>
                                </h5>
                            </div>
                        </div>
                    ))}
                </div>
                <hr />
                <div className="flex justify-between items-center py-4 pt-12 gap-8">
                    <h3 className="capitalize mega-title leading-[14px]">Total</h3>
                    <h3 className="mega-title leading-[14px] text-right">
                        <small>$ </small> 
                        {formatNumber(upcomingInvoice?.total / 100, 2)}
                        <small className="text-gray-600 !text-xs normal-font"> / {recurring}</small>
                    </h3>
                </div>
            </Dialog>
            {showCancelSubscriptionModal && <CancelSubscriptionModal onCancel={() => setShowCancelSubscriptionModal(false)} />}
        </div>
    )
}

export default MySubscription;
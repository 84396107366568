import OutOfWordsModal from "components/layout/OutOfWordsModal";
import SelectPlanModal from "components/layout/SelectPlanModal";
import { CompanyContext } from "contexts/CompanyContext";
import { PriorityModalContext } from "contexts/PriorityModalContext";
import { SessionContext } from "contexts/SessionContext";
import { get, replace } from "lodash";
import { useContext, useEffect, useState } from "react";
import Api from "services/Api";
import { replaceAll } from "utils/replaceAll";
import { formatSocialPostObj } from "../../formatSocialPostObj";
import { getSocialNetworkIntegration } from "utils/getSocialNetworkIntegration";

export const useTiktok = () => {

    const { company, companySocials } = useContext(CompanyContext);
    const _start_date = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000);
    const _end_date = new Date();
    

    const getTiktokPostsInsights = async (
        per_page = 100, // 100
        start_date = _start_date,
        end_date = _end_date
    ) => {
        const socialNetwork = getSocialNetworkIntegration("tiktok", companySocials);
        if (!socialNetwork) return [];

        return await getTiktokPosts(per_page, start_date, end_date);
    }

    const getTiktokPosts = async (
        per_page = 100, // 100
        start_date = _start_date,
        end_date = _end_date
    ) => {
        const socialNetwork = getSocialNetworkIntegration("tiktok", companySocials);
        if (!socialNetwork) return [];

        start_date = new Date(start_date).toISOString();
        end_date = new Date(end_date).toISOString();

        // if (localStorage.getItem(`${company.id}_${socialNetwork.id}_${start_date.split("T")[0]}${end_date.split("T")[0]}`)) {
        //     return JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_${start_date.split("T")[0]}${end_date.split("T")[0]}`));
        // }

        return await Api.get(`/socialNetworks/${socialNetwork.id}/posts?${end_date ? `&cursor=${new Date(end_date).getTime()}` : ``}`).then(({ data: posts }) => { 
            
            posts = posts?.filter((post) => new Date(post?.create_time * 1000) >= new Date(start_date) && new Date(post?.create_time * 1000) <= new Date(end_date));
            
            posts = posts?.map((post) => {
                return formatSocialPostObj(
                    "tiktok",
                    post?.id,
                    socialNetwork?.external_id,
                    socialNetwork?.name,
                    socialNetwork?.username,
                    socialNetwork?.image?.url,
                    post?.create_time * 1000,
                    post?.title,
                    [{
                        type: "video",
                        url: post?.cover_image_url,
                        width: post?.width,
                        height: post?.height
                    }],
                    post?.like_count,
                    post?.comment_count,
                    post?.view_count,
                    post?.share_count,
                    "",
                    "",
                    post?.embed_link
                )
            });
            localStorage.setItem(`${company.id}_${socialNetwork.id}_${start_date.split("T")[0]}${end_date.split("T")[0]}`, JSON.stringify(posts));
            return posts; 
        });
    }

    return { getTiktokPosts, getTiktokPostsInsights }
}


import { ModalContext } from "contexts/ModalContext";
import React, { useContext, useEffect, useState } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { TbSettings } from "react-icons/tb";
import useConfigPanel from "utils/hooks/useConfigPanel";
import Prompts from "views/company_settings/components/Prompts";
import ConfigPanelPostTypesOptions from "./ConfigPanelPostTypesOptions";

const { Dropdown, Tooltip } = require("components/ui");

const ConfigPanelPostTypes = React.forwardRef((props, ref) => {

    const {
        postType,
        setPostType,
        template = "",
        className = "",
        parentClassName = "",
        menuClassName = "",
        placement = "bottom-start",
        renderTitle = undefined,
        hideChecks = false,
        selectedPrompts = [],
        onSelectPrompt = undefined
    } = props;
    const { postTypes } = useConfigPanel(template, postType);

    const { openModal, isOpened } = useContext(ModalContext);

    const PromptsComponent = 
        <Prompts 
            network={postType}
            selectable={true} 
            selected={selectedPrompts}
            isModal={true}
            onSelect={(i) => {
                onSelectPrompt(i);
            }}
        />

    useEffect(() => {
        if (isOpened && !hideChecks) openModal(PromptsComponent, 720) 
    }, [selectedPrompts?.length]);

    return (
        <Dropdown
            menuClass={`max-h-[350px] w-[240px] overflow-y-auto !rounded-2xl !border-0 !p-0 ${menuClassName}`}
            placement={placement}
            parentClassName={parentClassName}
            ref={ref}
            renderTitle={renderTitle || 
                <div className={`shrink-0 cursor-pointer w-full !pl-5 overflow-hidden whitespace-nowrap !pr-5 !py-3 !h-auto bg-gray-50 dark:bg-gray-600 rounded-2xl flex !justify-start gap-2 items-center font-semibold ${className}`} >
                    {postTypes?.filter(e => e.value == postType)?.[0]?.emoji 
                        ? <>{postTypes?.filter(e => e.value == postType)?.[0]?.emoji}&nbsp;</> 
                        : ``
                    } 
                    {postTypes?.filter(e => e.value == postType)?.[0]?.name || postTypes?.[0]?.name}

                    {!!onSelectPrompt && 
                        <Tooltip wrapperClass="shrink-0" title="Select prompts">
                            <TbSettings 
                                onClick={(e) => { 
                                    e.stopPropagation(); 
                                    openModal(PromptsComponent, 720) 
                                }} 
                                className="text-lg ml-1" 
                            />
                        </Tooltip>
                    }
                </div>
            }
        >
            <ConfigPanelPostTypesOptions 
                postType={postType}
                setPostType={setPostType}
                hideChecks={hideChecks}
            />
        </Dropdown>
    );
});

export default ConfigPanelPostTypes;
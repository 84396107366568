export const INSTAGRAM_PROMPTS = [
    `
        Write an Instagram post in first person with the following Inspirational Quote Structure:
        1) Inspirational Quote
        2) Personal Interpretation
        3) Real-Life Application
        4) Encouragement
        5) Call-to-Action

        Do not mention each step of the structure

        Example:
        "✨ 'The only limit to our realization of tomorrow is our doubts of today.' - Franklin D. Roosevelt.\n\nThis quote reminds me that the barriers we face are often self-imposed. Believe in your potential, take that first step, and watch how far you can go. What doubt will you leave behind today? 🌟\n\nShare your thoughts!\n\n#Inspiration #MondayMotivation"
    `,
    `
        Write an Instagram post in first person with the following Question & Engagement Structure:
        1) Engaging Question
        2) Context/Explanation
        3) Personal Insight
        4) Call to action inviting to Engage
        5) Relevant Hashtags

        Do not mention each step of the structure

        Example:
        "💭 What's the one book that changed your life? For me, it was 'The Alchemist' by Paulo Coelho. 📖 \n\nThe lessons on following your dreams and listening to your heart were truly transformative. I'd love to know your life-changing read! \n\nDrop the title in the comments! 👇 #BookRecommendations #LifeChangingBooks"
    `,
    `
        Write an Instagram post in first person with the following Storytelling Approach Structure:
        1) Hook
        2) Relatable Scenario
        3) Main Story
        4) Resolution/Message
        5) Call-to-Action

        Do not mention each step of the structure

        Example:
        "🌟 Once upon a time, I was terrified of public speaking. 🙈 \n\nI remember sweating bullets before every presentation. But one day, I decided to face my fear head-on. Through practice and persistence, I conquered my anxiety and now speak confidently at events worldwide.\n\nIf you're struggling with something similar, know that you can overcome it too! 💪 \n\nWhat's your biggest fear? Share below! 👇 \n\n#OvercomeChallenges #PublicSpeakingJourney"
    `,
    `
        Write an Instagram post in first person with the following How-to-guide Structure:
        1) Use a catchy headline
        2) Briefly introduce the topic and what the guide will cover
        3) Create a step by step of the topic
        4) Summarize the steps
        5) Include a call to action

        Do not mention each step of the structure
    `,
    `
        Write an Instagram post in first person with the following Storytelling Approach Structure:

        Hook
        Relatable Scenario
        Main Story
        Resolution/Message
        Call-to-Action

        Do not mention each step of the structure
    `,
    `
        Write an Instagram post in first person with the following Teaser structure:

        Step 1: Teaser
        Step 2: Engagement Paragraph

        Do not mention each step of the structure
        Do not introduce yourself
    `,
    `
        Write an Instagram post in first person with the following structure:

        Start with a Quote. Then write a Personal Reflection explaining why this quote is meaningful to me. Finally insert a Call to Action encouraging followers to share their thoughts or favorite quotes

        Do not mention each step of the structure
    `,
    `
        Write a plain instagram post in first person with the following structure:
        Start with a Hook: Pose a question or present a challenge.
        Then create a List: Provide a numbered list of tips or steps.
        Then make a Summary: Recap the benefits of following the tips.
        Finally insert a Call to Action: Ask followers to share their own tips or ask questions.

        Do not mention each step of the structure
        Do not add any other paragraphs
        Do not introduce yourself
        Use only 2 hashtags
    `,
    `
        Write a short instagram post in first person with the following structure:
        First start with an appreciation
        Then make a highlight about the topic
        Finally insert a call to action

        Do not introduce yourself
        Do not mention each step of the structure
    `,
    `
        Create an instagram post talking in first person with the following structure:

        Opening Hook: A question or intriguing statement.
        Main Content: Describe the behind-the-scenes process.
        Call to Action: Invite followers to share their own experiences or thoughts.

        Do not mention each step of the structure
    `,
    `
        Write a plain short Instagram post describing the topic. Do not use HTML
    `,
    `
        Write a very short Instagram post. Add a CTA in a separate paragraph at the end.
    `,
    `
        Write a very short Instagram post using between 100 and 150 characters. Add a CTA in a separate paragraph at the end.
    `,
    `
        Write a Instagram post use between 200 and 260 characters. Add a CTA to encourage followers to comment on the post.
    `
];
import React, { useContext, useEffect, useState } from 'react'
import { Button, Dialog, Dropdown, Input, Notification, Progress, toast } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { HiOutlineChevronDown, HiOutlineChip, HiOutlineLightningBolt, HiOutlinePlusCircle } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/formatNumber';
import { BsCheckCircleFill, BsCircle, BsLayers } from 'react-icons/bs';
import { MdLayers } from 'react-icons/md';
import { HiOutlineSquare2Stack, HiOutlineSquare3Stack3D, HiOutlineUser, HiPlus } from 'react-icons/hi2';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import Api, { API_HOST } from 'services/Api';
import { SessionContext } from 'contexts/SessionContext';
import MySocialIcon from 'components/shared/MySocialIcon';
import { TbLoader3 } from 'react-icons/tb';
import { ModalContext } from 'contexts/ModalContext';
import InputModern from 'components/ui/Input/InputModern';
import { CalendarActiveSocialNetworkContext } from 'contexts/CalendarActiveSocialNetworkContext';
import { Trans } from 'react-i18next';

const LinkedinIntegrationModal = () => {

    const { closeModalPriority } = useContext(PriorityModalContext);
    const { linkedInPageSelectModalOpened } = useContext(CalendarActiveSocialNetworkContext);
    const { company } = useContext(CompanyContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isCollapsed, setIsCollapsed ] = useState(true);

    const handleSubmit = () => {
        window.open(`${API_HOST}/companies/${company?.id}/socialNetworks/linkedin?token=${localStorage.getItem(`token`)}`);
        setIsLoading(true);
    }

    useEffect(() => {
        if (linkedInPageSelectModalOpened == true) {
            closeModalPriority();
        }
    }, [linkedInPageSelectModalOpened]);

    useEffect(() => {
        handleSubmit();
    }, []);

    return (
        <>
            <div className='flex flex-col gap-8 pt-4'>
                <img src="/img/socials-3d/linkedin.png" className='w-28 mx-auto drop-shadow' />
                <h3 className='hidden text-gray-900 mega-title text-center'><Trans i18nKey={`linkedinIntegration.title`}>LinkedIn Integration</Trans></h3>
                <p className='hidden text-center !text-base'><Trans i18nKey={`linkedinIntegration.quickAndEasy`}>Connecting your LinkedIn account is quick and easy. Simply click the "Connect with LinkedIn" button below, and you'll be redirected to authorize the connection between your LinkedIn profile and Followr.</Trans></p>
                {/* <p className='text-center !text-base'>Due to limitations on LinkedIn, connecting a <b>personal account</b> restricts access to users' posts and their associated insights. Consequently, you will only be able to schedule posts through the platform.</p> */}
                <p className='text-left !text-base hidden'>
                    <b className='block text-center'><Trans i18nKey={`linkedinIntegration.permissions`}>Followr will request the following permissions</Trans>: <span onClick={() => setIsCollapsed(!isCollapsed)} className='text-primary-600 cursor-pointer'>{isCollapsed ? `View` : `Hide`} permissions</span></b>
                    <div className={`overflow-hidden transition-all rounded-2xl border border-gray-200 dark:border-gray-600 p-4 !text-sm bg-gray-50 dark:bg-gray-800 mt-4 ${isCollapsed && `hidden !h-0`}`}>
                        <ul className={`!list-disc`}>
                            <li className='ml-6'><b>r_organization_followers</b>: Use your followers' data so your organization can mention them in posts</li>
                            <li className='ml-6'><b>r_organization_social</b>: Retrieve your organization's posts, comments, reactions, and other engagement data</li>
                            <li className='ml-6'><b>rw_organization_admin</b>: Manage your organization's pages and retrieve reporting data</li>
                            <li className='ml-6'><b>r_organization_social_feed</b>: Retrieve comments, reactions, and other engagement data on your organization's posts</li>
                            <li className='ml-6'><b>w_member_social</b>: Create, modify, and delete posts, comments, and reactions on your behalf</li>
                            <li className='ml-6'><b>w_organization_social</b>: Create, modify, and delete posts, comments, and reactions on your organization's behalf</li>
                            <li className='ml-6'><b>r_basicprofile</b>: Use your basic profile including your name, photo, headline, and public profile URL</li>
                            <li className='ml-6'><b>w_organization_social_feed</b>: Create, modify, and delete comments and reactions on your organization's posts</li>
                            <li className='ml-6'><b>w_member_social_feed</b>: Create, modify, and delete comments and reactions on posts on your behalf</li>
                        </ul>
                    </div>
                </p>
                <p className='hidden text-center !text-base'><Trans i18nKey={`linkedinIntegration.makeSureConnected`}>Make sure that you are connected to the right LinkedIn account before connecting to Followr.</Trans></p>
            </div>

            <div className='flex justify-end gap-2 p-4 -m-6 w-auto mt-10 border-t border-gray-200 dark:border-gray-700'>
                <Button onClick={closeModalPriority}><Trans i18nKey={`linkedinIntegration.cancel`}>Cancel</Trans></Button>
                <Button loading={isLoading} variant="solid" onClick={handleSubmit} icon={<img src="/img/socials/linkedin.svg" className="!h-4 !w-4 shrink-0" />}>&nbsp; <Trans i18nKey={`linkedinIntegration.connectWith`}>Connect with LinkedIn</Trans></Button>
            </div>
        </>
    )
}

export default LinkedinIntegrationModal

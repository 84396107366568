import React, { useContext, useEffect, useState } from 'react'
import { Button, Dialog, Dropdown, Notification, Progress, toast } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { HiOutlineChevronDown, HiOutlineChip, HiOutlineLightningBolt, HiOutlinePlusCircle } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/formatNumber';
import { BsCheckCircleFill, BsCircle, BsLayers } from 'react-icons/bs';
import { MdLayers } from 'react-icons/md';
import { HiOutlineSquare2Stack, HiOutlineSquare3Stack3D, HiOutlineUser, HiPlus } from 'react-icons/hi2';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import Api from 'services/Api';
import { SessionContext } from 'contexts/SessionContext';
import MySocialIcon from 'components/shared/MySocialIcon';
import { TbLoader3 } from 'react-icons/tb';
import { CalendarActiveSocialNetworkContext } from 'contexts/CalendarActiveSocialNetworkContext';
import { Loading } from 'components/shared';
import { Trans } from 'react-i18next';
import { AppSumoContext } from 'contexts/AppSumoContext';

const AppSumoWelcomeModal = () => {

    const { setShowAppSumoWelcomeModal } = useContext(AppSumoContext);

    return (
        <Dialog
            isOpen={true}
            closable={true}
            onClose={() => setShowAppSumoWelcomeModal(false)}
            onRequestClose={() => setShowAppSumoWelcomeModal(false)}
            overlayClassName="flex w-full h-full items-center justify-center"
            width={550}
        >
            <img src="/img/others/appsumo-banner.jpg" className='object-fit object-center object-cover w-[120%] max-w-none h-[150px] -mt-6 -mx-[10%] !mb-12' />
            <div className='flex flex-col gap-8 mb-12'>
                <div className='flex flex-col gap-8'>
                    <h2 className='text-gray-900 mega-title text-center'><Trans i18nKey={`appSumo.thankYou`}>Thank you for your purchase!</Trans></h2>
                    <p className='text-center text-base'><Trans i18nKey={`appSumo.oneStepAway`}>You are just one step away from your lifetime Essential Plan.</Trans></p>
                    <p className='text-center text-base -mt-4'><Trans i18nKey={`appSumo.login`}>Just click on the "Continue" button and login or create a new account. Your Essential Plan will be activated right away!</Trans></p>
                </div>
            </div>

            <div className='flex justify-end gap-2 p-4 -m-6 w-auto mt-10 border-t border-gray-200 dark:border-gray-700'>
                <Button variant="solid" onClick={() => setShowAppSumoWelcomeModal(false)}><Trans i18nKey={`appSumo.continue`}>Continue</Trans></Button>
            </div>
        </Dialog>
    )
}

export default AppSumoWelcomeModal

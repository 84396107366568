import React, { useEffect, useState, useCallback, useContext } from 'react';
import Api, { API_HOST } from "services/Api";
import { Spinner, Button } from 'components/ui';
import CanvaDesign from './components/CanvaDesign';
import { CompanyContext } from 'contexts/CompanyContext';
import { HiOutlineSearch } from 'react-icons/hi';
import InputModern from "components/ui/Input/InputModern";
import { ResponsiveMasonry } from 'react-responsive-masonry';
import Masonry from 'react-responsive-masonry';
import { checkIfIsIntegrated } from 'utils/checkIfIsIntegrated';
import { checkIfIsExpired } from 'utils/checkIfIsExpired'

const Canva = ({ 
    simplificated = false,
    handleOnSelect = () => {},
    handleOnUnselect = () => {},
    selectedImages = [],
    isSelectable = false
}) => {
    const [designs, setDesigns] = useState([]);
    const [filteredDesigns, setFilteredDesigns] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [query, setQuery] = useState("");
    const { company, companySocials } = useContext(CompanyContext);
    const isIntegrated = checkIfIsIntegrated("canva", companySocials);
    const [isExpired, setIsExpired] = useState(checkIfIsExpired("canva"));

    const fetchDesigns = useCallback(async (companyId) => {
        if (companyId) {
            try {
                setIsLoading(true);
                const response = await Api.get(`/companies/${companyId}/canva/designs`);
                setDesigns(response.data);
                setFilteredDesigns(response.data);
            } catch (err) {
                onError(err);
            } finally {
                setIsLoading(false);
            }
        }
    }, [isExpired]);

    const onError = (err) => {
        setIsExpired(
            err.toString().includes("Token has expired") ||
            err.toString().includes("revoked") || 
            err.toString().includes("unauthorized")
        )
        setError(true);
    }

    useEffect(() => {
        if (isIntegrated) fetchDesigns(company?.id);
    }, [company, fetchDesigns, isIntegrated]);

    const handleSearch = () => {
        if (query?.trim() === '') {
            setFilteredDesigns(designs);
        } else {
            const filteredDesigns = designs.filter(design => 
                (design?.title || `Untitled Design`)?.toLowerCase()?.includes(query?.toLowerCase())
            );
            setFilteredDesigns(filteredDesigns);
        }
    }

    const integrateSection = (reintegrate = false) =>
        <div className='flex flex-col items-center justify-center gap-4'>
            <img src="/img/socials/canva.svg" alt="Canva" className="w-12" />
            <p className="text-center mb-0">
                {!reintegrate ? 
                    "Canva is not integrated. To integrate it, please click the button below."
                :
                    "The integration with Canva has expired. To re-integrate, please click the button below."
                }
            </p>
            <a 
                href={`${API_HOST}/companies/${company?.id}/socialNetworks/canva?token=${localStorage.getItem(`token`)}`} 
                onClick={() => localStorage.setItem(`back_url`, window.location.pathname + window.location.search)} 
                className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
            >
                {reintegrate ? "Reintegrate" : "Integrate"} Canva
            </a>
        </div>

    if (!isIntegrated) {
        return (
            <div className="flex flex-col items-center justify-center w-full h-64 text-center">
                {integrateSection()}
            </div>
        );
    }

    if (isLoading) {
        return <div className="flex justify-center items-center h-64"><Spinner size={28} /></div>;
    }
   
    if (error) {
        return (
            <div className="flex flex-col items-center justify-center w-full h-64 border-2 border-red-600 rounded-lg bg-red-100 text-center p-4">
                {isExpired ? integrateSection(true)  :
                    <p className="text-center mb-4">
                        An error occurred. Please try again later.
                    </p>
                }
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4 relative">
            <div className='p-0 relative flex-1'> 
                <InputModern 
                    onChange={(e) => setQuery(e.target.value)} 
                    className="!px-5 !py-5 !h-auto rounded-2xl !bg-gray-50/50 dark:!bg-gray-900 rounded-2xl" 
                    placeholder="Search..." 
                    onKeyUp={(e) => e.key == 'Enter' ? handleSearch() : void 0}
                />
                <div className='flex gap-2 items-center !absolute right-2.5 top-1/2 translate-y-[-50%] '>
                    <Button 
                        variant="twoTone" 
                        className="text-base !w-10 !h-10 !border-0 !rounded-2xl"
                        onClick={handleSearch}
                        icon={<HiOutlineSearch />}
                    />
                </div>
            </div>
            {!simplificated ? 
               <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1100: 4}}>
                   <Masonry gutter='1rem'>
                    {filteredDesigns.map((design) => (
                        <CanvaDesign 
                            key={design.id} 
                            design={design} 
                            company={company}
                            onError={onError}
                            handleOnSelect={handleOnSelect}
                            handleOnUnselect={handleOnUnselect}
                            selectedImages={selectedImages}
                            isSelectable={isSelectable}
                        />
                    ))}
                    </Masonry>
                </ResponsiveMasonry>
            :
                <div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] gap-2">
                    {filteredDesigns.map((design) => (
                        <CanvaDesign 
                            isDraggable={simplificated}
                            key={design.id} 
                            design={design} 
                            company={company}
                            onError={onError}
                            handleOnSelect={handleOnSelect}
                            handleOnUnselect={handleOnUnselect}
                            selectedImages={selectedImages}
                            isSelectable={isSelectable}
                        />
                    ))}
                </div>
            }
        </div>
    );
};

export default Canva;

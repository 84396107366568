import { CalendarActiveSocialNetworkContext } from "contexts/CalendarActiveSocialNetworkContext";
import { CompanyContext } from "contexts/CompanyContext"
import { AIContext } from "contexts/AIContext";
import { useContext } from "react"


const socialMediaPrompts = {
    twitter: {
        prompts: [
            "Write a Twitter post with a maximum of 280 characters.",
            "Write a twitter thread between 4 and 7 comments with a maximum of 280 characters per comment."
        ],
        configs: [
            "It must contain emojis.",
            "It must contain hashtags."
        ]
    },
    instagram: {
        prompts: [
            "Write an Instagram post between 50 to 80 words.",
        ],
        configs: [
            "It must contain emojis.",
            "It must contain hashtags.",
            "End with a catchy sentence in a different paragraph to make people engage with the post."
        ]
    },
    facebook: {
        prompts: [
            "Write a Facebook post between 50 to 80 words.",
        ],
        configs: [
            "It must contain emojis.",
            "It must contain hashtags.",
            "End with a catchy sentence in a different paragraph to make people engage with the post."
        ]
    },
    medium: {
        prompts: [
            "Write a blog with 250 to 500 words. Answer in HTML format. Include a title. Relate the blog topic with the company.",
        ],
        configs: []
    }
}

export const randomInt = (max) => {
    return Math.floor(Math.random() * max);
}

const randomBool = () => {
    return Math.random() < 0.5;
}

const formatOptions = (optionsString) => {
    let options = [];
    try {
        options = JSON.parse(optionsString);
    } catch {
        options = [];
    }
    return options;
}

const usePrompts = () => {
    
    const { company } = useContext(CompanyContext);
    const { calendarActiveSocialNetwork } = useContext(CalendarActiveSocialNetworkContext); 
    const { getByQueryChat } = useContext(AIContext);

    if (!company) return;
    
    const getPromptForNetwork = (network, query = "") => {
        let options = socialMediaPrompts[network]['prompts']?.length;
        let prompt = socialMediaPrompts[network]['prompts'][randomInt(options)];
        let configs = "";
        let querySuffix = query 
            ? `You must write about ${query}. ` 
            : company && company?.interests?.length
                ? `You must write about ${company?.interests[Math.floor(Math.random() * company?.interests?.length)]}. `
                : ``;
        socialMediaPrompts[network]['configs'].map((config) => {
            if (randomBool()) {
                configs += config;
            }
        });
        let finalPrompt = prompt + querySuffix + configs;
        return finalPrompt
    }

    const getPrompts = async (query = "", quantity = 5) => {
        let returnPrompts = [];

        let queryOptions = [];
        if (!query) {
            queryOptions = formatOptions(
                await getByQueryChat(
                    `
                        Give me ${quantity} interesting ${quantity == 1 ? `title` : `titles`} for a blog post. 
                        It may not include the company name. answer in json array format.

                        Interesting titles: 
                    `,
                    false
                )
            );
        }
        
        for (let index = 0; index < quantity; index++) {
            let network = calendarActiveSocialNetwork?.[randomInt(calendarActiveSocialNetwork?.length)];
            let options = socialMediaPrompts?.[network]?.['prompts']?.length;
            let prompt = socialMediaPrompts?.[network]?.['prompts']?.[randomInt(options)];
            let configs = "";
            let _query = query 
                ? query 
                : queryOptions && queryOptions?.length
                    ? queryOptions?.[index]
                    : ``;
            let querySuffix = query 
                ? `You must write about ${query}. ` 
                : queryOptions && queryOptions?.length
                    ? `You must write about ${queryOptions?.[index]}. `
                    : ``;
            socialMediaPrompts?.[network]?.['configs'].map((config) => {
                if (randomBool()) {
                    configs += config;
                }
            });
            let finalPrompt = prompt + querySuffix + configs;
            returnPrompts.push({ prompt: finalPrompt, topic: _query, networks: [network] });
        }

        return returnPrompts;
    }

    return { getPrompts, getPromptForNetwork }
}

export default usePrompts;
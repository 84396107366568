import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Dialog, Dropdown, Input, Notification, Progress, toast } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { HiOutlineChevronDown, HiOutlineChip, HiOutlineLightningBolt, HiOutlinePlusCircle } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/formatNumber';
import { BsCheckCircleFill, BsCircle, BsLayers } from 'react-icons/bs';
import { MdLayers } from 'react-icons/md';
import { HiOutlineSquare2Stack, HiOutlineSquare3Stack3D, HiOutlineUser, HiPlus } from 'react-icons/hi2';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import Api, { API_HOST } from 'services/Api';
import { SessionContext } from 'contexts/SessionContext';
import MySocialIcon from 'components/shared/MySocialIcon';
import { TbLoader3 } from 'react-icons/tb';
import { ModalContext } from 'contexts/ModalContext';
import InputModern from 'components/ui/Input/InputModern';
import { CalendarActiveSocialNetworkContext } from 'contexts/CalendarActiveSocialNetworkContext';
import capitalize from 'components/ui/utils/capitalize';
import { Trans } from 'react-i18next';

const SocialNetworkErrorModal = () => {

    const { setSocialNetworkErrorOpened } = useContext(CalendarActiveSocialNetworkContext);
    const [ network, setNetwork ] = useState();
    
    useEffect(() => {
        var url = new URL(window.location.href);
        var network = url.searchParams.get("social_network_type");
        setNetwork(network);
    }, [])

    return (
        <Dialog
            isOpen={true}
            closable={true}
            onClose={() => setSocialNetworkErrorOpened(false)}
            onRequestClose={() => setSocialNetworkErrorOpened(false)}
            overlayClassName="flex w-full h-full items-center justify-center"
            width={650}
        >
            <div className='flex flex-col gap-8 pt-4'>
                <img src={`/img/others/error-face-3d.png`} className='w-20 lg:w-28 mx-auto drop-shadow' />
                <h3 className='text-gray-900 mega-title text-center'><Trans i18nKey={`socialNetworkErrorModal.title`}>Oops... an error ocurred.</Trans></h3>
                <p className='text-center !text-base'>
                    <Trans i18nKey={`socialNetworkErrorModal.text`}>There was an error while trying to connect Followr to your social media account. We recommend that you contact our support center, we answer within 24hs.</Trans>
                </p>
            </div>

            <div className='flex justify-end gap-2 p-4 -m-6 w-auto mt-10 border-t border-gray-200 dark:border-gray-700'>
                <Button variant="plain" onClick={() => setSocialNetworkErrorOpened(false)}><Trans i18nKey={`socialNetworkErrorModal.close`}>Close</Trans></Button>
                <Button onClick={() => window.open("mailto:support@followr.help")} icon={<img src={`/img/others/support-1.png`} className="!h-6 shrink-0" />}>
                    &nbsp; <Trans i18nKey={`socialNetworkErrorModal.chatWithSupport`}>Contact Support</Trans>
                </Button>
            </div>
        </Dialog>
    )
}

export default SocialNetworkErrorModal


// const getImageDimensions = async (uri) => new Promise(resolve => {
//     Image.getSize(uri, (width, height) => {
//         resolve({ width: width, height: height });
//     });
// });

const getImageDimensions = async (uri) => new Promise(resolve => {
    const img = new Image();
    img.src = uri;
    img.onload = () => {
        resolve({ width: img.width, height: img.height });
    };
});

export default getImageDimensions;

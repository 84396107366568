import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Api from "services/Api";
import { getAspectRatio } from "utils/getAspectRatio";
import { CompanyContext } from "./CompanyContext";
import CopiesFilters from "components/layout/CopiesFilters";
import { SessionContext } from "./SessionContext";

const CopyContext = React.createContext({
    myCopies: [],
    updateMyCopies: () => void 0,
    deleteAssetMyCopies: (id) => void 0,
    isLoadingCopies: false,
    filtersMyCopies: [],
    setFiltersMyCopies: ({}) => void 0,
    filtersMyCopiesDrawerOpened: false,
    setFiltersMyCopiesDrawerOpened: (_openBool) => void 0,
    loadMoreMyCopies: () => void 0,
    hasMoreMyCopies: false
});

const CopyProvider = ({ children }) => {
    
    const [ myCopies, setMyCopies ] = useState([]);
    const [ isLoadingMyCopies, setIsLoadingMyCopies ] = useState(false);
    const { user } = useContext(SessionContext);
    const { company } = useContext(CompanyContext);
    const [ filtersMyCopies, setFiltersMyCopies ] = useState({});
    const [ filtersMyCopiesDrawerOpened, setFiltersMyCopiesDrawerOpened ] = useState(false);
    const [ loadMoreURL, setLoadMoreURL ] = useState(false);
    const isInitialMount = useRef(true);


    const updateMyCopies = async (loadMore = false) => {
        // if (!company) return;
        // setIsLoadingMyCopies(true);
        // let finalCopies = [];
        // try {
        //     await Api.get(loadMore && loadMoreURL ? loadMoreURL : `/companies/${company?.id}/assets?sort=-created_at&include=posts,image,image.thumbnail,video,video.thumbnail,user,children.user,children.user.image,user.image,children.image,children.video&filter[type]=text&filter[parent_id]=null${filtersMyCopies?.query ? `&filter[text]=${filtersMyCopies?.query}` : ``}${filtersMyCopies?.create_date_range && filtersMyCopies?.create_date_range?.length > 0 ? `&filter[created_at_after]=${new Date(filtersMyCopies?.create_date_range[0]).toDateString()}&filter[created_at_before]=${new Date(filtersMyCopies?.create_date_range[1]).toDateString()}` : ``}${filtersMyCopies?.users && filtersMyCopies?.users?.length > 0 ? `&filter[user_id]=${filtersMyCopies?.users?.map(e => e.value)?.join(",")}` : ``}${filtersMyCopies?.social_networks && filtersMyCopies?.social_networks?.length > 0 ? `&filter[social_network_type_with_children]=${filtersMyCopies?.social_networks?.map(e => e.value)?.join(",")}` : ``}${filtersMyCopies?.unused_assets ? `&filter[has_relation]=!posts` : ``}`, {}).then(({ data: copies, links, meta }) => {
        //         setLoadMoreURL(links?.next);
        //         const _totalCopies = loadMore && loadMoreURL ? [...myCopies, ...copies] : copies;
        //         finalCopies = _totalCopies; 
        //         setMyCopies(_totalCopies);
        //         window?.Intercom('update', {
        //             "copies_quantity": meta?.total ? meta?.total : 0
        //         });
        //     })
        // } catch (err) {}
        // setIsLoadingMyCopies(false);
        // return finalCopies;
    }

    const loadMoreMyCopies = () => {
        if (!loadMoreURL) return;
        updateMyCopies(true);
    }

    const deleteAssetMyCopies = async (id) => {
        return Api.delete(`/assets/${id}?include_children=1`, {}, {}).then(() => {
            return updateMyCopies();
        });
    }

    useEffect(() => {
        localStorage.setItem(`${user?.id}_filtersMyCopies`, JSON.stringify(filtersMyCopies));
    }, [filtersMyCopies]);

    useEffect(() => {
        if (!!user) {
            if (localStorage.getItem(`${user?.id}_filtersMyCopies`)) {
                try {
                    const _filters = JSON.parse(localStorage.getItem(`${user?.id}_filtersMyCopies`));
                    if (!!_filters) {
                        setFiltersMyCopies(_filters);
                    }
                } catch (err) {}
            }
        }
    }, [user?.id]);

    useEffect(() => {
        updateMyCopies();
    }, [company?.id]);

    useEffect(() => {
        if (!company) setMyCopies([]);
    }, [company]);

    useEffect(() => {
        if (isInitialMount.current) {
           isInitialMount.current = false;
        } else {
            updateMyCopies();
        }
    }, [filtersMyCopies]);

    return (
        <CopyContext.Provider value={{ 
            myCopies: myCopies,
            updateMyCopies: updateMyCopies,
            deleteAssetMyCopies: deleteAssetMyCopies,
            isLoadingMyCopies: isLoadingMyCopies,
            filtersMyCopies: filtersMyCopies,
            setFiltersMyCopies: setFiltersMyCopies,
            filtersMyCopiesDrawerOpened: filtersMyCopiesDrawerOpened,
            setFiltersMyCopiesDrawerOpened: setFiltersMyCopiesDrawerOpened,
            loadMoreMyCopies: loadMoreMyCopies,
            hasMoreMyCopies: loadMoreURL != "" && loadMoreURL != undefined
        }}>
            {children}
            {filtersMyCopiesDrawerOpened && <CopiesFilters />}
        </CopyContext.Provider>
    );
};

export { CopyContext, CopyProvider };
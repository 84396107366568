import React, { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineClear, AiOutlineFormatPainter, AiOutlineLink, AiOutlineUnorderedList, AiOutlineUserSwitch } from "react-icons/ai";
import { HiOutlineEmojiHappy, HiOutlineGlobe, HiOutlineMail, HiOutlinePhotograph, HiOutlineSpeakerphone, HiOutlineTranslate } from "react-icons/hi";
import { HiChevronLeft, HiChevronRight, HiMiniArrowPath, HiOutlineCpuChip, HiOutlineCursorArrowRays, HiOutlineGlobeAlt, HiOutlineHashtag, HiOutlineLightBulb, HiOutlinePencil, HiOutlineQuestionMarkCircle, HiOutlineUser, HiOutlineUserCircle, HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { IoColorWandOutline, IoLinkOutline, IoListCircleOutline, IoPeopleCircleOutline } from "react-icons/io5";
import { MdOutlineArticle, MdOutlineAutoFixHigh, MdOutlineFormatAlignCenter, MdOutlineFormatQuote, MdOutlineHistoryEdu, MdOutlineMood, MdOutlineShortText, MdOutlineUnfoldLessDouble, MdOutlineUnfoldMoreDouble, MdOutlineWrapText } from "react-icons/md";
import { socialNetworks } from "views/calendar/SocialNetworkSidePanel";
import { languageOptions } from "views/onboarding/StepCompanyLanguage";
import AskAIToWrite from "./functions/AskAIToWrite";
import { CompanyContext } from "contexts/CompanyContext";
import ContinueWriting from "./functions/ContinueWriting";
import BrainstormIdeas from "./functions/BrainstormIdeas";
import MakeShorter from "./functions/MakeShorter";
import MakeLonger from "./functions/MakeLonger";
import TranslateTo from "./functions/TranslateTo";
import ImproveWriting from "./functions/ImproveWriting";
import FixSpellingOrGrammar from "./functions/FixSpellingOrGrammar";
import ImproveConciseness from "./functions/ImproveConcisenss";
import RemoveIrrelevantInformation from "./functions/RemoveIrrelevantInformation";
import AdaptToSocialNetwork from "./functions/AdaptToSocialNetwork";
import ChangeTone from "./functions/ChangeTone";
import ChangeFormat from "./functions/ChangeFormat";
import { FiUser, FiUsers } from "react-icons/fi";
import ChangeVoice from "./functions/ChangeVoice";
import ChangeAudience from "./functions/ChangeAudience";
import ChangeSpeaker from "./functions/ChangeSpeaker";
import AddEmojis from "./functions/AddEmojis";
import AddLink from "./functions/AddLink";
import SuggestHashtags from "./functions/SuggestHashtags";
import SuggestEmojis from "./functions/SuggestEmojis";
import AddEngagingQuestion from "./functions/AddEngagingQuestion";
import AddExample from "./functions/AddExample";
import AddQuote from "./functions/AddQuote";
import { capitalize } from "lodash";
import MySocialIcon from "components/shared/MySocialIcon";
import { getSocialNetworkIntegration } from "utils/getSocialNetworkIntegration";
import getSocialNetworkProfileLink from "utils/getSocialNetworkProfileLink";
import AddCallToAction from "./functions/AddCallToAction";
import { Trans } from "react-i18next";
import { communicationStyleOptions } from "views/onboarding/StepCompanyCommunicationStyle";
import SuggesstAlternative from "./functions/SuggestAlternative";


export const getWindowScreenSize = () => {
    var win = window,
    doc = document,
    docElem = doc.documentElement,
    body = doc.getElementsByTagName('body')[0],
    x = win.innerWidth || docElem.clientWidth || body.clientWidth,
    y = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
    return({x, y});
}

const Divider = ({ item, setClickedItem = undefined }) => {
    return (
        <div onClick={(e) => { e.preventDefault(); return false }} className="px-5 py-2 !text-[11px] pt-5 text-gray-400 font-bold dark:text-gray-400 uppercase transition-all flex items-center gap-3 ">
            {setClickedItem && <HiChevronLeft className="text-primary-600 block !-ml-2 cursor-pointer" onClick={() => setClickedItem(undefined)} />}
            <span>{item?.title}</span>
        </div>
    )
}

const Item = ({ item, fullText, setClickedItem, submenuPlacement }) => {

    const [ showSubmenu, setShowSubmenu ] = useState(false);
    const disabled = item?.isModifier && !fullText?.trim();

    if (!item) return <></>

    return (
        item?.type == "divider" ? 
            <Divider item={item} />
            : 
            <div 
                onClick={(e) => {
                    e.preventDefault();
                    if (!disabled && item?.clickComponent) {
                        setClickedItem(item);
                    }
                    return false;
                }}
                onMouseOver={() => setShowSubmenu(true)}
                onMouseOut={() => setShowSubmenu(false)}
                className={`${disabled && `cursor-not-allowed`} relative px-5 py-2 !text-xs text-gray-800 dark:text-white cursor-pointer  hover:bg-primary-600/10 transition-all flex items-center gap-2.5`}
            >
                <div className={`text-primary-600 text-base ${disabled && `opacity-50`}`}>{item?.icon}</div>
                <span className={`${disabled && `opacity-50`}`}>{item?.title}</span>
                {item?.items && item?.items?.length > 0 && <HiChevronRight className={`absolute right-4 top-1/2 translate-y-[-50%] text-gray-600 dark:text-gray-300 ${disabled && `opacity-50`}`} />}
            
                {item?.items && item?.items?.length > 0 && 
                    <div className={`${!showSubmenu && `hidden`} absolute z-[10] -translate-y-[41%] ${submenuPlacement == "left" ? `left-0 translate-x-[-80%]` : `right-0 translate-x-[80%]`} max-h-[300px] border border-gray-100 dark:border-gray-600 bg-white dark:bg-gray-700 rounded-xl shadow-xl min-w-[200px] ${item?.items?.filter(e => e?.items && e?.items?.length)?.length > 0 ? `` : `overflow-y-auto`}`}>
                        {item?.items.map((item, key) => (<>
                            <Item 
                                key={key} 
                                item={item} 
                                fullText={fullText} 
                                setClickedItem={setClickedItem} 
                                submenuPlacement={submenuPlacement}
                            />
                        </>))}
                    </div>
                }
            </div>
    );   
}

const ContextMenu = React.forwardRef((props, ref)  => {

    const {
        menuOpened,
        setMenuOpened,
        menuPosition, 
        fullText,
        selectedText,
        selectionStartEnd,
        inputRef,
        setValue,
        network,
        value
    } = props;
    
    const [ clickedItem, setClickedItem ] = useState();
    const { company, companySocials } = useContext(CompanyContext);

    const screenSize = getWindowScreenSize();
    const x = menuPosition.x;
    const y = menuPosition.y;
    const xTranslate = x > screenSize.x / 2 ? "-100%" : "0%";
    const yTranslate = y > screenSize.y / 5 * 4 ? "-110%" : y > screenSize.y / 3 * 2 ? "-80%" : y < screenSize.y / 3 ? "-20%" : "-50%";
    const submenuPlacement = x > screenSize.x / 2 ? "left" : "right";
    let style = { left: x, top: y, transform: `translate(${xTranslate}, ${yTranslate})` };

    const contextMenuConfig = [
        {
            title: <Trans i18nKey={`contextMenu.writeWithAI`}>Write with AI</Trans>,
            type: "divider"
        },
        {
            title: <Trans i18nKey={`contextMenu.useAIToWrite`}>Use AI to write about...</Trans>,
            icon: <HiOutlineCpuChip />,
            clickComponent: <AskAIToWrite inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />
        },
        {
            title: <Trans i18nKey={`contextMenu.continueWriting`}>Continue writing</Trans>,
            icon: <HiOutlinePencil />,
            isModifier: true,
            clickComponent: <ContinueWriting inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />
        },
        {
            title: <Trans i18nKey={`contextMenu.brainstormIdeas`}>Brainstorm ideas...</Trans>,
            icon: <HiOutlineLightBulb />,
            isModifier: true,
            clickComponent: <BrainstormIdeas inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />,
        },   
        {
            title: <Trans i18nKey={`contextMenu.suggestAlternative`}>Suggest alternative</Trans>,
            icon: <HiMiniArrowPath />,
            isModifier: true,
            clickComponent: <SuggesstAlternative inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />,
        },   
        {
            title: <><Trans i18nKey={`contextMenu.modify`}>Modify</Trans> {selectedText ? `Selected` : `Full`} <Trans i18nKey={`contextMenu.text`}>Text</Trans></>,
            type: "divider",
        },
        {
            title: <Trans i18nKey={`contextMenu.textLength`}>Text Length</Trans>,
            icon: <MdOutlineShortText />,
            isModifier: true,
            items: [
                { title: <Trans i18nKey={`contextMenu.makeShorter`}>Make shorter</Trans>, icon: <MdOutlineUnfoldLessDouble />, isModifier: true, clickComponent: <MakeShorter inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                { title: <Trans i18nKey={`contextMenu.makeLonger`}>Make longer</Trans>, icon: <MdOutlineUnfoldMoreDouble />, isModifier: true, clickComponent: <MakeLonger inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
            ]
        },
        {
            title: <Trans i18nKey={`contextMenu.translateTo`}>Translate to</Trans>,
            icon: <HiOutlineTranslate />,
            isModifier: true,
            items: languageOptions.map((item) => { return {
                title: item?.value,
                icon: <img src={item?.image} className="h-4 w-4 rounded-full object-cover" />, 
                isModifier: true,
                clickComponent: <TranslateTo inputRef={inputRef} langTo={item?.value} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />
            }})
        },   
        {
            title: <Trans i18nKey={`contextMenu.enhanceText`}>Enhance text</Trans>,
            icon: <MdOutlineAutoFixHigh />,
            isModifier: true,
            items: [   
                { title: <Trans i18nKey={`contextMenu.improveWriting`}>Improve writing</Trans>, icon: <MdOutlineAutoFixHigh />, isModifier: true, clickComponent: <ImproveWriting inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                { title: <Trans i18nKey={`contextMenu.fixSpelling`}>Fix spelling or grammar</Trans>, icon: <HiOutlineWrenchScrewdriver />, isModifier: true, clickComponent: <FixSpellingOrGrammar inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                { title: <Trans i18nKey={`contextMenu.improveClarity`}>Improve clarity</Trans>, icon: <IoColorWandOutline />, isModifier: true, clickComponent: <ImproveWriting inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                { title: <Trans i18nKey={`contextMenu.improveConciseness`}>Improve conciseness</Trans>, icon: <MdOutlineShortText />, isModifier: true, clickComponent: <ImproveConciseness inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                { title: <Trans i18nKey={`contextMenu.removeIrrelevantInformation`}>Remove irrelevant information</Trans>, icon: <AiOutlineClear />, isModifier: true, clickComponent: <RemoveIrrelevantInformation inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
            ]
        },   
        {
            title: <Trans i18nKey={`contextMenu.speakingFormat`}>Speaking Format</Trans>,
            icon: <AiOutlineFormatPainter />,
            isModifier: true,
            items: [
                {
                    title: <Trans i18nKey={`contextMenu.changeTone`}>Change Tone</Trans>,
                    icon: <MdOutlineMood />,
                    isModifier: true,
                    items: communicationStyleOptions.map((item) => {
                        return {
                            title: item.name, icon: <span style={{ display: "block", height: "15px", marginTop: "-0.5rem" }}>{item.emoji}</span>, isModifier: true, clickComponent: <ChangeTone inputRef={inputRef} tone={item.value} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />
                        }
                    }) 
                },   
                {
                    title: <Trans i18nKey={`contextMenu.changeFormat`}>Change format</Trans>,
                    icon: <AiOutlineFormatPainter />,
                    isModifier: true,
                    items: [
                        { title: <Trans i18nKey={`contextMenu.poem`}>Poem</Trans>, icon: <MdOutlineFormatAlignCenter />, isModifier: true, clickComponent: <ChangeFormat inputRef={inputRef} format={"Poem"} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                        { title: <Trans i18nKey={`contextMenu.pressRelease`}>Press release</Trans>, icon: <HiOutlineSpeakerphone />, isModifier: true, clickComponent: <ChangeFormat inputRef={inputRef} format={"Press release"} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                        { title: <Trans i18nKey={`contextMenu.blogPost`}>Blog post</Trans>, icon: <MdOutlineArticle />, isModifier: true, clickComponent: <ChangeFormat inputRef={inputRef} format={"Blog post"} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                        { title: <Trans i18nKey={`contextMenu.creativeStory`}>Creative story</Trans>, icon: <MdOutlineHistoryEdu />, isModifier: true, clickComponent: <ChangeFormat inputRef={inputRef} format={"Creative story"} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                        { title: <Trans i18nKey={`contextMenu.salesEmail`}>Sales e-mail</Trans>, icon: <HiOutlineMail />, isModifier: true, clickComponent: <ChangeFormat inputRef={inputRef} format={"Sales e-mail"} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                    ]
                },   
                {
                    title: <Trans i18nKey={`contextMenu.changeVoice`}>Change voice</Trans>,
                    icon: <AiOutlineUserSwitch />,
                    isModifier: true,
                    items: [
                        { title: <Trans i18nKey={`contextMenu.firstPerson`}>First person</Trans>, icon: <FiUser />, isModifier: true, clickComponent: <ChangeVoice inputRef={inputRef} voice={"First person"} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                        { title: <Trans i18nKey={`contextMenu.secondPerson`}>Second person</Trans>, icon: <FiUsers />, isModifier: true, clickComponent: <ChangeVoice inputRef={inputRef} voice={"Second person"} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                        { title: <Trans i18nKey={`contextMenu.thirdPerson`}>Third person</Trans>, icon: <FiUsers />, isModifier: true, clickComponent: <ChangeVoice inputRef={inputRef} voice={"Third person"} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                    ]
                },
                {
                    title: <Trans i18nKey={`contextMenu.changeAudience`}>Change audience</Trans>,
                    icon: <IoPeopleCircleOutline />, 
                    isModifier: true,
                    items: [
                        ...(company?.audience_types && company?.audience_types?.length > 0 ? company?.audience_types?.map((item) => {
                            return { title: item, isModifier: true, clickComponent: <ChangeAudience inputRef={inputRef} audience={item} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> }
                        }) : [undefined]),
                        { title: <Trans i18nKey={`contextMenu.customAudience`}>Custom audience...</Trans>, icon: <HiOutlineUser />, isModifier: true, clickComponent: <ChangeAudience inputRef={inputRef} audience={""} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                    ]
                },   
                {
                    title: <Trans i18nKey={`contextMenu.changeSpeaker`}>Change speaker...</Trans>,
                    icon: <HiOutlineUserCircle />, 
                    isModifier: true,
                    clickComponent: <ChangeSpeaker inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />
                },   
            ]
        },
        ...!network ? [{
            title: <Trans i18nKey={`contextMenu.formatToSocialNetwork`}>Format to social network</Trans>,
            icon: <MdOutlineWrapText />,
            isModifier: true,
            items: [
                { title: "Instagram", icon: <img src={`/img/socials/instagram.svg`} className="h-4 w-4" />, isModifier: true, clickComponent: <AdaptToSocialNetwork network={"instagram"} textToAdapt={fullText} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                { title: "Facebook", icon: <img src={`/img/socials/facebook.svg`} className="h-4 w-4" />, isModifier: true, clickComponent: <AdaptToSocialNetwork network={"facebook"} textToAdapt={fullText} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                { title: "Twitter", icon: <img src={`/img/socials/twitter.svg`} className="h-4 w-4" />, isModifier: true, clickComponent: <AdaptToSocialNetwork network={"twitter"} textToAdapt={fullText} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                { title: "LinkedIn", icon: <img src={`/img/socials/linkedIn.svg`} className="h-4 w-4" />, isModifier: true, clickComponent: <AdaptToSocialNetwork network={"linkedIn"} textToAdapt={fullText} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                { title: "TikTok", icon: <img src={`/img/socials/tikTok.svg`} className="h-4 w-4" />, isModifier: true, clickComponent: <AdaptToSocialNetwork network={"tikTok"} textToAdapt={fullText} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                { title: "Discord", icon: <img src={`/img/socials/discord.svg`} className="h-4 w-4" />, isModifier: true, clickComponent: <AdaptToSocialNetwork network={"discord"} textToAdapt={fullText} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                { title: "Medium", icon: <img src={`/img/socials/medium.svg`} className="h-4 w-4" />, isModifier: true, clickComponent: <AdaptToSocialNetwork network={"medium"} textToAdapt={fullText} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                { title: "Pinterest", icon: <img src={`/img/socials/pinterest.svg`} className="h-4 w-4" />, isModifier: true, clickComponent: <AdaptToSocialNetwork network={"pinterest"} textToAdapt={fullText} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                { title: "Youtube", icon: <img src={`/img/socials/youtube.svg`} className="h-4 w-4" />, isModifier: true, clickComponent: <AdaptToSocialNetwork network={"youtube"} textToAdapt={fullText} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                { title: "Telegram", icon: <img src={`/img/socials/telegram.svg`} className="h-4 w-4" />, isModifier: true, clickComponent: <AdaptToSocialNetwork network={"telegram"} textToAdapt={fullText} setValue={setValue} setMenuOpened={setMenuOpened} /> },
            ]
        }] : [], 
        ...network ? [{
            title: <><Trans i18nKey={`contextMenu.formatFor`}>Format for</Trans> {capitalize(network)}</>,
            icon: <img src={`/img/socials/${network}.svg`} className="w-4" />,
            isModifier: true,
            clickComponent: <AdaptToSocialNetwork network={network} textToAdapt={fullText} setValue={setValue} setMenuOpened={setMenuOpened} />,
        }] : [], 
        {
            title: <Trans i18nKey={`contextMenu.addText`}>Add Text</Trans>,
            type: "divider",
        },
        {
            title: <Trans i18nKey={`contextMenu.addEmojis`}>Add emojis...</Trans>,
            icon: <HiOutlineEmojiHappy />,
            clickComponent: <AddEmojis inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />
        },   
        // {
        //     title: "Add image...",
        //     icon: <HiOutlinePhotograph />
        // },   
        {
            title: <Trans i18nKey={`contextMenu.addLink`}>Add link</Trans>,
            icon: <AiOutlineLink />,
            items: [
                ...(company?.website ? [{
                    title: `Website: ${company?.website}`,
                    icon: <HiOutlineGlobe />,
                    clickComponent: <AddLink link={company?.website} inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />
                }] : []),
                ...companySocials.filter(e => getSocialNetworkIntegration(e.type, companySocials) && getSocialNetworkProfileLink(getSocialNetworkIntegration(e.type, companySocials))).map((e) => {
                    const link = getSocialNetworkProfileLink(getSocialNetworkIntegration(e.type, companySocials));
                    return {
                        title: `${capitalize(e.type)}`,
                        icon: <img src={`/img/socials/${e.type}.svg`} className="h-4 w-4" />,
                        clickComponent: <AddLink link={link} inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />
                    }
                }),
                { title: <Trans i18nKey={`contextMenu.customLink`}>Custom link...</Trans>, icon: <IoLinkOutline />, clickComponent: <AddLink inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
            ]
        },  
        {
            title: <Trans i18nKey={`contextMenu.aiElements`}>AI elements</Trans>,
            icon: <HiOutlineHashtag />,
            items: [
                { title: <Trans i18nKey={`contextMenu.suggestHashtags`}>Suggest hashtags</Trans>, icon: <HiOutlineHashtag />, clickComponent: <SuggestHashtags inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },   
                { title: <Trans i18nKey={`contextMenu.suggestEmojis`}>Suggest emojis</Trans>, icon: <HiOutlineEmojiHappy />, clickComponent: <SuggestEmojis inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />},   
                { 
                    title: <Trans i18nKey={`contextMenu.addCTA`}>Add call-to-action</Trans>,
                    icon: <HiOutlineCursorArrowRays />,
                    items: [
                        { title: <Trans i18nKey={`contextMenu.downloadNow`}>Download now</Trans>, icon: <span style={{ display: "block", height: "15px", marginTop: "-0.5rem" }}>📲</span>, clickComponent: <AddCallToAction callToAction="Download now" inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                        { title: <Trans i18nKey={`contextMenu.linkToWebsite`}>Link to website</Trans>, icon: <span style={{ display: "block", height: "15px", marginTop: "-0.5rem" }}>🌐</span>, clickComponent: <AddCallToAction callToAction="Link to website" inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                        { title: <Trans i18nKey={`contextMenu.likePost`}>Like post</Trans>, icon: <span style={{ display: "block", height: "15px", marginTop: "-0.5rem" }}>❤️</span>, clickComponent: <AddCallToAction callToAction="Like post" inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                        { title: <Trans i18nKey={`contextMenu.commentPost`}>Comment post</Trans>, icon: <span style={{ display: "block", height: "15px", marginTop: "-0.5rem" }}>💬</span>, clickComponent: <AddCallToAction callToAction="Comment post" inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                        { title: <Trans i18nKey={`contextMenu.tagFriend`}>Tag a friend</Trans>, icon: <span style={{ display: "block", height: "15px", marginTop: "-0.5rem" }}>🏷</span>, clickComponent: <AddCallToAction callToAction="Tag a friend" inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} /> },
                    ]
                },   
                {
                    title: <Trans i18nKey={`contextMenu.addEngagingQuestion`}>Add engaging question</Trans>,
                    icon: <HiOutlineQuestionMarkCircle />,
                    clickComponent: <AddEngagingQuestion inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />
                },   
                {
                    title: <Trans i18nKey={`contextMenu.addExamples`}>Add examples</Trans>,
                    icon: <IoListCircleOutline />,
                    clickComponent: <AddExample inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />
                },   
                {
                    title: <Trans i18nKey={`contextMenu.addQuote`}>Add quote</Trans>,
                    icon: <MdOutlineFormatQuote />,
                    clickComponent: <AddQuote inputRef={inputRef} value={value} fullText={fullText} selectedText={selectedText} selectionStartEnd={selectionStartEnd} setValue={setValue} setMenuOpened={setMenuOpened} />
                },   
            ]
        },   
    ];

    useEffect(() => {
        setClickedItem(undefined);
    }, [menuOpened]);

    if (!menuOpened) return <></>;

    return (
        <div ref={ref} onClick={(e) => { return false; }} className="fixed !z-[1000000000]" style={style}>
            <div className="overflow-visible border border-gray-100 dark:border-gray-600 bg-white dark:bg-gray-700 rounded-xl shadow-xl min-w-[250px]">
                {clickedItem 
                    ? <>
                        <Divider item={clickedItem} setClickedItem={setClickedItem} />
                        <div className="relative px-3 py-3 !text-xs text-gray-800 dark:text-gray-100">
                            {clickedItem?.clickComponent}
                        </div> 
                    </> 
                    : <>
                        {contextMenuConfig.map((item, key) => (
                            <Item 
                                key={key} 
                                item={item} 
                                fullText={fullText} 
                                setClickedItem={setClickedItem}    
                                submenuPlacement={submenuPlacement}
                            />
                        ))}
                    </>
                }
            </div>
        </div>
    )
})

export default ContextMenu;
import React, { useEffect } from 'react'

export default function useLazyEffect(func, dependencies, miliseconds = 500) {
    useEffect(() => {
        const timeout = setTimeout(() => {
            func?.();
        }, miliseconds)
        return () => clearTimeout(timeout);
    }, dependencies);
}

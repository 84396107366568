import MaintenanceScreen from "components/layout/MaintenanceScreen";
import React from "react";

const MaintenanceContext = React.createContext({});

const MaintenanceProvider = ({ children }) => {

    const maintenanceModeOn = !!process.env.REACT_APP_MAINTENANCE_MODE;

    return (
        <MaintenanceContext.Provider value={{}}>
            {maintenanceModeOn
                ? <MaintenanceScreen />
                : children
            }
        </MaintenanceContext.Provider>
    )
}

export { MaintenanceContext, MaintenanceProvider };
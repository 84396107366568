export default function getFileType(src = '') {
    src = src?.split("?")?.[0];
    let ext = String(src).slice((String(src).lastIndexOf(".") - 1 >>> 0) + 2);
    ext = ext.split("?");
    ext = ext[0];
    ext = ext.split("&");
    ext = ext[0];

    if (ext.match(/(jpg|jpeg|png)$/i)) {
        return 'image';
    }
    
    if (ext.match(/(gif)$/i)) {
        return 'gif';
    }
    
    if (ext.match(/(mp4|mpg|mov|wmv|rm|3g2|3gp)$/i)) {
        return 'video';
    }

    return 'image'
}

export function getFileTypeImageVideo(src = '') {

    if (!src) src = "";
    
    if (!src) src = "";
    
    src = src.toLowerCase();

    if (src.includes(".jpg") || src.includes(".jpeg") || src.includes(".png") || src.includes(".gif")) {
        return 'image';
    }
    
    if (src.includes(".mp4") || src.includes(".mpg") || src.includes(".mov") || src.includes(".wmv") || src.includes(".rm") || src.includes(".3g2") || src.includes(".3gp")) {
        return 'video';
    }

    return 'image'
}

// export function getFileTypeImageVideo(src = '') {
//     src = src?.split("?")?.[0];
//     let ext = String(src).slice((String(src).lastIndexOf(".") - 1 >>> 0) + 2);
//     ext = ext.split("?");
//     ext = ext[0];
//     ext = ext.split("&");
//     ext = ext[0];

//     if (ext.match(/(jpg|jpeg|png)$/i)) {
//         return 'image';
//     }
    
//     if (ext.match(/(gif)$/i)) {
//         return 'image';
//     }
    
//     if (ext.match(/(mp4|mpg|mov|wmv|rm|3g2|3gp)$/i)) {
//         return 'video';
//     }

//     return 'image'
// }

import { CompanyContext } from "contexts/CompanyContext";
import { useContext } from "react";
import Api from "services/Api";
import { formatSocialPostObj } from "../../formatSocialPostObj";
import { Notification, toast } from "components/ui";
import { getSocialNetworkIntegration } from "utils/getSocialNetworkIntegration";

export const useInstagram = () => {

    const { company, companySocials } = useContext(CompanyContext);
    const _start_date = new Date(new Date().getTime() - 90 * 24 * 3600 * 1000);
    const _end_date = new Date();

    const network = "instagram";

    const clearExpiredCache = (socialNetwork) => {
        const currentTime = Date.now();
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith(`${socialNetwork.id}_posts_`)) {
                const cachedData = JSON.parse(localStorage.getItem(key));
                if (currentTime > cachedData.timestamp + 1000 * 60 * 2) {
                    localStorage.removeItem(key);
                }
            }
        });
    };
    
    const getInstagramPostsInsights = async (
        per_page = 100, // 100
        start_date = _start_date,
        end_date = _end_date
    ) => {
        const socialNetwork = getSocialNetworkIntegration("instagram", companySocials);
        if (!socialNetwork) return [];

        return await getInstagramPosts(per_page, start_date, end_date, false);
    }
    
    const getInstagramPosts = async (
        per_page = 100,
        start_date = _start_date,
        end_date = _end_date,
        get_comments = true 
    ) => {
        const socialNetwork = getSocialNetworkIntegration("instagram", companySocials);
        if (!socialNetwork) return [];

        const formatDate = (date) => {
            return new Date(date).toISOString().split('T')[0];
        };

        start_date = new Date(start_date).toISOString();
        end_date = new Date(end_date).toISOString();
        
        const cacheKey = `${socialNetwork.id}_posts_${formatDate(start_date)}_${formatDate(end_date)}`;

        // Clear expired cache entries
        clearExpiredCache(socialNetwork);

        // Check cache
        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
            const { posts, timestamp } = JSON.parse(cachedData);
            if (Date.now() <= timestamp + 1000 * 60 * 2) {
                return posts;
            }
        }

        // Fetch posts if not in cache or cache expired
        const fetchedPosts = await Api.get(`/socialNetworks/${socialNetwork.id}/posts?${start_date ? `&since=${Math.ceil(new Date(start_date).getTime() / 1000)}` : ``}${end_date ? `&until=${Math.ceil(new Date(end_date).getTime() / 1000)}` : ``}${per_page ? `&limit=${per_page}` : ``}&fields=media_url,like_count,comments_count,media_type,id,caption,ig_id,permalink,thumbnail_url,timestamp,username,media_product_type,insights.metric(impressions,comments,likes,shares){values,name},children{media_url,media_type,thumbnail_url}`).then(({ data: posts }) => { 
            return posts?.map((post) => {
                const mainAsset = { 
                    type: post?.media_type?.toLowerCase(), 
                    url: post?.media_url, 
                    width: 1000, 
                    height: 1000, 
                    thumbnail_url: post?.thumbnail_url 
                };
                const childrenAssets = post?.children?.data?.map(child => ({
                    type: child?.media_type?.toLowerCase(),
                    url: child?.media_url,
                    thumbnail_url: child?.thumbnail_url,
                    width: 1000,
                    height: 1000
                })) || [];
                const allAssets = childrenAssets.length ? childrenAssets : [mainAsset];

                return formatSocialPostObj(
                    "instagram",
                    post?.id,
                    post?.ig_id,
                    socialNetwork?.name,
                    post?.username,
                    socialNetwork?.image?.url,
                    post?.timestamp,
                    post?.caption,
                    allAssets,
                    post?.insights?.data?.filter(e => e?.name == "likes")?.[0]?.values?.[0]?.value,
                    post?.insights?.data?.filter(e => e?.name == "comments")?.[0]?.values?.[0]?.value,
                    post?.insights?.data?.filter(e => e?.name == "impressions")?.[0]?.values?.[0]?.value,
                    post?.insights?.data?.filter(e => e?.name == "shares")?.[0]?.values?.[0]?.value,
                    "",
                    "",
                    "",
                    "FEED",
                    undefined,
                    undefined,
                    allAssets,
                    { media_product_type: "FEED" },
                    0,
                    0,
                    post?.comments_to_create
                )
            });
        });

        // Update cache
        localStorage.setItem(cacheKey, JSON.stringify({ posts: fetchedPosts, timestamp: Date.now() }));

        if (get_comments) {
            try {
                let comments = await Api.get(`/comments?countUnreadByPost=true`).then(({ data: comments }) => comments);
                return fetchedPosts.map((post) => ({
                    ...post,
                    unreadComments_count: comments.find(e => e?.external_post_id == post?.id)?.comments_count
                }));
            } catch (err) {
                console.error("Error fetching comments:", err);
            }
        }

        return fetchedPosts;
    }
    
    const replyToInstagramComment = async (
        comment,
        message,
    ) => {
        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        await Api.post(`/comments`, {}, {
            external_comment_id: comment?.external_id,
            message: message
        });
    }
    
    const deleteInstagramMessage = async (message_id) => {
        
        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        try {
            const response = await Api.delete(`/messages/${message_id}`);
        } catch (e) {
            let message = e?.message;
            if (message) {
                message = message.split(`"error":{"message":"`);
                if (message?.length > 1) {
                    message = message[1];
                    message = message.split(`","type"`)?.[0];
                } else {
                    message = null;
                }
            }
            toast.push(<Notification type="danger" title={message || e?.message || `An error ocurred while deleteing the message`} />);
            return false;
        }
        
        return true;
    }

    const getInstagramComments = async (
        id
    ) => {

        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        const comments = await Api.get(`/comments?sort=-id&filter[parent_id]=null&filter[external_post_id]=${id}&include=externalUser.image,children.externalUser.image`).then(({ data }) => data);

        return comments;
    }

    const sendInstagramMessage = async (conversation, message) => {

        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        const _message = await Api.post(`/messages`, {}, {
            conversation_id: conversation.id,
            message: message
        }).then(({ data: message }) => {
            return message;
        }).catch((err) => {
            toast.push(<Notification type="danger" title={err?.message || err} />);
        })

        return _message;
    }
    
    const getInstagramConversationMessages = async (
        conversation_id,
        per_page = 100, 
    ) => {

        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        const messages = await Api.get(`/messages?filter[conversation_id]=${conversation_id}&sort=-id&include=binaries`).then(
            ({ data: messages }) => messages);
        
        return messages;
    }

    const getInstagramConversations = async (filters) => {
        
        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        let filterArray = [];
        filterArray.push(`filter[social_network_id]=${socialNetwork.id}`);
        if (!!filters?.query) {
            filterArray.push(`filter[externalUser.name]=${filters?.query}`);
        }
        if (!!filters?.assignedTo) {
            filterArray.push(`filter[user_id]=${filters?.assignedTo}`);
        }
        if (!!filters?.unread) {
            filterArray.push(`filter[has_unreadMessages]=${1}`);
        }

        try {
            const conversations = await Api.get(`/conversations?${filterArray.join("&")}&sort=lastMessage&include=lastMessage,image,unreadMessagesCount,externalUser.image`).then(({ data: conversations }) => {
                conversations = conversations.map(c => ({...c, network: network}));
                return conversations;
            });
            return conversations;
        } catch (err) {}

        return [];
    }
    
    return { 
        getInstagramPosts, 
        getInstagramConversations, 
        sendInstagramMessage, 
        getInstagramConversationMessages, 
        getInstagramComments, 
        replyToInstagramComment, 
        getInstagramPostsInsights,
        deleteInstagramMessage 
    }
}


import { Tooltip } from 'components/ui';
import React, { useEffect, useRef, useState } from 'react'
import { BsCheckSquare, BsCheckSquareFill, BsPencil, BsStar, BsStarFill } from 'react-icons/bs';
import { LuCheck, LuCheckCircle, LuPencil, LuStar, LuTrash2 } from 'react-icons/lu';
import { TbCheck } from 'react-icons/tb';
import { useOnClickOutside } from 'usehooks-ts';
import useLazyEffect from 'utils/hooks/useLazyEffect';
import { newPromptStart } from './Prompts';
import { stripHTML, stripHTMLReplaceWithNewLines } from 'utils/stripHTML';

export default function PromptItem({
  promptObj,
  prompts,
  onChange,
  onSetDefault,
  onDelete,
  editable = true,
  selectable = false,
  selected = false,
  onSelect = () => void 0,
}) {

  const ref = useRef();
  const [ rand, setRand ] = useState(0);
  const [ isEditing, setIsEditing ] = useState(promptObj?.prompt == newPromptStart ? true : false);
  const [ isDefault, setIsDefault ] = useState(!!promptObj?.default);

  selected = !selectable ? false : selected;

  const handleOnChange = () => {
    if (ref && rand != 0) {
      onChange(stripHTML(stripHTMLReplaceWithNewLines(ref.current.innerHTML.replace(/<br\s*\\?>/g, "\n"))));
      setIsEditing(false);
      setRand(0);
    }
  }
  
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("input", function() {
        setRand(Math.random());
      }, false);
    }
  }, [ref]);
  
  useEffect(() => {
    ref.current && ref.current.focus()
  }, [isEditing]);

  return (
    <div 
      className={`overflow-hidden w-full text-sm rounded-xl border border-gray-100 dark:border-gray-700 ${selected && `border-2 border-primary-600 bg-primary-600/5`}`}
      onClick={(e) => {
        ref.current && ref.current.focus();
        selectable && !isEditing && onSelect();
      }}
    >
      <span 
        className={`!outline-0 whitespace-prewrap p-4 py-4 block text-gray-900 dark:text-gray-50 ${selectable && !isEditing ? `cursor-pointer` : `cursor-text`}`}
        contentEditable={isEditing}
        suppressContentEditableWarning
        ref={ref}
        dangerouslySetInnerHTML={{__html: promptObj?.prompt.replace(/(?:\r\n|\r|\n)/g, '<br>')}}
        onBlur={handleOnChange}
      />
      <div className={`bg-gray-50 dark:bg-gray-600 border-t border-gray-100 dark:border-gray-700 p-4 pr-6 py-2.5 flex justify-between items-center gap-4 ${selectable && !isEditing && `cursor-pointer`}`}>
        <span className='text-xs'>
          {!editable && <>
            <i>Followr default prompt</i>
          </>}
        </span>
        <div className='flex gap-4 text-lg'>
          {!!editable && 
            <Tooltip title={isEditing ? 'Save' : 'Edit'}>
              {!isEditing 
                ? <LuPencil 
                  onClick={(e) => { e.stopPropagation(); setIsEditing(true); }}
                  className='cursor-pointer transition-all hover:brightness-[1.1]'
                />
                : <LuCheckCircle 
                  onClick={(e) => { e.stopPropagation(); setIsEditing(false); }}
                  className='cursor-pointer transition-all hover:brightness-[1.1] text-green-600'
                />
              }
            </Tooltip>
          }
          {!!editable && !selectable && 
            <LuTrash2 
              onClick={(e) => { e.stopPropagation(); onDelete(); }}
              className='cursor-pointer transition-all hover:brightness-[1.1]'
            />
          }
          <Tooltip title='Set as default'>
            {isDefault
              ? <BsCheckSquareFill 
                onClick={(e) => { e.stopPropagation(); onSetDefault(); setIsDefault(!isDefault); }}
                className='cursor-pointer transition-all hover:brightness-[1.1] text-primary-600'
              />
              : <BsCheckSquare 
                onClick={(e) => { e.stopPropagation(); onSetDefault(); setIsDefault(!isDefault); }}
                className='cursor-pointer transition-all hover:brightness-[1.1]'
              />
            }
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

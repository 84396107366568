import React, { useState, useEffect, useContext } from 'react'
import { CompanyContext } from 'contexts/CompanyContext'
import Api from 'services/Api'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import FormDesription from './FormDesription'
import { Trans } from 'react-i18next'
import { Button, Checkbox, Notification, Tooltip, toast } from 'components/ui'
import { LuSave } from 'react-icons/lu'
import { SessionContext } from 'contexts/SessionContext'
import { Loading } from 'components/shared'
import { PriorityModalContext } from 'contexts/PriorityModalContext'
import AIImageDropdownModels from 'views/ai_image/components/AIImageDropdownModels'
import { AIContext, aiSizeOptions } from 'contexts/AIContext'
import AIImageDropdownSizes from 'views/ai_image/components/AIImageDropdownSizes'
import { PLACEMENT } from 'components/ui/utils/constant'

export const aiImageStyles = [
    { style: `No style`, description: `No style selected`, image: `/img/others/noimage.jpg` },
    { style: `Realism`, description: `Depicts subjects with accuracy and detail, emphasizing objective representation`, image: `https://cdn.followr.online/elephants/9a90de76-f7d2-4f24-98ed-11b6d991d3af.jpg` },
    { style: `Impressionism`, description: `Captures the feeling or atmosphere of a scene, often using loose brush strokes and vivid colors`, image: `https://cdn.followr.online/elephants/9a90deac-a6c3-4b93-afbf-fd4cc2cbab76.jpg` },
    { style: `Trompe l’oeil`, description: `A technique that uses realistic imagery to create optical illusions, making objects appear three-dimensional`, image: `https://cdn.followr.online/elephants/9a90da72-e80f-470c-a133-ff1fd9a92184.jpg` },
    { style: `Conceptual Art`, description: `Emphasizes the idea behind the artwork rather than the physical object`, image: `https://cdn.followr.online/elephants/9a90df35-fa29-4108-8c87-195ecb5ce438.jpg` },
    { style: `Minimalism`, description: `Simplistic, geometric forms with a focus on color and composition`, image: `https://cdn.followr.online/elephants/9a90df85-29cf-4fdd-9d9c-d09a92df1ab5.jpg` },
    { style: `Clipart`, description: `Digital pictures used for a variety of graphic design projects`, image: `https://cdn.followr.online/elephants/clipart.jpg` },
    { style: `Pencil Art`, description: `Images created using pencil strokes, conveying realism or creativity`, image: `https://cdn.followr.online/elephants/pencilart.jpg` },
    { style: `Hyperrealism`, description: `An art style that achieves an extremely high level of realism, often surpassing what is seen in photographs`, image: `https://cdn.followr.online/elephants/9a90dfba-224c-4980-b731-c568b1afd645.jpg` },
    { style: `Constructivism`, description: `Abstract, geometric art influenced by the Russian Revolution`, image: `https://cdn.followr.online/elephants/9a90dfdc-48ea-49dc-835e-8b51d7d20d31.jpg` },
    { style: `Cubism`, description: `Fragmented and abstracted subjects, exploring multiple perspectives`, image: `https://cdn.followr.online/elephants/9a90e0d6-bd15-4e8d-af8f-26b5d54a5237.jpg` },
    { style: `Dada`, description: `Challenges traditional notions of art, embracing absurdity and randomness`, image: `https://cdn.followr.online/elephants/9a90e0f9-dc94-44e7-b182-3b4617245247.jpg` },
    { style: `Futurism`, description: `Emphasizes speed, technology, and modernity`, image: `https://cdn.followr.online/elephants/9a90e11f-1d16-44cb-8e07-4136674d7aa8.jpg` },
    { style: `Surrealism`, description: `Combines dreamlike imagery with elements of reality`, image: `https://cdn.followr.online/elephants/9a90e1b2-ba3d-443a-96a5-2cc587cbdd15.jpg` },
    { style: `Pop Art`, description: `Features imagery from popular culture and mass media`, image: `https://cdn.followr.online/elephants/9a90e1db-99a5-4d17-b64d-7ac19569d33e.jpg` },
    { style: `Art Deco`, description: `Geometric and decorative style, popular in the 1920s and 1930s`, image: `https://cdn.followr.online/elephants/9a90e206-1bb0-4927-bdc1-d9a1e974d6ba.jpg` },
    { style: `Expressionism`, description: `Conveys emotional and psychological aspects of the subject through color, texture, and brushwork`, image: `https://cdn.followr.online/elephants/9a90e22c-1e86-490d-bf5c-0a15531316e7.jpg` },
    { style: `Art Nouveau`, description: `Flowing, organic forms often inspired by nature`, image: `https://cdn.followr.online/elephants/9a90e2f1-5853-4a9a-987c-251402ebc040.jpg` },
    { style: `Fauvism`, description: `Characterized by bold, non-naturalistic use of color`, image: `https://cdn.followr.online/elephants/9a90e30a-3758-4d09-9e31-1ed52e4f46a4.jpg` },
    { style: `Abstract`, description: `Non-representational art focusing on shapes, colors, and lines`, image: `https://cdn.followr.online/elephants/9a90e3ad-f295-4e94-9e79-b66c37905256.jpg` },
    { style: `3D Model`, description: `Digital representations of objects or scenes created with depth and realism using computer software.`, image: `https://cdn.followr.online/elephants/3dmodel.jpg` },
    { style: `Cinematic`, description: `Images styled to evoke the atmosphere of films, with dramatic lighting and composition.`, image: `https://cdn.followr.online/elephants/cinematic.jpg` },
    { style: `Isometric`, description: `Images depicting objects or scenes from a fixed perspective, showing three dimensions without distortion`, image: `https://cdn.followr.online/elephants/isometric.jpg` },
    { style: `Line Art`, description: `Images composed solely of lines, often simple and minimalist`, image: `https://cdn.followr.online/elephants/lineart.jpg` },
    { style: `Photographic`, description: `Images captured using a camera, replicating real-world scenes with detail and accuracy`, image: `https://cdn.followr.online/elephants/photographic.jpg` },
    { style: `Digital Painting`, description: `Painting created using digital tools`, image: `https://cdn.followr.online/elephants/9a90f54d-53bf-4773-ad53-e0f1f0e2e59a.jpg` },
    { style: `Digital Drawing`, description: `Drawing created using digital tools`, image: `https://cdn.followr.online/elephants/9a90f4f8-1aff-4c8a-a58f-74f8b8b0392d.jpg` },
    { style: `Digital Art`, description: `Art created using digital technology, including computer-generated imagery and 3D models`, image: `https://cdn.followr.online/elephants/9a90f5d5-682e-4f97-aef1-e0690de9bd5e.jpg` },
    { style: `Fantasy`, description: `Imaginative art style that often features magical creatures, supernatural elements, and mythical settings`, image: `https://cdn.followr.online/elephants/9a90f761-4fe7-49de-84f3-2a184b00e90c.jpg` },
    { style: `Pixel Art`, description: `Digital art created using individual pixels as the smallest visual unit, reminiscent of early video game graphics`, image: `https://cdn.followr.online/elephants/9a90f6f0-ee16-4acd-a826-fd5ecf28d528.jpg` },
    { style: `Cyberpunk`, description: `Futuristic art style that often features dystopian settings, advanced technology, and cybernetic enhancements`, image: `https://cdn.followr.online/elephants/9a90f80c-e793-4be4-9af3-9648f79dda63.jpg` },
    { style: `Vaporwave`, description: `A nostalgic art style that combines elements from the 80s and 90s with surreal, dreamlike compositions`, image: `https://cdn.followr.online/elephants/9a90f880-60d5-4e5e-be61-f8d6f77ab600.jpg` },
    { style: `Comic Book`, description: `Art style found in American and European comic books, characterized by bold lines and dynamic action`, image: `https://cdn.followr.online/elephants/9a90f8be-63dc-4fd6-b031-e336106e67e9.jpg` },
    { style: `Low Poly`, description: `3D art style that uses a limited number of polygons to create simple, geometric shapes`, image: `https://cdn.followr.online/elephants/9a90f978-8c4d-45cd-9c3d-e052940b9333.jpg` },
    { style: `Glitch Art`, description: `Art style that incorporates digital errors, distortions, and noise to create visually striking images`, image: `https://cdn.followr.online/elephants/9a90f9cb-99d3-40f1-9195-21f32f8c1e00.jpg` },
    { style: `Manga`, description: `Japanese comic book art style, similar in appearance to anime but often more detailed and intricate`, image: `https://cdn.followr.online/elephants/9a90fa05-54ae-4561-93ca-5f826625a1da.jpg` },
    { style: `Anime`, description: `A Japanese animation style characterized by vibrant colors, exaggerated facial expressions, and dynamic movement`, image: `https://cdn.followr.online/elephants/9a90fb03-7f96-44ff-bde4-ea5af289bc22.jpg` },
    { style: `Sci-Fi`, description: `Art style that focuses on futuristic settings, advanced technology, and extraterrestrial life`, image: `https://cdn.followr.online/elephants/9a90fb6b-3cfe-401c-9186-f380df7f08ac.jpg` },
    { style: `Nature Photography`, description: `Depicts wildlife, plants, and other natural elements`, image: `https://cdn.followr.online/elephants/9a91092d-a2ce-44d8-b7a3-32a32cebd701.jpg` },
    { style: `Street Photography`, description: `Candid images of people and events in public spaces`, image: `https://cdn.followr.online/elephants/9a910c97-a69b-41d4-b34f-1fdd67165d2b.jpg` },
    { style: `Holga`, description: `A photography style that uses the Holga camera, known for its light leaks, vignetting, and soft focus`, image: `https://cdn.followr.online/elephants/9a910e39-d931-41fd-ba65-0fb5aaf9b480.jpg` },
    { style: `Double Exposure Photography`, description: `A technique that combines two or more images into a single frame, often resulting in dreamlike or surreal compositions`, image: `https://cdn.followr.online/elephants/9a910ea7-f804-42ad-a480-6bf8251a864d.jpg` },
    { style: `Performance Photography`, description: `Captures live art performances and events`, image: `https://cdn.followr.online/elephants/9a911254-368a-4824-b90d-89b673043188.jpg` },
    { style: `Underwater Photography`, description: `Features underwater environments and marine life`, image: `https://cdn.followr.online/elephants/9a91129e-bd25-46f9-9979-6588fab81010.jpg` },
    { style: `Time-Lapse Photography`, description: `A sequence of images taken over a period of time, often used to show change`, image: `https://cdn.followr.online/elephants/9a9112e3-f2f1-4045-9ac0-63238351d5dc.jpg` },
    { style: `Documentary Photography`, description: `Captures real-life events, people`, image: `https://cdn.followr.online/elephants/9a911379-5771-46a5-8513-3f25ad0610cb.jpg` },
    { style: `Photograms`, description: `Cameraless photographic images created by placing objects directly on photosensitive paper`, image: `https://cdn.followr.online/elephants/9a911548-bf89-4910-b08e-c9c929c4c0af.jpg` },
    { style: `New Objectivity`, description: `An art movement that focused on unsentimental realism and social critique in painting and photography`, image: `https://cdn.followr.online/elephants/9a91158d-fc6c-4311-a0bb-8c39145581a6.jpg` },
    { style: `Installation Photography`, description: `Documents large-scale, immersive art installations`, image: `https://cdn.followr.online/elephants/9a9115f4-c8c1-45b1-9569-e05d35664e8a.jpg` },
    { style: `Film Noir`, description: `A style of photography influenced by the dark and moody visual style of classic film noir movies`, image: `https://cdn.followr.online/elephants/9a9116b1-8b20-4813-8bfa-4d2217c6599c.jpg` },
    { style: `Aerial Photography`, description: `Captures landscapes and subjects from an elevated perspective, often using drones`, image: `https://cdn.followr.online/elephants/9a911737-fc5a-41d0-bb30-85d716e93fbf.jpg` },
    { style: `Micro Photography`, description: `Extremely close-up images, often requiring specialized equipment like microscopes`, image: `https://cdn.followr.online/elephants/9a9118f0-864f-4735-b581-0c24bf44908e.jpg` },
    { style: `Macro Photography`, description: `Close-up images of small subjects, revealing intricate details`, image: `https://cdn.followr.online/elephants/9a911931-f1a9-4aa7-a200-e2c03e2c4b22.jpg` },
    { style: `Portrait Photography`, description: `Focuses on capturing the likeness and personality of a person or group of people`, image: `https://cdn.followr.online/elephants/9a911b20-2db6-4c09-9ead-1a73558423ca.jpg` },
    { style: `Landscape Photography`, description: `Captures natural and man-made environments`, image: `https://cdn.followr.online/elephants/9a911ba0-f7e5-43c1-ac19-dec53040551b.jpg` },
    { style: `Still Life Photography`, description: `Features arranged inanimate objects as the subject`, image: `https://cdn.followr.online/elephants/9a911c22-57ff-410f-a02f-936a89ded0f1.jpg` },
    { style: `Seascape Photography`, description: `Features bodies of water, coastlines, and marine life`, image: `https://cdn.followr.online/elephants/9a911d98-e048-4661-914a-e25d52eeae8e.jpg` },
    { style: `Astrophotography`, description: `Captures celestial objects and astronomical events`, image: `https://cdn.followr.online/elephants/9a928976-58c6-4794-a13a-1e6c0f440696.jpg` },
    { style: `Animated Photography`, description: `Combines photography with animation techniques`, image: `https://cdn.followr.online/elephants/9a92899d-5b92-44fd-bd0f-7386a90cf511.jpg` },
    { style: `Banksy Photography`, description: `Focuses on the work of the famous street artist Banksy`, image: `https://cdn.followr.online/elephants/9a9289d0-35e5-46a4-b59d-1dd505b11c62.jpg` },
    { style: `Futurist Photography`, description: `A photography style that captures the dynamism, speed, and energy of the Futurist art movement`, image: `https://cdn.followr.online/elephants/9a928baa-4ff7-41bb-bd26-bbd8085d57c9.jpg` },
    { style: `Wildlife Photography`, description: `Captures animals in their natural habitats`, image: `https://cdn.followr.online/elephants/9a928a44-db76-4319-a772-356004006eff.jpg` },
    { style: `Cyanotypes`, description: `A photographic printing process that produces a cyan-blue print using sunlight`, image: `https://cdn.followr.online/elephants/9a928be4-4a47-44f1-9138-3a9665e7e78c.jpg` },
    { style: `Graffiti Photography`, description: `Features street art and graffiti`, image: `https://cdn.followr.online/elephants/9a91216a-c21f-44db-9ab7-1d0f22010de5.jpg` },
    { style: `Cityscape Photography`, description: `Depicts urban environments and architectural subjects`, image: `https://cdn.followr.online/elephants/9a928c18-a6ff-4731-a88a-ee31a7ef1a35.jpg` },
    { style: `Mixed Media Photography`, description: `Combines photography with other artistic mediums`, image: `https://cdn.followr.online/elephants/9a928c42-0f6d-4945-8b07-dc6b6fbeaa0f.jpg` },
    { style: `Stop-Motion Photography`, description: `A series of photographs combined to create the illusion of movement`, image: `https://cdn.followr.online/elephants/9a928f14-a817-4043-80ea-1519c65b3535.jpg` },
    { style: `Lomography`, description: `A photography style that embraces imperfections and spontaneity using low-fidelity cameras`, image: `https://cdn.followr.online/elephants/9a928f7f-d20f-4837-b2af-c28c78150c1d.jpg` },
    { style: `Pinhole Photography`, description: `A cameraless photography technique that uses a small hole in a light-tight container to project an image`, image: `https://cdn.followr.online/elephants/9a928fc5-55b8-41ee-a9f7-e55489419e71.jpg` },
];

const AIImages = ({ isModal = false }) => {

    const { getDriverModel, aiModels } = useContext(AIContext);
    const { closeModalPriority } = useContext(PriorityModalContext);
    const { company, setCompany } = useContext(CompanyContext);
    const { updateUserData } = useContext(SessionContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ selectedStyles, setSelectedStyles ] = useState([]);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const { model, size } = getDriverModel("image");

    const refresh = async () => {
        setIsLoading(true);
        setSelectedStyles(company?.ai_image_styles || []);
        setIsLoading(false);
    }

    const handleOnClick = (style) => {
        if (!!selectedStyles.find(e => e == style)) {
            setSelectedStyles(selectedStyles.filter(e => e != style));
        } else {
            setSelectedStyles([...selectedStyles, style]);
        }
    }

    const handleOnSave = async () => {
        setIsSubmitting(true);
        try {
            await Api.put(`/companies/${company?.id}`, {}, { ai_image_styles: selectedStyles.length ? selectedStyles : null })
            updateUserData();
            isModal && closeModalPriority()
            toast.push(<Notification title="AI image styles saved successfully" type="success" />);
        } catch (err) {
            toast.push(<Notification title={err?.message || err} type="danger" />);
        }
        setIsSubmitting(false);
    }

    const updateCompanyImageModel = async (_model) => {
        const ai_preferences = {
            ...company?.ai_preferences,
            image_model: _model
        }
        setCompany({...company, ai_preferences: ai_preferences});
        await Api.put(`/companies/${company?.id}`, {}, { ai_preferences });
    }
    
    const updateCompanyImageSize = async (_size) => {
        const ai_preferences = {
            ...company?.ai_preferences,
            image_aspect_ratio: _size
        }
        setCompany({...company, ai_preferences: ai_preferences});
        await Api.put(`/companies/${company?.id}`, {}, { ai_preferences });
    }

    useEffect(() => {
        refresh();
    }, [company?.id]);

    useEffect(() => {
        if (!!model && !aiModels?.find(m => m.model == model)?.availableSizes?.includes(size)) {
            updateCompanyImageSize(aiSizeOptions?.filter(e => aiModels?.find(m => m.model == model)?.availableSizes?.includes(e.id))?.[0]?.id);
        }
    }, [model]);

    return (<>
        {isLoading && <Loading type='element' />}
        {!!aiImageStyles?.length && 
            <div className="w-full flex flex-col gap-8">
                <div className='flex flex-col gap-4'>
                    <FormDesription
                        className="flex-1"
                        title={<Trans i18nKey={`aiImageStyles.aiImages`}>AI Image Model</Trans>}
                        desc={<Trans i18nKey={`aiImageStyles.aiImagesDescription`}>Choose what AI image model will be used when generating posts with AI images.</Trans>}
                    />
                    <div className='flex flex-col lg:flex-row items-stretch gap-2'>
                        <AIImageDropdownModels 
                            model={model}
                            setModel={(_newModel) => updateCompanyImageModel(_newModel)}
                            variant="twoTone"
                            placement={PLACEMENT.BOTTOM_START}
                        />
                    </div>
                </div>

                <div className='flex flex-col gap-4'>
                    <FormDesription
                        className="flex-1"
                        title={<Trans i18nKey={`aiImageStyles.aiImages`}>AI Image Aspect Ratio</Trans>}
                        desc={<Trans i18nKey={`aiImageStyles.aiImagesDescription`}>Choose what AI image aspect ratio will be used when generating posts with AI images.</Trans>}
                    />
                    <div className='flex flex-col lg:flex-row items-stretch gap-2'>
                        <AIImageDropdownSizes 
                            model={model}
                            size={size}
                            className='bg-primary-50 dark:bg-primary-500 dark:bg-opacity-20 text-primary-600 dark:text-primary-50 hover:bg-primary-100 dark:hover:bg-primary-500 dark:hover:bg-opacity-30'
                            setSize={(_newSize) => updateCompanyImageSize(_newSize)}
                            placement={PLACEMENT.BOTTOM_START}
                        />
                    </div>
                </div>

                <div className='flex flex-col gap-4'>
                    <FormDesription
                        className="flex-1"
                        title={<Trans i18nKey={`aiImageStyles.aiImages`}>AI Image Styles</Trans>}
                        desc={<Trans i18nKey={`aiImageStyles.aiImagesDescription`}>Choose what AI image styles will be used when generating posts with AI images.</Trans>}
                    />
                    <div className='flex flex-col lg:flex-row items-center justify-between gap-4'>
                        <label 
                            className='flex items-center cursor-pointer' 
                            htmlFor='selectall'
                        >
                            <Checkbox
                                id="selectall"
                                className="mb-0 mr-2"
                                checked={selectedStyles.length >= aiImageStyles.length}
                                onChange={(value) => value 
                                    ? setSelectedStyles(aiImageStyles.map(e => e.style)) 
                                    : setSelectedStyles([])
                                }
                            />
                            <span className='text-sm'>Select all</span>
                        </label>
                        <div className='flex-1 flex gap-4 justify-end items-center'>
                            <span className='text-sm'>
                                Styles ({selectedStyles.length}/{aiImageStyles.length})
                            </span>
                            <Button 
                                variant="solid"
                                icon={<LuSave />}
                                onClick={() => handleOnSave()}
                                loading={isSubmitting}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                    <ResponsiveMasonry className="w-full" columnsCountBreakPoints={{ 350: 2, 475: 3, 750: 4, 900: 5, 1200: 6 }}>
                        <Masonry gutter='1rem'>
                            {aiImageStyles.map((item, key) => (
                                <div key={item.style} onClick={() => handleOnClick(item?.style)} className={`cursor-pointer flex flex-col gap-1 items-center justify-center`}>
                                    <div className={`group relative overflow-hidden rounded-2xl transition-all shadow-sm hover:shadow-2xl ${!!selectedStyles.find(e => e == item?.style) && `outline outline-4 outline-primary-600`}`}>
                                        <img 
                                            src={item.image}
                                            className="w-full transition-all"
                                        />
                                        <Checkbox
                                            className="mb-0 absolute left-4 top-4 pointer-events-none"
                                            checked={!!selectedStyles.find(e => e == item?.style)}
                                        />
                                    </div>
                                    <Tooltip title={item?.description}>
                                        <b className="font-bold text-gray-900 dark:text-gray-100 text-ellipsis whitespace-nowrap block overflow-hidden w-full text-center">{item?.style}</b>
                                    </Tooltip>
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
        }
    </>)
}

export default AIImages

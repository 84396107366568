import React, { useContext, useEffect, useState } from 'react'
import { Button, Dialog, Notification, toast } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { BsCheckCircleFill, BsCircle } from 'react-icons/bs';
import Api from 'services/Api';
import { SessionContext } from 'contexts/SessionContext';
import { CalendarActiveSocialNetworkContext } from 'contexts/CalendarActiveSocialNetworkContext';
import { Loading } from 'components/shared';
import { Trans } from 'react-i18next';

const LinkedInPageSelectModal = () => {

    const { companySocials, updateSocials } = useContext(CompanyContext);
    const { setLinkedInPageSelectModalOpened } = useContext(CalendarActiveSocialNetworkContext);
    const { updateUserData } = useContext(SessionContext);
    const [ pages, setPages ] = useState([]);
    const [ pageID, setPageID ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ isSubmitting, setIsSubmitting ] = useState(false);

    const linkedinSocialNetwork = companySocials?.filter(e => e.type == 'linkedin')?.[0];

    const handleOnRequestClose = () => {
        setIsLoading(true);
        Api.delete(`/socialNetworks/${linkedinSocialNetwork?.id}`).then(() => {
            updateUserData();
            updateSocials();
            setLinkedInPageSelectModalOpened(false);
        });
    }

    const handleSubmit = () => {
        setIsSubmitting(true);
        if (linkedinSocialNetwork) {
            Api.put(`/socialNetworks/${linkedinSocialNetwork?.id}`, {}, { page_id: pageID ? pageID?.includes(":") ? pageID?.split(":")?.pop() : pageID : null }).then(() => {
                setIsLoading(true);
                updateUserData();
                updateSocials();
                setLinkedInPageSelectModalOpened(false);
                toast.push(<Notification title="LinkedIn successfully connected!" type="success" />);
            }).catch((err) => {
                setIsLoading(false);
                setIsSubmitting(false);
                toast.push(<Notification title={err.message} type="danger" />);
            });
        }
    }

    useEffect(() => {
        if (linkedinSocialNetwork && (!pages || !pages?.length)) {
            setIsLoading(true);
            Api.get(`/socialNetworks/${linkedinSocialNetwork?.id}/pages?q=roleAssignee&role=ADMINISTRATOR&projection=(elements*(organizationalTarget~(id,localizedName,logoV2(original~:playableStreams))))`).then(({ data: pages }) => {
                setPages(pages);
            }).catch((err) => {
                handleOnRequestClose();
                toast.push(<Notification title="An error ocurred. Please, try again later." type="danger" />);
            }).then(() => {
                setIsLoading(false)
            });
        }
    }, [companySocials]);

    useEffect(() => {
        if (pageID && pages?.length == 1) {
            handleSubmit();
        }
    }, [pageID]);

    return (
        <Dialog
            isOpen={true}
            closable={true}
            onRequestClose={handleOnRequestClose}
            overlayClassName="flex w-full h-full items-center justify-center"
            width={650}
        >
            {isLoading
                ? <>
                    <div className='flex flex-col items-center justify-center min-h-[300px]'>
                        <Loading type={'element'} /> 
                    </div>
                </> : <>
                    <img src="/img/others/linkedin-banner-2.jpg" className='object-fit object-cover w-[120%] max-w-none h-[200px] -m-6 !mb-8' />
                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col gap-5'>
                            <h3 className='text-gray-900 mega-title'><Trans i18nKey={`linkedinIntegration.title`}>LinkedIn Integration</Trans></h3>
                            {/* <p className='text-gray-700 font-normal !text-sm'>
                                Select the LinkedIn page you'd like to connect to Followr. You can also connect your LinkedIn personal account.
                            </p> */}
                        </div>

                        <div className='flex flex-col gap-2'>
                            <h6><Trans i18nKey={`linkedinIntegration.personalAccount`}>Personal Account</Trans></h6>
                            <div onClick={() => setPageID("")} className={`cursor-pointer flex justify-between items-center gap-4 rounded-2xl border border-gray-200 dark:border-gray-600 border-solid py-2.5 px-4 transition-all hover:bg-primary-600/5 ${pageID === "" ? `!bg-primary-600/20 border-2 !border-primary-600 dark:!border-primary-600` : ``}`}>
                                <div className='flex items-center gap-4'>
                                    <div>
                                        <div className='w-10 h-10 bg-primary-600/30 border-4 border-primary-600/10 flex items-center justify-center rounded-full overflow-hidden'>
                                            <img src={linkedinSocialNetwork?.image?.url} className="w-full h-full" />
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-0 text-gray-800 dark:text-gray-100'>
                                        <div className='font-bold !text-sm'>{linkedinSocialNetwork?.name}</div>
                                    </div>
                                </div>
                                <div className='text-xl'>
                                    {pageID === ""
                                        ? <BsCheckCircleFill className='text-primary-600' />
                                        : <BsCircle className='text-gray-200 dark:text-gray-600' />
                                    }
                                </div>
                            </div>
                            {/* <p className='text-gray-800'><Trans i18nKey={`linkedinIntegration.dueToLimitations`}>Due to limitations on LinkedIn, connecting a <b>personal account</b> restricts access to users' posts and their associated insights. Consequently, you will only be able to schedule posts through the platform.</Trans></p> */}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <h6><Trans i18nKey={`linkedinIntegration.companyPages`}>Company Pages</Trans></h6>
                            <div className="max-h-[275px] overflow-y-auto flex flex-col gap-2">
                                {pages && pages.map((item, key) => (
                                    <div onClick={() => setPageID(item?.organizationalTarget)} key={key} className={`cursor-pointer flex justify-between items-center gap-4 rounded-2xl border border-gray-200 dark:border-gray-600 border-solid py-2.5 px-4 transition-all hover:bg-primary-600/5 ${pageID == item?.organizationalTarget ? `!bg-primary-600/20 border-2 !border-primary-600 dark:!border-primary-600` : ``}`}>
                                        <div className='flex items-center gap-4'>
                                            <div>
                                                <div className='w-10 h-10 bg-primary-600/30 border-4 border-primary-600/10 flex items-center justify-center rounded-full overflow-hidden'>
                                                    <img src={item?.['organizationalTarget~']?.logoV2?.['original~']?.elements?.[0]?.identifiers?.[0]?.identifier} className="w-full h-full" />
                                                </div>
                                            </div>
                                            <div className='flex flex-col gap-0 text-gray-800 dark:text-gray-100'>
                                                <div className='font-bold !text-sm'>{item?.['organizationalTarget~']?.localizedName}</div>
                                            </div>
                                        </div>
                                        <div className='text-xl'>
                                            {pageID == item?.organizationalTarget
                                                ? <BsCheckCircleFill className='text-primary-600' />
                                                : <BsCircle className='text-gray-200 dark:text-gray-600' />
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-end gap-2 p-4 -m-6 w-auto mt-10 border-t border-gray-200 dark:border-gray-700'>
                        <Button onClick={handleOnRequestClose}>Cancel</Button>
                        <Button loading={isSubmitting} disabled={pageID === undefined} variant="solid" onClick={handleSubmit} icon={<img src="/img/socials/linkedin.svg" className="!h-4 !w-4 shrink-0" />}>&nbsp; Connect LinkedIn Page</Button>
                    </div>
                </>
            }
        </Dialog>
    )
}

export default LinkedInPageSelectModal

import React, { useContext } from 'react'

import bannedImg from '../../assets/images/other/banned.png';
import followrLogoImg from '../../assets/images/logo-text.png';
import { SessionContext } from 'contexts/SessionContext';
import { useNavigate } from 'react-router-dom';

export default function BannedScreen() {

  const { actions } = useContext(SessionContext);

  const handleLogout = async () => {
    await actions.logout();
  }

  return (
    <div className='fixed left-0 top-0 w-full h-[100svh] flex flex-col items-center justify-center gap-2 text-center p-4'>
      <img src={bannedImg} className='w-[165px] mb-8 drop-shadow-lg' />
      <h1 className='text-lg lg:text-3xl font-bold text-gray-900 mb-1'>
        Your account has been suspended
      </h1>
      <p className='text-sm mb-1'>
        If you have any questions you can chat with our support center or send an email to <a className='text-primary-600 font-semibold' href='mailto:support@followr.help'>support@followr.help</a>.
      </p>
      <p className='text-sm font-semibold cursor-pointer text-gray-400 dark:text-gray-600' onClick={() => handleLogout()}>LOGOUT</p>
      <img src={followrLogoImg} className='w-[125px] mt-8' />
    </div>
  )
}

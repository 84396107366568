import { CompanyContext } from "contexts/CompanyContext";
import { AIContext } from "contexts/AIContext";
import { useContext } from "react";
import { aiImageStyles } from "views/company_settings/components/AIImages";

const useRandomContexts = () => {

    const { getByQueryChat } = useContext(AIContext);
    const { company } = useContext(CompanyContext);

    const generateRandomContexts = async (quantity = 8, isChargeable = true) => {

        const suggestionsString = await getByQueryChat(`
SEED: ${new Date().getTime()}
    
Write ${Math.ceil(quantity * 3)} different topics for a social media post for the given company.

EXAMPLES:
Example 1: Create a post about rainy days for social media managers including a CTA to the website
Example 2: Create a post about sunny days for influencers including a CTA to like and comment 

WRITING INSTRUCTIONS:
- Include important upcoming festive events in the last topics, considering today is ${new Date().toUTCString()}
- All topics must start with "Create a post about"
- Add communication tone at the end like "..., using a Y tone"
- No video creation
- Topics must be 20 words long or less

Topics: 
1. Create a post about X
2. Create a post about `, isChargeable, undefined, undefined, undefined, { temperature: 1, top_p: 1 });

        let suggestionsArray = suggestionsString.split("\n");
        suggestionsArray = suggestionsArray.map(suggestion => suggestion.split(".").slice(1)?.join(".")?.trim()).filter(suggestion => !!suggestion);
        suggestionsArray.shift();
        suggestionsArray.shift();
        suggestionsArray.pop();
        suggestionsArray.reverse();
        suggestionsArray.length = Math.min(quantity, suggestionsArray.length);

        return suggestionsArray;
    }

    const generateRandomAIImagePrompts = async (quantity = 4, isChargeable = true) => {

        const suggestionsString = await getByQueryChat(`
Write ${Math.ceil(quantity * 2)} photo advertisements ideas for ${company?.name}.

WRITING INSTRUCTIONS:
- Do not include the company name
- Do not use the COMPANY name
- Do not include the COMPANY URL
- Do not include the COMPANY logo
- No captions
- Be super creative and imaginative
- Do not explain the meaning of the photo

photo advertisements ideas: 
1. `, isChargeable, undefined, undefined, undefined);

        let suggestionsArray = suggestionsString.split("\n");
        suggestionsArray = suggestionsArray.map(suggestion => suggestion.split(".").slice(1)?.join(".")?.trim()).filter(suggestion => !!suggestion);
        suggestionsArray.shift();
        suggestionsArray.shift();
        suggestionsArray.pop();
        suggestionsArray.reverse();
        suggestionsArray.length = Math.min(quantity, suggestionsArray.length);

        return suggestionsArray;
    }

    return { generateRandomContexts, generateRandomAIImagePrompts }
}

export default useRandomContexts;
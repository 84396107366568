import Resizer from "react-image-file-resizer";

export const resizeImageFile = (file, maxW = 1920, maxH = 1920, q = 99) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
        file,
        maxW,
        maxH,
        "JPEG",
        q,
        0,
        (uri) => {
            resolve(uri);
        },
        "base64"
    );
});
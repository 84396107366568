"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterEventsHandlerBase = void 0;
class RegisterEventsHandlerBase {
    constructor(commands, window) {
        this.commands = commands;
        this.window = window;
    }
    addJsEventLogEntry(event) {
        const log = {
            tag: 'js_event',
            text: JSON.stringify(event),
            url: this.getURL(),
        };
        this.commands.addLogEntry(log);
    }
    getURL() {
        if (this.window) {
            return this.window.location.href;
        }
        return '';
    }
    supportsPassiveEvents() {
        let passiveSupported = false;
        try {
            const noop = () => { };
            const options = {
                get passive() {
                    passiveSupported = true;
                    return false;
                }
            };
            this.window.addEventListener('offline', noop, options);
            this.window.removeEventListener('offline', noop, false);
        }
        catch (err) {
            passiveSupported = false;
        }
        return passiveSupported;
    }
}
exports.RegisterEventsHandlerBase = RegisterEventsHandlerBase;

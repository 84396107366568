import { Badge, Card, Tooltip } from "components/ui";
import { SessionContext } from "contexts/SessionContext";
import { capitalize } from "lodash";
import React, { useContext } from "react";
import { Trans } from "react-i18next";
import { HiOutlineSupport } from "react-icons/hi";
import { HiOutlineAtSymbol, HiOutlineBriefcase, HiOutlineCheck, HiOutlineCpuChip, HiOutlineUser, HiOutlineXMark } from "react-icons/hi2";
import { TbDatabase, TbPhotoAi } from "react-icons/tb";
import { getGB } from "utils/bytesUtils";
import { formatNumber } from "utils/formatNumber";
import { socialNetworks } from "views/calendar/SocialNetworkSidePanel";

const ProductPlan = ({index = 0, item, selectedPlan, billingFrequency, setSelectedPlan}) => {

    const { user } = useContext(SessionContext);

    const discountText = process.env.REACT_APP_DEFAULT_DISCOUNT;
    const discountFactor = parseFloat(discountText) ? 1 - parseFloat(discountText) / 100 : 1;

    return (
        <Card onClick={() => setSelectedPlan(item.id)} bodyClass={`flex flex-col lg:flex-row items-center gap-4 !px-4 lg:!px-6 !py-4 relative`} className={`shrink-0 rounded-3xl overflow-hidden !px-0 hover:bg-gray-50 dark:hover:bg-gray-700 box-border cursor-pointer transition-all !border-0  ${selectedPlan == item.id && `group is-active bg-gradient-primary shadow-xl shadow-primary-600/50 dark:shadow-primary-600/10`}`}>
            <div className='relative z-10 w-[150px]'>
                {user?.subscription && user?.subscription?.items?.filter(e => e.product.id == item.id)?.length > 0 && <span className='bg-gradient-primary text-white group[.is-active]:bg-white group[.is-active]:text-gray-900 font-bold px-3 py-1 rounded-xl w-fit !text-xs inline-block !mb-2'>Actual Plan</span>}
                <h3 className='mega-title text-primary-600 text-center lg:text-left'>{item?.label}</h3>
                <p className='group-[.is-active]:text-gray-100 text-center lg:text-left'>{item?.description}</p>
            </div>
            <div className='w-[150px]'>
                <img src={`/img/others/super-plan-${index}.png`} className="w-full h-40 object-contain mx-auto z-10 relative" />
            </div>
            <div className='flex flex-col gap-2.5 px-6'>
                <div className='flex items-center gap-2'>
                    <HiOutlineUser className='text-primary-600 text-xl'/> 
                    <span className='group-[.is-active]:!text-gray-50'>{item?.users_amount} <Trans i18nKey={`subscription.productUser`}>User</Trans>{item?.users_amount > 1 && 's'}</span>
                </div>
                <div className='flex items-center gap-2'>
                    <HiOutlineBriefcase className='text-primary-600 text-xl'/> 
                    <span className='group-[.is-active]:!text-gray-50'>{item?.companies_amount} {item?.users_amount > 1 ? <Trans i18nKey={`subscription.companies`}>Companies</Trans> : <Trans i18nKey={`subscription.company`}>Company</Trans>}</span>
                </div>
                <div className='flex items-center gap-2'>
                    <HiOutlineCpuChip className='text-primary-600 text-xl'/> 
                    <span className='group-[.is-active]:!text-gray-50'>{formatNumber(item?.words_amount)} <Trans i18nKey={`subscription.aiGeneratedWords`}>AI-Generated Words</Trans></span>
                </div>
                <div className='flex items-center gap-2'>
                    <TbPhotoAi className='text-primary-600 text-xl'/> 
                    <span className='group-[.is-active]:!text-gray-50'>{formatNumber(item?.images_amount)} <Trans i18nKey={`subscription.aiImages`}>AI-Images</Trans></span>
                </div>
                <div className='flex items-center gap-2'>
                    <TbDatabase className='text-primary-600 text-xl'/> 
                    <span className='group-[.is-active]:!text-gray-50'>{formatNumber(getGB(item?.bytes_amount))} GB <Trans i18nKey={`subscription.storage`}>Storage</Trans></span>
                </div>
                {/* <div className='flex items-center gap-2'>
                    <HiOutlineAtSymbol className='text-primary-600 text-xl'/> 
                    <span className='group-[.is-active]:!text-gray-50 flex items-center gap-2'>
                        <Trans i18nKey={`subscription.fullsocialNetworkSupport`}>All Social Networks</Trans>
                    </span>
                </div> */}
                {index > 0 && <div className='flex items-center gap-2'>
                    <HiOutlineSupport className='text-primary-600 text-xl'/> 
                    <span className='group-[.is-active]:!text-gray-50'><Trans i18nKey={`subscription.liveChatSupport`}>Live Chat Support </Trans></span>
                </div>}
            </div>
            <div className="flex flex-1 flex-col items-end gap-4 mt-4 lg:mt-0">
                <div className='flex flex-col gap-0 items-right'>
                    {discountFactor < 1 && 
                        <div className="flex items-center gap-2">
                            <Badge content={`${discountText}% OFF`} />
                            <h1 className='!text-lg group-[.is-active]:!text-white text-right line-through opacity-[0.4]'>
                                ${formatNumber(((item?.prices?.filter(e => e.recurring == billingFrequency)[0]).amount) / (billingFrequency == 'year' ? 12 : 1) / 100 , 2)} 
                            </h1>
                        </div>
                    }
                    <h1 className='mega-title !text-3xl group-[.is-active]:!text-white text-right'>
                        ${formatNumber(((item?.prices?.filter(e => e.recurring == billingFrequency)[0]).amount) / (billingFrequency == 'year' ? 12 : 1) / 100 * discountFactor, 2)} 
                        &nbsp;<small>USD</small>
                    </h1>
                    <h4 className='text-gray-300 font-semibold mt-0 !text-base text-right'>/ month</h4>
                </div>
            </div>
        </Card>
    )
}

export default ProductPlan;
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Tooltip, Spinner } from 'components/ui';
import { HiOutlinePencil } from 'react-icons/hi';
import { TbShare3 } from 'react-icons/tb';
import { Trans } from 'react-i18next';
import ConfigPanelPostTypes from 'views/post_playground/ConfigPanelPostTypes';
import { useNavigate } from 'react-router-dom';
import Api from 'services/Api';
import { Notification, toast } from 'components/ui';
import { Draggable } from '@fullcalendar/interaction';


const CanvaDesign = ({
  design,
  company, 
  isDraggable = false, 
  onError = () => {},
  handleOnSelect = () => {}, 
  handleOnUnselect = () => {}, 
  selectedImages = [], 
  isSelectable = false 
}) => {
  const navigate = useNavigate();
  const mediaRef = useRef(null); 
  const [loading, setLoading] = useState(false);
  
  const handleEdit = () => window.open(`https://www.canva.com/design/${design.id}`, '_blank');
  
  const onAssetPublish = async (network) => {
    setLoading(true);
    try {
      const urls = await getExportUrls();
      let _assets = urls.map(url => ({
        image: {
          url: url,
        }
      }));
      
      navigate(`/calendar?postModal=${Math.random()}`, { state: { assets: _assets, network: network } });
    } catch (error) {
      onError(error);
      toast.push(
        <Notification 
        type="warning" 
        title={`Error: ${error.message}`} 
        />
      ); 
    } finally {
      setLoading(false); 
    }
  }
  
  const getExportUrls = useCallback(async () => {
    let url = null;
    const designId = design.id;
    
    const checkExportStatus = async (jobId) => {
      await new Promise(resolve => setTimeout(resolve, 1500)); 
      const response = await Api.get(`/companies/${company?.id}/canva/designExportJob/${jobId}`).then(res => res.data);
      
      if (response.status === "success") {
        return response.urls;
      } else if (response.status === "in_progress") {
        return checkExportStatus(jobId); 
      } else {
        onError(new Error('Error: ' + response.status));
        toast.push(
          <Notification 
            type="danger" 
            title={response.status} 
          />
        );
        throw new Error('Error: ' + response.status);
      }
    };
    
    try {
      toast.push(
        <Notification 
          type="info" 
          title={`Exporting Canva Design`} 
          duration={6000}
        />
      ); 
      
      const response = await Api.post(`/companies/${company?.id}/canva/designExportJob`, {}, {
        design_id: designId,
        format: {
          "type": 'png',
          "quality": "horizontal_1080p",
        }, 
      }).then(res => res.data);
      
      if (response.status === "success") {
        url = response.urls.pop();
      } else if (response.status === "in_progress") {
        url = await checkExportStatus(response.id);
      }
    } catch (error) {
      onError(error);
    }
    
    return url;
  }, [design.id, company?.id]);
  
  useEffect(() => {
    let draggable = null;
    if (mediaRef.current && isDraggable) {
      const initializeDraggable = async () => {
        draggable = new Draggable(
          mediaRef.current,
          {
            eventData: {
              title: 'New event',
              duration: "1:30",
              post_group: {
                posts: [{
                  assets: [{
                    image: {
                      url: design?.thumbnail.url
                    }
                  }],
                  preferences: {
                    media_product_type: "FEED"
                  },
                  getAssets: getExportUrls,
                }]
              }
            },
          }
        );
      };
      initializeDraggable();
      return () => draggable && draggable.destroy();
    }
  }, [mediaRef, isDraggable, getExportUrls]);
  
  
  const onAssetSelect = async () => {
    setLoading(true);
    const urls = await getExportUrls();
    
    if (!urls) return;
    setLoading(false);
    
    return handleOnSelect(urls, design.id);
  }
  
  const onAssetUnselect = () => handleOnUnselect(design.id);
  
  return (
    <div ref={mediaRef} key={design.id} className={`group relative transition-all ${isDraggable && `!cursor-pointer hover:drop-shadow-[0_10px_8px_rgba(0,0,0,0.2)] hover:rotate-[2deg]`}`}>
    <div className="w-full" style={{ aspectRatio: `${design?.thumbnail?.width || 1} / ${design?.thumbnail?.height || 1}` }}>
    <img 
      src={design?.thumbnail?.url || `/img/others/noimage.jpg`} 
      alt={design?.title || `Untitled Design`}
      className="w-full h-full object-cover rounded-lg group-hover:opacity-[0.85] transition-all"
    />
    <div className="absolute inset-0 flex flex-col justify-between p-4 pb-2 bg-gradient-to-b from-transparent via-transparent to-gray-900/90 rounded-lg">
    <h3 className="text-sm font-semibold text-white line-clamp-2" style={{ textShadow: '0px 0px 4px rgba(0, 0, 0, 0.8)' }}>{design?.title || `Untitled Design`}</h3>
    {!isSelectable ? (
      <div className="flex items-center justify-end mt-2 z-[12] relative">
        <Tooltip title={<Trans i18nKey="canva.edit">Edit</Trans>}>
          <Button 
          size="sm" 
          shape="circle"
          variant="plain"
          className="!text-white !bg-transparent"
          icon={<HiOutlinePencil />}
          onClick={handleEdit}
          disabled={loading} // Deshabilitar el botón si está cargando
          />
        </Tooltip>
        <ConfigPanelPostTypes
          postType={undefined}
          setPostType={(postType) => onAssetPublish(postType)}
          menuClassName="!max-h-[160px] !z-[150] zoom-90 max-w-[200px]"
          placement="top-end"
          hideChecks={true}
          renderTitle={
            <Tooltip title={<Trans i18nKey={'mediaElement.publishLabel'} />} wrapperClass="ml-1.5">
              <Button
              variant="solid"
              size="xs"
              shape="round"
              className="!rounded-xl"
              icon={<TbShare3 />}
              disabled={loading} // Deshabilitar el botón si está cargando
              />
            </Tooltip>
          }
        />
      </div>
    ):
    <Tooltip title={<Trans i18nKey={'mediaElement.selectLabel'} />} wrapperClass="ml-1.5">
    {!selectedImages?.find(e => e.designId == design.id)  ?
      <Button
      shape="circle"
      variant=""
      size="xs"
      onClick={(e) => {
        e.stopPropagation();
        if (!loading) onAssetSelect(); 
      }}
      >
      {<Trans i18nKey={'mediaElement.selectLabel'} />}
      </Button>
      : <Button
      shape="circle"
      variant="solid"
      size="xs"
      onClick={(e) => { 
        e.stopPropagation(); 
        if (!loading) onAssetUnselect(); 
      }}
      >
      {<Trans i18nKey={'mediaElement.selectedLabel'} />}
      </Button>
    }
    </Tooltip>
  }
  </div>
  </div>
  {loading && (
    <div className="absolute inset-0 flex justify-center items-center bg-gray-900/50 rounded-lg">
    <Spinner size="lg" />
    </div>
  )}
  {loading && (
    <div className="absolute inset-0 bg-gray-900/50 z-10" /> 
  )}
  </div>
);
};

export default CanvaDesign;

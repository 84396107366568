import { Button, Dropdown } from "components/ui";
import { PLACEMENT } from "components/ui/utils/constant";
import { useEffect, useState } from "react";
import { BsStars } from "react-icons/bs";
import { TbPencilStar } from "react-icons/tb";
import { getFileTypeImageVideo } from "utils/fileType";
import useEditorCallback from "utils/hooks/useEditorCallback";
import useVistaCreate from "utils/hooks/useVistaCreate";
import DesignTypesDropdownOptions from "./DesignTypesDropdownOptions";
import { BiSolidMagicWand } from "react-icons/bi";

const MagicEditButton = ({
    asset,
    onAssetEdit = undefined,
    size = "sm",
    placement = PLACEMENT.TOP_END
}) => {

    const [ isLoadingEditOpen, setIsLoadingEditOpen ] = useState(false);
    const [ showDesignTypes, setShowDesignTypes ] = useState(false);
    const [ isLoadingMedia, setIsLoadingMedia ] = useState(false);
    const { initEditor, resizeByDesignType } = useVistaCreate();
    const { onPublish } = useEditorCallback();

    const type = getFileTypeImageVideo(asset?.video?.url || asset?.image?.url);

    const handleOnOpenEditor = () => {
        setIsLoadingEditOpen(true);
        initEditor(asset, undefined, undefined, (data) => {
            onAssetEdit && onAssetEdit(data.url, data.id);
            asset?.id && onPublish(data, asset);
        }, undefined, undefined, undefined, setIsLoadingMedia).catch(() => {
            setIsLoadingEditOpen(false);
            setIsLoadingMedia(false);
        }).finally(() => {
            setIsLoadingEditOpen(false);
        });
        setTimeout(() => {
            setIsLoadingEditOpen(false);
        }, 10000);
    }

    const handleOnChangeFormat = async (designType) => {
        setIsLoadingEditOpen(true);
        const data = await resizeByDesignType(designType, asset);
        onAssetEdit && onAssetEdit(data.url, data.id);
        asset?.id && onPublish(data, asset);
        setIsLoadingEditOpen(false);
    }

    const resetState = () => {
        setShowDesignTypes(false);
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoadingMedia(false);
        }, 1000);
    }, [asset?.image?.url || asset?.video?.url]);

    if (!(type == "image" || asset?.vista_project_id)) {
        return <></>;
    }

    return (
        <Dropdown
            placement={placement}
            menuClass="w-auto max-w-[210px] max-h-[300px] overflow-y-auto"
            disabled={isLoadingEditOpen || isLoadingMedia}
            onClick={(e) => e.stopPropagation()}
            renderTitle={
                <Button
                    shape="round"
                    variant="ai"
                    size={size}
                    className="!rounded-xl"
                    onClick={(e) => { resetState(); }}
                    icon={<TbPencilStar />}
                    loading={isLoadingEditOpen || isLoadingMedia}
                />
            }
        >
        {!!showDesignTypes 
            ? <DesignTypesDropdownOptions 
                allowedTypes={["image"]}
                onSelectDesignType={handleOnChangeFormat}
            />
            : <>
                {type == "image" && <Dropdown.Item className="!p-0">
                    <div onClick={(e) => { setShowDesignTypes(true); e.stopPropagation(); }} className="w-full h-ful flex items-center gap-2 cursor-pointer py-2 px-4">
                        <BiSolidMagicWand className='h-6 md:text-2xl text-pink-500' />
                        <span>Change Format...</span>
                    </div>
                </Dropdown.Item>}
                <Dropdown.Item className="!p-0">
                    <div onClick={(e) => { handleOnOpenEditor(); e.stopPropagation() }} className="w-full h-ful flex items-center gap-2 cursor-pointer py-2 px-4">
                        <TbPencilStar className='h-6 md:text-2xl text-purple-500' />
                        <span>{asset?.vista_project_id ? `Open` : `Create`} Creative</span>
                    </div>
                </Dropdown.Item>
            </>
        }
        </Dropdown>
    )
}

export default MagicEditButton;
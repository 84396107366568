"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageWriter = void 0;
class StorageWriter {
    constructor(deviceKeysTable, issuesTable, logFactory, logsTable) {
        this.deviceKeysTable = deviceKeysTable;
        this.issuesTable = issuesTable;
        this.logFactory = logFactory;
        this.logsTable = logsTable;
        this.isClearingSpace = false;
        this.logsQueue = [];
    }
    handleMessage(msg) {
        return __awaiter(this, void 0, void 0, function* () {
            switch (msg.type) {
                case 'device-key':
                    return this.addDeviceKey(msg.data.key, msg.data.value, msg.data.callInfo, msg.data.time);
                case 'init': {
                    this.options = msg.data.options;
                    this.sessionUUID = msg.data.sessionUUID;
                    break;
                }
                case 'issue':
                    return this.addIssue(msg.data.type, msg.data.title, msg.data.text, msg.data.uuid, msg.data.callInfo, msg.data.time);
                case 'log-entry':
                    return this.addLogEntry(msg.data.log, msg.data.callInfo, msg.data.time);
            }
        });
    }
    addDeviceKey(key, value, callInfo, time) {
        this.deviceKeysTable.add({
            sessionUUID: this.sessionUUID,
            data: { key, value },
        });
        this.addLog(this.logFactory.createFromDeviceKey(key, value, callInfo, time));
    }
    addLog(log) {
        return __awaiter(this, void 0, void 0, function* () {
            const entry = {
                sessionUUID: this.sessionUUID,
                data: log,
            };
            if (this.isClearingSpace) {
                this.logsQueue.push(entry);
            }
            else {
                try {
                    yield this.logsTable.add(entry);
                }
                catch (err) {
                    if (this.isQuotaExceededError(err)) {
                        this.logsQueue.push(entry);
                        this.isClearingSpace = true;
                        yield this.logsTable.deleteOldLogs(this.sessionUUID);
                        yield this.processLogsQueue();
                        this.isClearingSpace = false;
                    }
                }
            }
        });
    }
    addLogEntry(log, callInfo, time) {
        this.addLog(this.logFactory.createFromEntry(log, callInfo, time));
    }
    addIssue(type, title, text, uuid, callInfo, time) {
        this.issuesTable.add({
            sessionUUID: this.sessionUUID,
            data: { title, text, type, uuid },
        });
        this.addLog(this.logFactory.createFromIssue(uuid, callInfo, time));
    }
    isQuotaExceededError(err) {
        var _a;
        return ((err.name === 'QuotaExceededError') ||
            (((_a = err.inner) === null || _a === void 0 ? void 0 : _a.name) === 'QuotaExceededError'));
    }
    processLogsQueue() {
        return __awaiter(this, void 0, void 0, function* () {
            while (this.logsQueue.length > 0) {
                const entry = this.logsQueue[0];
                try {
                    yield this.logsTable.add(entry);
                    this.logsQueue.shift();
                }
                catch (err) {
                    if (this.isQuotaExceededError(err)) {
                        yield this.logsTable.deleteOldLogs(this.sessionUUID);
                    }
                }
            }
        });
    }
}
exports.StorageWriter = StorageWriter;

const getMeta = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      img.src = src
    })
}

export const getImageDimensions = async (url) => {
    let width, height;
    const img = await getMeta(url);
    width = img.naturalWidth;
    height = img.naturalHeight;
    return { width, height }
}
import { PLACEMENT } from "components/ui/utils/constant";
import { AIContext, aiSizeOptions } from "contexts/AIContext";
import { useContext } from "react";
import { HiCheckCircle } from "react-icons/hi";

const { Dropdown, Tooltip } = require("components/ui");

const AIImageDropdownSizes = ({
    size,
    setSize,
    model,
    placement = PLACEMENT.CENTER_CENTER,
    className = "",
    parentClassName = ""
}) => {

    const { aiModels } = useContext(AIContext);
    const aiSizeFilteredOptions = aiSizeOptions.filter(e => aiModels?.find(m => m.model == model)?.availableSizes?.includes(e.id));

    return (
        <Dropdown
            menuClass="max-h-[290px] w-[240px] overflow-y-auto !rounded-2xl !border-0 !p-0"
            placement={placement}
            parentClassName={parentClassName}
            renderTitle={
                <div className={`cursor-pointer !pl-5 overflow-hidden whitespace-nowrap !px-10 !py-3 !h-auto bg-gray-50 dark:bg-gray-600 rounded-2xl flex items-center font-semibold ${className}`}>
                    {aiSizeFilteredOptions?.filter(e => e.id == size)?.[0]?.label || aiSizeFilteredOptions?.[0]?.label}
                </div>
            }
        >
            {aiSizeOptions?.sort((a, b) => aiSizeFilteredOptions?.map(e => e.id)?.includes(b?.id) - aiSizeFilteredOptions?.map(e => e.id)?.includes(a?.id)).map((_size, key) => (
                <Dropdown.Item key={key} className={`${!aiSizeFilteredOptions?.map(e => e.id)?.includes(_size?.id) && "opacity-40"} !px-0`}>
                    <Tooltip 
                        title={`This aspect ratio is not available for the selected model`} 
                        disabled={aiSizeFilteredOptions?.map(e => e.id)?.includes(_size?.id)}
                        wrapperClass={`w-full block flex-1`}
                        placement={`right`}
                    >
                        <div onClick={() => !!aiSizeFilteredOptions?.map(e => e.id)?.includes(_size?.id) && setSize(_size?.id)} className={`flex justify-between items-center gap-2 !px-4 !py-2 cursor-pointer dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 w-full ${!aiSizeFilteredOptions?.map(e => e.id)?.includes(_size?.id) && "pointer-events-none"}`}>
                            <div className='flex items-center gap-2'>
                                <span>{_size?.label}</span>
                            </div>
                            {!!aiSizeFilteredOptions?.map(e => e.id)?.includes(_size?.id) && (
                                <HiCheckCircle className={`text-xl text-primary-600 shrink-0 ${_size?.id != size && `opacity-[0.1]`}`} />
                            )}
                        </div>
                    </Tooltip>
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
}

export default AIImageDropdownSizes;
import ContextMenu from "components/layout/MagicEditor/ContextMenu";
import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useOnClickOutside from "utils/hooks/useOnClickOutside";

const ContextMenuContext = React.createContext({
    openContextMenu: (network, value, menuPosition, fullText, selectedText, selectionStartEnd) => void 0,
    contextMenuRef: undefined
});

const ContextMenuProvider = ({ children }) => {
    
    const [ network, setNetwork ] = useState("")
    const [ value, setValue ] = useState("");
    const [ onChange, setOnChange ] = useState({ handler: () => void 0 });
    const [ menuOpened, setMenuOpened ] = useState(false);
    const [ menuPosition, setMenuPosition ] = useState({ x: 0, y: 0 });
    const [ fullText, setFullText ] = useState("");
    const [ selectedText, setSelectedText ] = useState("");
    const [ selectionStartEnd, setSelectionStartEnd ] = useState({ start: 0, end: 0 });
    const ref = useRef();
    const contextMenuRef = useRef();

    const openContextMenu = (network, value, onChange, menuPosition, fullText, selectedText, selectionStartEnd) => {
        setNetwork(network);
        setValue(value);
        setOnChange({ handler: onChange });
        setMenuPosition(menuPosition);
        setFullText(fullText);
        setSelectedText(selectedText);
        setSelectionStartEnd(selectionStartEnd);
        setMenuOpened(true);
    }

    useEffect(() => {
        if (onChange && onChange?.handler) {
            onChange?.handler(value);
        }
    }, [value]);
    
    useOnClickOutside(contextMenuRef, () => {
        setMenuOpened(false);
    }, [menuOpened])
    
    return (
        <ContextMenuContext.Provider value={{ 
            openContextMenu,
            contextMenuRef
        }}>
            {children}
            <ContextMenu 
                ref={contextMenuRef}
                menuOpened={menuOpened} 
                setMenuOpened={setMenuOpened}
                menuPosition={menuPosition} 
                fullText={fullText}
                selectedText={selectedText}
                selectionStartEnd={selectionStartEnd}
                inputRef={ref}
                value={value}
                setValue={setValue}
                network={network}
            />
        </ContextMenuContext.Provider>
    );
};

export { ContextMenuContext, ContextMenuProvider };
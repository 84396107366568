import React, { ReactNode, useContext, useEffect, useState } from "react";
import { CompanyContext } from "./CompanyContext";
import { useSocialNetworks } from "utils/hooks/socials/useSocialNetworks";
import { CalendarActiveSocialNetworkContext } from "./CalendarActiveSocialNetworkContext";
import Api from "services/Api";
import { Notification, toast } from "components/ui";

const TagContext = React.createContext({
    tags: [],
    isLoadingTags: false,
    fetchTags: () => void 0,
    createTag: (name, color) => void 0,
    updateTag: (id, name, color) => void 0,
    toggleActiveTag: (id) => void 0
});

const TagProvider = ({ children }) => {

    const { company } = useContext(CompanyContext);
    const [ tags, setTags ] = useState();
    const [ isLoadingTags, setIsLoadingTags ] = useState();
    
    const fetchTags = async () => {
        if (company) {
            setIsLoadingTags(true);
            try {
                const _tags = await Api.get(`/tags?sort=-id&filter[company_id]=${company?.id}`).then(({ data }) => data);
                setTags(_tags);
            } catch (err) {}
            setIsLoadingTags(false);
        } else {
            setTags([])
        }
    }

    const updateTag = async (id, name, color) => {
        try {
          await Api.put(`/tags/${id}`, {}, { name, color });
          await fetchTags();
        } catch (err) {
          toast.push(<Notification title={err?.message || err} type="danger" />);
        }
    }

    const toggleActiveTag = async (id) => {
        const actualStatus = tags.find(e => e.id == id).active;
        try {
          await Api.put(`/tags/${id}`, {}, { active: !actualStatus });
          await fetchTags();
        } catch (err) {
          toast.push(<Notification title={err?.message || err} type="danger" />);
        }
    }

    const createTag = async (name, color) => {
        try {
          await Api.post(`/tags`, {}, { name, color, company_id: company?.id, active: 1 });
          await fetchTags();
        } catch (err) {
          toast.push(<Notification title={err?.message || err} type="danger" />);
        }
    }

    useEffect(() => {
        fetchTags();
    }, [company?.id]);

    return (
        <TagContext.Provider value={{ 
            tags,
            isLoadingTags,
            fetchTags,
            createTag,
            updateTag,
            toggleActiveTag
        }}>
            {children}
        </TagContext.Provider>
    );
};

export { TagContext, TagProvider };
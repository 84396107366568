import React from 'react'
import PropTypes from 'prop-types'
import { MenuContextConsumer } from './context/menuContext'
import { GroupContextConsumer } from './context/groupContext'
import { CollapseContextConsumer } from './context/collapseContext'
import Item from '../MenuItem'

const MenuItem = (props) => {
    const { eventKey, ...rest } = props
    const regex = new RegExp(`(^|/)${eventKey.toLowerCase()}($|/)`);
    const isActive = regex.test(window.location.pathname.toLowerCase());
    
    return (
        <MenuContextConsumer>
            {(context) => (
                <GroupContextConsumer>
                    {() => (
                        <CollapseContextConsumer>
                            {() => (
                                <Item
                                    onSelect={context.onSelect}
                                    menuItemHeight={context.menuItemHeight}
                                    variant={context.variant}
                                    isActive={isActive}
                                    eventKey={eventKey}
                                    {...rest}
                                />
                            )}
                        </CollapseContextConsumer>
                    )}
                </GroupContextConsumer>
            )}
        </MenuContextConsumer>
    )
}

MenuItem.propTypes = {
    disabled: PropTypes.bool,
    eventKey: PropTypes.string,
}

export default MenuItem

import OutOfWordsModal from "components/layout/OutOfWordsModal";
import SelectPlanModal from "components/layout/SelectPlanModal";
import { CompanyContext } from "contexts/CompanyContext";
import { PriorityModalContext } from "contexts/PriorityModalContext";
import { SessionContext } from "contexts/SessionContext";
import { get, replace } from "lodash";
import { useContext, useEffect, useState } from "react";
import Api from "services/Api";
import { replaceAll } from "utils/replaceAll";
import { formatSocialPostObj } from "../../formatSocialPostObj";
import { getSocialNetworkIntegration } from "utils/getSocialNetworkIntegration";

export const useMedium = () => {

    const { company, companySocials } = useContext(CompanyContext);
    
    const getMediumPosts = async (
        per_page = 100, // 100
        start_date = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000),
        end_date = new Date()
    ) => {
        const socialNetwork = getSocialNetworkIntegration("medium", companySocials);
        if (!socialNetwork) return [];

        start_date = start_date.toISOString();
        end_date = end_date.toISOString();

        if (localStorage.getItem(`${company.id}_${socialNetwork.id}_${start_date.split("T")[0]}${end_date.split("T")[0]}`)) {
            return JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_${start_date.split("T")[0]}${end_date.split("T")[0]}`));
        }

        return await Api.get(`/socialNetworks/${socialNetwork.id}/posts`).then(({ data: posts }) => { 
            posts = posts?.map((post) => {
                return formatSocialPostObj(
                    "medium",
                    post?.guid,
                    socialNetwork?.external_id,
                    post?.author,
                    "",
                    "",
                    post?.pubDate,
                    post?.content,
                    post?.thumbnail ? [{ url: post?.thumbnail, width: 1000, height: 1000, type: "image" }] : [],
                    0,
                    0,
                    0,
                    0,
                    post?.title
                )
            });
            localStorage.setItem(`${company.id}_${socialNetwork.id}_${start_date.split("T")[0]}${end_date.split("T")[0]}`, JSON.stringify(posts));
            return posts; 
        });
    }

    return { getMediumPosts }
}


import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api";
import { CompanyContext } from "./CompanyContext";
import { ScheduledPostContext } from "./ScheduledPostContext";
import dayjs from "dayjs";
import { ruleToDateObj } from "utils/rulesUtils";
import { Notification, toast } from "components/ui";

const RuleGroupsContext = React.createContext({
  ruleGroups: [],
  isLoading: false,
  autoscheduledPosts: [],
  isLoadingAutoscheduledPosts: false,
  getRuleGroups: (replace = false) => void 0,
  updateRuleGroupFields: (id, fileds = undefined) => void 0,
  deleteRuleGroup: (id) => void 0,
  isAutomatedPostGroup: (postGroup) => false,
  getAutomatedPublishAt: (postGroup) => void 0
});

const RuleGroupsProvider = ({ children }) => {

  const { getScheduledPostGroups, triggerUpdatePostGroups } = useContext(ScheduledPostContext);
  const { company } = useContext(CompanyContext);
  const [ ruleGroups, setRuleGroups ] = useState();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ autoscheduledPosts, setAutoscheduledPosts ] = useState([]);
  const [ isLoadingAutoscheduledPosts, setIsLoadingAutoscheduledPosts ] = useState(false);
  
  const getRuleGroups = async (replace = false) => {
    if (company?.id) {
      await Api.get(`/ruleGroups?include=tags,rules&sort=-id&filter[company_id]=${company?.id}`, {}).then(({ data: _ruleGroups }) => {
        setRuleGroups(replace ? _ruleGroups : [...ruleGroups, ..._ruleGroups]);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }

  const updateRuleGroupFields = async (id, fields = undefined) => {
    await Api.put(`/ruleGroups/${id}`, {}, fields).then(() => {
      getRuleGroups(true);
    }).catch((err) => {
      toast.push(
        <Notification 
          title={err?.message || err}  
          type="warning"
        />
      )
    });
  } 

  const deleteRuleGroup = async (id) => {
    await Api.delete(`/ruleGroups/${id}`).then(() => {
      getRuleGroups(true);
    }).catch((err) => {
      toast.push(
        <Notification 
          title={err?.message || err}  
          type="warning"
        />
      )
    });
  }

  const isAutomatedPostGroup = (postGroup) => {
    let flag = false;
    if (!!postGroup?.publish_at) {
      return false;
    }
    if (ruleGroups?.length) {
      const activeRuleGroups = ruleGroups?.filter(e => !!e.active);
      activeRuleGroups?.map(ruleGroup => {
        if (!postGroup?.tags || !postGroup?.tags?.length) {
          if (!ruleGroup?.tags || !ruleGroup?.tags?.length) {
            flag = true;
          }
        }
        postGroup?.tags?.map(tag => {
          if (!!ruleGroup?.tags?.find(t => t?.id == tag?.id)) {
            flag = true;
          }
        });
      })
    }
    return flag;
  }

  const getAutomatedPublishAt = (postGroup) => {
    return autoscheduledPosts?.find(e => e?.id == postGroup?.id)?.publish_at;
  }

  const getAutoscheduledPosts = async () => {
    if (isLoadingAutoscheduledPosts) return;
    setIsLoadingAutoscheduledPosts(true);
    let _autoscheduledPostGroups = [];
    try {
      if (ruleGroups?.length) {
        const activeRuleGroups = ruleGroups?.filter(e => !!e.active);
        if (!!activeRuleGroups?.length) {
          let activeRuleGroupsWithPostGroups = [];
          if (activeRuleGroups?.length) {
            const results = await Promise.allSettled(activeRuleGroups.map(ruleGroup => 
              getScheduledPostGroups(
                { 
                  rule_group_id: ruleGroup?.id,
                  publish_at_null: 1,
                  status: 'pending'
                }, 
                "id",
                true
              )
            ));
            activeRuleGroupsWithPostGroups = [
              ...activeRuleGroupsWithPostGroups,
              ...(results?.length ? results?.map((e,i) => {
                const ruleGroup = activeRuleGroups[i];
                let postGroups = e?.value;
                return {
                  ...ruleGroup,
                  postGroups: postGroups || []
                }
              }) : [])
            ];
          }
          // Calculate dates array
          let dates = activeRuleGroupsWithPostGroups?.map((ruleGroup) => {
            let postGroups = ruleGroup?.postGroups;
            let _dates = [];
            if (postGroups?.length) {
              const rules = ruleGroup?.rules;
              if (rules?.length) {
                _dates = rules.map(rule => {
                  let date = dayjs(ruleToDateObj(rule));
                  return Array.apply(null, Array(54)).map((_,i) => date.add(i, 'weeks').toDate())
                });
                _dates = _dates.flat();
                const today = new Date();
                _dates = _dates.filter(e => e > today);
                _dates = _dates.sort((a,b) => a - b);
                _dates = _dates.map(date => ({
                  date: date,
                  ruleGroup: ruleGroup
                }))
              }
            }
            return _dates;
          });
          dates = dates?.flat()?.sort((a,b) => a?.date - b?.date);
          // Set dates to post groups
          dates.map(dateObj => {
            let ruleGroup = dateObj?.ruleGroup;
            let postGroups = ruleGroup?.postGroups?.filter(e => !_autoscheduledPostGroups?.find(i => e?.id == i?.id));
            let postGroup = postGroups?.shift();
            if (postGroup) {
              _autoscheduledPostGroups.push({
                ...postGroup,
                publish_at: dayjs(dateObj?.date).add(
                  !ruleGroup?.random_minutes 
                    ? 0 
                    : dayjs(postGroup?.created_at).unix() % parseFloat(ruleGroup?.random_minutes),
                  `minutes`
                ).toDate()?.toISOString(),
                ruleGroup: ruleGroup
              })
            }
          });
        }
      }
    } catch (err) {}
    setAutoscheduledPosts(_autoscheduledPostGroups?.flat());
    setIsLoadingAutoscheduledPosts(false);
  }

  useEffect(() => {
    getRuleGroups(true);
  }, [company?.id]);

  useEffect(() => {
    getAutoscheduledPosts();
  }, [JSON.stringify(ruleGroups), triggerUpdatePostGroups]);
  
  return (
    <RuleGroupsContext.Provider value={{ 
      ruleGroups,
      isLoading,
      autoscheduledPosts,
      isLoadingAutoscheduledPosts,
      getRuleGroups,
      updateRuleGroupFields,
      deleteRuleGroup,
      isAutomatedPostGroup,
      getAutomatedPublishAt
    }}>
      {children}
    </RuleGroupsContext.Provider>
  );
};

export { RuleGroupsContext, RuleGroupsProvider };
export const isMobile = () => {

    // User agent string method
    // let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    let isMobile = false;

    // Screen resolution method
    // if (!isMobile) {
        let screenWidth = window.screen.width;
        let innerWidth = window.innerWidth;
        isMobile = (screenWidth < 768 || innerWidth < 768);
    // }
    
    // // Touch events method
    // if (!isMobile) {
    //     isMobile = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    // }
    
    // // CSS media queries method
    // if (!isMobile) {
    //     let bodyElement = document.getElementsByTagName('body')[0];
    //     isMobile = window.getComputedStyle(bodyElement).getPropertyValue('content').indexOf('mobile') !== -1;
    // }
    
    return isMobile;
}
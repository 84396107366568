import React, { useContext, useEffect, useState } from 'react'
import { Button, Dropdown, Notification, Progress, toast } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { HiOutlineChevronDown, HiOutlineChip, HiOutlineLightningBolt, HiOutlinePlusCircle } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/formatNumber';
import { BsCheckCircleFill, BsCircle, BsLayers } from 'react-icons/bs';
import { MdLayers } from 'react-icons/md';
import { HiOutlineSquare2Stack, HiOutlineSquare3Stack3D, HiOutlineUser, HiPlus } from 'react-icons/hi2';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import Api from 'services/Api';
import { SessionContext } from 'contexts/SessionContext';
import { Loading } from 'components/shared';

const OutOfUsersModal = ({ limit = true }) => {

    const { closeModalPriority } = useContext(PriorityModalContext);
    const { user, updateUserData } = useContext(SessionContext);

    const [productActive, setProductActive] = useState();
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const recurring = user?.subscription?.items?.[0]?.price?.recurring;

    const icons = [
        <HiOutlineUser />
    ]

    const updateSubscription = () => {
        let newProductPriceId = products?.filter(e => e.id == productActive)?.[0]?.prices?.filter(e => e.recurring == recurring)?.[0]?.id;
        let newSubscription = user?.subscription?.items?.map((item) => {
            return {
                "id": item.price.id,
                "quantity": item.quantity
            }
        });
        if (newSubscription?.filter(item => item.id == newProductPriceId)?.length == 0) {
            newSubscription.push({
                "id": newProductPriceId,
                "quantity": 1
            });
        } else {
            newSubscription = newSubscription.map((item) => {
                if (item.id == newProductPriceId) {
                    item.quantity += 1;
                }
                return item;
            })
        }
        
        setIsSubmitting(true);
        Api.put(`/subscriptions`, {}, { prices: newSubscription }).then((response) => {
            toast.push(<Notification title="Great, plan has been upgraded! You can keep inviting!" type="success" />, { placement: "top-center" });
            updateUserData();
            setIsSubmitting(false);
            closeModalPriority();
        }).catch((err) => {
            toast.push(<Notification title="An error ocurred. Please, try again later." type="danger" />, { placement: "top-center" });
        }).finally(() => {
            setIsSubmitting(false);
        });
    }

    useEffect(() => {
        setIsLoading(true);
        Api.get(`/products?include=prices`).then(({ data: _products }) => {
            const filteredProducts = _products.filter(item => item.name.includes("add-user")).map((item, key) => { return { ...item, icon: icons[key] } });
            setProducts(filteredProducts);
            setProductActive(filteredProducts?.[0]?.id);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    return (
        <div className='flex flex-col gap-2'>
            {limit 
                ? <>
                    <h3 className='text-gray-900 mega-title'>Oops! You've hit your limit...</h3>
                    <h6 className='text-gray-700 font-normal'>Do you want to add another user to your plan?</h6>
                </>
                : <>
                    <h3 className='text-gray-900 mega-title'>Add Users</h3>
                </>
            }

            <div className='flex flex-col gap-2 pt-4'>
                {isLoading 
                    ? <Loading type={`element`} />
                    : <>
                        {products && products.map((item, key) => (
                            <div onClick={() => setProductActive(item?.id)} key={key} className={`cursor-pointer flex justify-between items-center gap-4 rounded-2xl border border-gray-200 dark:border-gray-600 border-solid py-4 px-4 transition-all hover:bg-primary-600/5 ${productActive == item?.id ? `!bg-primary-600/20 border-2 !border-primary-600 dark:!border-primary-600` : ``}`}>
                                <div className='flex items-center gap-4'>
                                    <div>
                                        <div className='w-8 h-8 bg-primary-600/30 border-4 border-primary-600/10 flex items-center justify-center rounded-full'>
                                            {item?.icon}
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-0 text-gray-800 dark:text-gray-100'>
                                        <div className='font-bold !text-sm'>{formatNumber(item?.users_amount, 0)} User{item?.users_amount > 1 && `s`}</div>
                                        <div className='!text-xs !-mt-0'>+${formatNumber(item?.prices?.filter(e => e.recurring == recurring)?.[0].amount / (recurring == "year" ? 12 : 1) / 100, 0)}/month</div>
                                    </div>
                                </div>
                                <div className='text-xl'>
                                    {productActive == item?.id
                                        ? <BsCheckCircleFill className='text-primary-600' />
                                        : <BsCircle className='text-gray-200 dark:text-gray-600' />
                                    }
                                </div>
                            </div>
                        ))}
                    </>
                }
            </div>

            <div className='flex justify-end gap-2 pt-5'>
                <Button onClick={closeModalPriority}>Cancel</Button>
                <Button loading={isSubmitting} disabled={!productActive} variant="solid" onClick={updateSubscription} icon={<HiOutlineUser />}>&nbsp; Add User</Button>
            </div>
        </div>
    )
}

export default OutOfUsersModal

import { Button, Dialog } from 'components/ui'
import React, { useContext } from 'react'
import ParticleBackground from 'react-particle-backgrounds'
import { particleBackgroundSettingsButton } from 'configs/particle-background.config';
import { BsStars } from 'react-icons/bs';
import { AppSumoContext } from 'contexts/AppSumoContext';

export default function FreePlusModal() {

  const { setShowFreePremiumImageModal } = useContext(AppSumoContext);

  const handleOnClose = () => {
    setShowFreePremiumImageModal(false);
  }

  return (
    <Dialog
      isOpen={true}
      closable={false}
      onClose={() => handleOnClose()}
      onRequestClose={() => handleOnClose()}
      overlayClassName="flex w-full h-full items-center justify-center"
      contentClassName='!p-0'
      width={500}
    >
      <div className='relative'>
        <div className='bg-gradient-primary rounded-2xl overflow-hidden relative max-h-[90svh] overflow-y-auto'>
          <ParticleBackground settings={particleBackgroundSettingsButton} className="absolute pointer-events-none" />
          <div className='w-full h-full flex flex-col gap-8 lg:gap-10 items-center justify-center p-4 lg:p-8 py-8 lg:py-12 relative z-[1]'>
            <h2 className='mega-title text-white flex items-center gap-2'>
              <img 
                src="/img/logo/logo-dark-full.png" 
                className='h-8'
              />
              <span className='plus'>Plus</span>
              <BsStars className='stars' />
            </h2>
            <h3 className='text-white text-center text-lg lg:text-2xl font-black'>
              Try our Followr Plus premium <b>AI image models</b> for free for limited time!
            </h3>
            <p className='text-gray-200 text-center text-sm -mt-4 lg:-mt-6 font-bold'>
              Just select one of our premium models (<b>Flux, Core, Ultra and Ideogram 2.0</b>) in the Models Dropdown and experience total enhancements on your content creation.
            </p>

            <div className='flex justify-center gap-2 mt-2'>
              <Button 
                variant="ai"
                icon={<BsStars />}
                onClick={handleOnClose}
              >
                Try it for free!
              </Button>
            </div>
          </div>
        </div>
        {/* <img src="/img/others/behind-wall-top.png" className='absolute top-0 translate-y-[-71.5%] left-[10%] w-[402w%] drop-shadow-xl' /> */}
      </div>
    </Dialog>
  );
}

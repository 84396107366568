import { Button, Dialog, Input, Notification, toast } from "components/ui";
import { SessionContext } from "contexts/SessionContext";
import React, { useContext, useState } from "react";
import { Trans } from "react-i18next";
import { HiMinus, HiOutlineDocumentText, HiOutlineUser, HiPlus } from "react-icons/hi";
import { HiOutlineTrash } from "react-icons/hi2";
import { IoBusinessOutline } from "react-icons/io5";
import Api from "services/Api";
import { formatNumber } from "utils/formatNumber";

const AddOn = (props) => {

    const {
        item, 
        recurring,
        ...rest
    } = props; 

    const { user, updateUserData } = useContext(SessionContext);
    const [ isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false)

    const handleOnClickDeleteAddOn = () => {
        setIsDeleteConfirmationDialogOpen(true);
    }

    const deleteAddOn = () => {
        let newSubscription = user?.subscription?.items?.map((_item) => {
            return {
                "id": _item.price.id,
                "quantity": _item.quantity
            }
        });
        newSubscription = newSubscription.map((_item) => {
            if (_item.id == item.price.id) {
                _item.quantity = _item.quantity - 1;
            }
            return _item;
        })
        newSubscription = newSubscription.filter(item => item.quantity > 0);

        setIsDeleting(true);
        Api.put(`/subscriptions`, {}, { prices: newSubscription }).then((response) => {
            toast.push(<Notification title="Your plan has been updated" type="success" />, { placement: "top-center" });
            updateUserData();
            setIsDeleting(false);
            setIsDeleteConfirmationDialogOpen(false);
        }).catch((err) => {
            toast.push(<Notification title={err?.message || "An error ocurred. Please, try again later."} type="danger" />, { placement: "top-center" });
        }).finally(() => {
            setIsDeleting(false);
        });
    }

    return (<>
        {[...Array(item.quantity)].map((_, key) => (
            <div key={key} className="flex gap-4 items-center rounded-3xl border border-gray-200 dark:border-gray-700 py-3 px-6" {...rest}>
                <div className="flex items-center gap-2 w-[300px]">
                    {item.product.name.includes("words") && <HiOutlineDocumentText className="text-lg" />}
                    {item.product.name.includes("user") && <HiOutlineUser className="text-lg" />}
                    {item.product.name.includes("company") && <IoBusinessOutline className="text-lg" />}
                    <span>{item.product.description}</span>
                </div>
                <div className="flex-1 flex-col text-center">
                    {/* <span className="mega-title text-lg block !m-0 leading-0 text-gray-800 dark:text-gray-200">${formatNumber(item.price.amount / (recurring == "year" ? 12 : 1) / 100 * item.quantity, 2)} <small className="text-xs">/ month</small></span> */}
                    <span>${formatNumber(item.price.amount / (recurring == "year" ? 12 : 1) / 100, 2)} <small className="text-xs">/ <Trans i18nKey={`subscription.month`}>month</Trans></small></span>
                </div>
                <Button variant="twoTone" color="red-700" onClick={handleOnClickDeleteAddOn} icon={<HiOutlineTrash />}></Button>
            </div>
        ))}
        <Dialog
            isOpen={isDeleteConfirmationDialogOpen}
            onClose={() => setIsDeleteConfirmationDialogOpen(false)}
            onRequestClose={() => setIsDeleteConfirmationDialogOpen(false)}
            overlayClassName="flex items-center justify-center w-full"
            closeButtonClassName="!hidden"
        >
            <div className='flex flex-col gap-4 pt-4'>
                <p className='text-center w-full text-base text-gray-800 dark:text-gray-200'><Trans i18nKey={`subscription.areYouSureRemoveAddOn`}>Are you sure you want to remove the add-on?</Trans></p>
                <div className='flex justify-center w-full items-center gap-2 pt-4'>
                    <Button variant="plain" onClick={() => setIsDeleteConfirmationDialogOpen(false)}><Trans i18nKey={`subscription.cancel`}>Cancel</Trans></Button>
                    <Button variant="twoTone" color="red-600" loading={isDeleting} onClick={deleteAddOn}><Trans i18nKey={`subscription.confirm`}>Confirm</Trans></Button>
                </div>
            </div>
        </Dialog>
    </>)
}

export default AddOn;
export const checkIfIsIntegrated = (network, companySocials) => {
    if (companySocials?.filter(e => e.type == network)?.length == 0) {
        return false;
    }
    if (network == "facebook" || network == "instagram") {
        if (!companySocials?.filter(e => e.type == network)?.[0]?.page_id) {
            return false;
        }
    }
    return companySocials?.filter(e => e.type == network)?.[0];
}

import React, { useContext, useEffect, useState } from 'react'
import { Button, Dialog, Dropdown, Input, Notification, Progress, toast } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { HiOutlineChevronDown, HiOutlineChip, HiOutlineLightningBolt, HiOutlinePlusCircle } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/formatNumber';
import { BsCheckCircleFill, BsCircle, BsLayers } from 'react-icons/bs';
import { MdLayers } from 'react-icons/md';
import { HiOutlineSquare2Stack, HiOutlineSquare3Stack3D, HiOutlineUser, HiPlus } from 'react-icons/hi2';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import Api, { API_HOST } from 'services/Api';
import { SessionContext } from 'contexts/SessionContext';
import MySocialIcon from 'components/shared/MySocialIcon';
import { TbLoader3 } from 'react-icons/tb';
import { ModalContext } from 'contexts/ModalContext';
import InputModern from 'components/ui/Input/InputModern';
import { CalendarActiveSocialNetworkContext } from 'contexts/CalendarActiveSocialNetworkContext';
import capitalize from 'components/ui/utils/capitalize';
import { Trans } from 'react-i18next';

const PinterestIntegrationModal = () => {

    const { closeModalPriority } = useContext(PriorityModalContext);
    const { company, companySocials } = useContext(CompanyContext);
    const [ isLoading, setIsLoading ] = useState(false);

    const network = "pinterest";

    const handleSubmit = () => {
        window.open(`${API_HOST}/companies/${company?.id}/socialNetworks/${network}?token=${localStorage.getItem(`token`)}`);
        setIsLoading(true);
    }
    
    useEffect(() => {
        if (companySocials?.filter(e => e.type == network)?.length > 0) {
            toast.push(<Notification title={`${capitalize(network)} successfully connected!`} type="success" />);
            localStorage.removeItem("social_network_updated");
            closeModalPriority();
        }
    }, [companySocials])

    return (
        <>
            <div className='flex flex-col gap-8 pt-4'>
                <img src={`/img/socials-3d/${network}.png`} className='w-28 mx-auto drop-shadow' />
                <h3 className='text-gray-900 mega-title text-center'><Trans i18nKey={`pinterestIntegration.title`}>Pinterest Integration</Trans></h3>
                <p className='text-center !text-base'><Trans i18nKey={`pinterestIntegration.quickAndEasy`}>Connecting your Pinterest account is quick and easy. Simply click the "Connect with Pinterest" button below, and you'll be redirected to authorize the connection between your Pinterest profile and Followr.</Trans></p>
                <p className='text-center !text-base'><Trans i18nKey={`pinterestIntegration.makeSure`}>Make sure that you are connected to the right Pinterest account before connecting to Followr.</Trans></p>
            </div>

            <div className='flex justify-end gap-2 p-4 -m-6 w-auto mt-10 border-t border-gray-200 dark:border-gray-700'>
                <Button onClick={closeModalPriority}><Trans i18nKey={`pinterestIntegration.cancel`}>Cancel</Trans></Button>
                <Button loading={isLoading} variant="solid" onClick={handleSubmit} icon={<img src={`/img/socials/${network}.svg`} className="!h-4 !w-4 shrink-0" />}>&nbsp; <Trans i18nKey={`pinterestIntegration.connectWith`}>Connect with Pinterest</Trans></Button>
            </div>
        </>
    )
}

export default PinterestIntegrationModal

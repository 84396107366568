import HiOutlineGif from "components/shared/HiOutlineGif";
import { Button, Input } from "components/ui";
import InputModern from "components/ui/Input/InputModern";
import { CompanyContext } from "contexts/CompanyContext";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineAdjustments, HiOutlinePhotograph, HiOutlineSearch, HiOutlineVideoCamera } from "react-icons/hi";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import useDarkMode from "utils/hooks/useDarkMode";
import { getGiphyImages, getPexelsImages, getPexelsVideos, getPixabayImages, getPixabayVideos, getUnsplashImages } from "utils/stockImages";
import FilterBadge from "./FilterBadge";
import MediaElement from "./MediaElement";
import { Loading } from "components/shared";

const FollowrExploreMedia = ({ mediaContainerRef }) => {

    const { company } = useContext(CompanyContext);
    const [isLoading, setIsLoading] = useState(false);
    const [media, setMedia] = useState(localStorage.getItem(`exploreMedia`) ? JSON.parse(localStorage.getItem(`exploreMedia`)) : []);
    const [query, setQuery] = useState(localStorage.getItem(`exploreQuery`) ? localStorage.getItem(`exploreQuery`) : "");
    const [defaultQuery, setDefaultQuery] = useState("");
    const [page, setPage] = useState(0);
    const [exploreMediaOrientation, setExploreMediaOrientation] = useState(localStorage.getItem(`exploreMediaOrientation`) ? localStorage.getItem(`exploreMediaOrientation`) : "");
    const [exploreMediaType, setExploreMediaType] = useState(localStorage.getItem(`exploreMediaType`) ? localStorage.getItem(`exploreMediaType`) : "images")
    const [firstRender, setFirstRender] = useState(true);
    const [isDark, setMode] = useDarkMode();
    const perPage = 15;

    const getImages = (replace = false) => {
        let _page = page+1;
        if (replace) _page = 1;
        setPage(_page);
        if (!isLoading) {
            setIsLoading(true);
            getUnsplashImages(perPage, query != "" ? query : defaultQuery, exploreMediaOrientation).then((unsplashData) => {
                // getPexelsImages(perPage, _page, query != "" ? query : defaultQuery, exploreMediaOrientation).then((pexelsData) => {
                //     getPixabayImages(perPage, _page, query != "" ? query : defaultQuery, exploreMediaOrientation).then((pixabayData) => {
                //         setMedia(replace ? [...(unsplashData ?? []), ...(pexelsData ?? []), ...(pixabayData ?? [])] : [...media, ...(unsplashData ?? []), ...(pexelsData ?? []), ...(pixabayData ?? [])]);
                //         setIsLoading(false);
                //     });
                // });
                setMedia(replace ? [...(unsplashData ?? [])] : [...media, ...(unsplashData ?? [])]);
                setIsLoading(false);
            });
        }
    }

    const getGifs = (replace = false) => {
        let _page = page+1;
        if (replace) _page = 1;
        setPage(_page);
        if (!isLoading) {
            setIsLoading(true);
            getGiphyImages(perPage, _page, query != "" ? query : defaultQuery, exploreMediaOrientation).then((giphyData) => {
                setMedia(replace ? [...(giphyData ?? [])] : [...media, ...(giphyData ?? [])]);
                setIsLoading(false);
            });
        }
    }

    const getVideos = (replace = false) => {
        let _page = page+1;
        if (replace) _page = 1;
        setPage(_page);
        if (!isLoading) {
            setIsLoading(true);
            getPexelsVideos(perPage, _page, query != "" ? query : defaultQuery, exploreMediaOrientation).then((pexelsData) => {
                setMedia(replace ? [...(pexelsData ?? [])] : [...media, ...(pexelsData ?? [])]);
                setIsLoading(false);
            });
        }
    }

    const get = (replace = false) => {
        if (replace == true) setMedia([]);
        if (exploreMediaType == "images") getImages(replace);
        if (exploreMediaType == "videos") getVideos(replace);
        if (exploreMediaType == "gifs") getGifs(replace);

        localStorage.setItem('exploreMediaLastTimestamp', new Date().getTime());
    }

    const handleOnClickExploreMediaType = (_exploreMediaType) => {
        if (_exploreMediaType == exploreMediaType) return;
        setPage(1); 
        setMedia([]); 
        setExploreMediaType(_exploreMediaType);
    }

    const handleOnClickExploreMediaOrientation= (_exploreMediaOrientation) => {
        if (_exploreMediaOrientation == exploreMediaOrientation) return;
        setPage(1); 
        setMedia([]); 
        setExploreMediaOrientation(_exploreMediaOrientation);
    }

    useEffect(() => {
        if (!defaultQuery) {
            return;
        }
        if (
            firstRender && 
            media && 
            media?.length > 0 && 
            localStorage.getItem('exploreMediaLastTimestamp') && 
            localStorage.getItem('exploreMedia') && 
            parseInt(localStorage.getItem('exploreMediaLastTimestamp')) + 2 * 3600 * 1000 > new Date().getTime()
        ) {
            setFirstRender(false);
            return;
        }
        setPage(1);
        get(true);
        localStorage.setItem(`exploreMediaType`, exploreMediaType);
        localStorage.setItem(`exploreMediaOrientation`, exploreMediaOrientation);
    }, [exploreMediaType, exploreMediaOrientation, defaultQuery]);

    useEffect(() => {
        setDefaultQuery(company && company?.interests ? company?.interests[Math.floor(Math.random() * company?.interests?.length)] : "funny")
    }, [company]);

    useEffect(() => {
        if (media && media?.length > 0) {
            localStorage.setItem('exploreMedia', JSON.stringify(media))
        }
    }, [media])

    useEffect(() => {
        localStorage.setItem('exploreQuery', query)
    }, [query])

    return (
        <>
            <div className='w-auto mb-0 flex gap-0 items-center overflow-hidden rounded-xl relative'>
                <InputModern label="Search" value={query} onKeyDown={(e) => e.key == 'Enter' ? get(true) : void(0)} onChange={(e) => setQuery(e.target.value)} size="sm" placeholder="Search..." className="" />
                <Button
                    variant="twoTone"
                    icon={<HiOutlineSearch />}
                    className="text-base !w-10 !h-10 !absolute right-2 top-3 mt-[3px] !rounded-2xl"
                    onClick={() => get(true)}
                />
            </div>
            <div className="gap-2 flex flex-col !-mt-2">
                <div className="flex items-center gap-2">
                    <FilterBadge className="flex-col h-auto py-2" active={exploreMediaType == "images"} onClick={() => handleOnClickExploreMediaType("images")}>
                        <HiOutlinePhotograph className="h-5 w-5" />
                        <span>Images</span>
                    </FilterBadge>
                    <FilterBadge className="flex-col h-auto py-2" active={exploreMediaType == "videos"} onClick={() => handleOnClickExploreMediaType("videos")}>
                        <HiOutlineVideoCamera className="h-5 w-5" />
                        <span>Videos</span>
                    </FilterBadge>
                    <FilterBadge className="flex-col h-auto py-2" active={exploreMediaType == "gifs"} onClick={() => handleOnClickExploreMediaType("gifs")}>
                        <HiOutlineGif className="w-5 h-5" />
                        <span>Gifs</span>
                    </FilterBadge>
                </div>
                <div className="flex items-center gap-2">
                    <FilterBadge active={exploreMediaOrientation == "portrait"} onClick={() => exploreMediaOrientation == "portrait" ? handleOnClickExploreMediaOrientation("") : handleOnClickExploreMediaOrientation("portrait")}>
                        {/* <HiOutlinePhotograph /> */}
                        <span>Portrait</span>
                    </FilterBadge>
                    <FilterBadge active={exploreMediaOrientation == "square"} onClick={() => exploreMediaOrientation == "square" ? handleOnClickExploreMediaOrientation("") : handleOnClickExploreMediaOrientation("square")}>
                        {/* <HiOutlinePhotograph /> */}
                        <span>Square</span>
                    </FilterBadge>
                    <FilterBadge active={exploreMediaOrientation == "landscape"} onClick={() => exploreMediaOrientation == "landscape" ? handleOnClickExploreMediaOrientation("") : handleOnClickExploreMediaOrientation("landscape")}>
                        {/* <HiOutlinePhotograph /> */}
                        <span>Landscape</span>
                    </FilterBadge>
                </div>
            </div>
            {isLoading && <Loading type={`element`} />}
            {media?.length > 0 && 
                <div>
                    <ResponsiveMasonry columnsCountBreakPoints={{350: 1}}>
                        <Masonry gutter='0.5rem'>
                            {media.map((item, key) => (
                                <MediaElement key={key} aspectRatio={exploreMediaOrientation == "square" ? `aspect-square` : exploreMediaOrientation == "landscape" ? `aspect-[16/10]` : exploreMediaOrientation == "portrait" ? `aspect-[10/16]` : item?.aspectRatio ? item?.aspectRatio : `aspect-square`} src={item.src} originalSrc={item.original} mediaDescription={item?.description} isStock={item?.isStock} isDraggable={true} />
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                    <div className="flex flex-col items-center justify-center gap-4">
                        <Button onClick={() => get(false)} className="mt-4" variant="twoTone">{isLoading ? <Loading type={`element`} /> : `Load more`}</Button>
                        <a href="#" className="text-center -mt-1 !text-xs" onClick={() => mediaContainerRef.current.scroll({top: 0, left: 0, behavior: 'smooth' })}>Back to top</a>
                    </div>
                </div>
            }
            {media?.length == 0 && !isLoading && (
                <p className='!text-xs text-gray-400 dark:text-gray-600 text-center'>
                    <img src={isDark ? `/img/others/nothing-to-see-4.png` : `/img/others/nothing-to-see-4.png`} className="w-24 drop-shadow my-5 m-auto" />
                    Nothing to see here.
                    <br />
                    Try a different query.
                </p>
            )}
            <p className='!text-xs text-gray-400 dark:text-gray-600 text-center'>All images and videos are powered by our partner Unsplash.</p>
        </>
    )
}

export default FollowrExploreMedia;
import React, { useContext, useEffect, useState } from 'react'
import { Button, Dialog, Dropdown, Notification, Progress, toast } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { HiOutlineChevronDown, HiOutlineChip, HiOutlineLightningBolt, HiOutlinePlusCircle } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/formatNumber';
import { BsCheckCircleFill, BsCircle, BsLayers } from 'react-icons/bs';
import { MdLayers } from 'react-icons/md';
import { HiOutlineSquare2Stack, HiOutlineSquare3Stack3D, HiOutlineUser, HiPlus } from 'react-icons/hi2';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import Api from 'services/Api';
import { SessionContext } from 'contexts/SessionContext';
import MySocialIcon from 'components/shared/MySocialIcon';
import { TbLoader3 } from 'react-icons/tb';
import { CalendarActiveSocialNetworkContext } from 'contexts/CalendarActiveSocialNetworkContext';
import { Loading } from 'components/shared';
import { Trans } from 'react-i18next';

const InstagramPageSelectModal = () => {

    const { companySocials, updateSocials } = useContext(CompanyContext);
    const { updateUserData } = useContext(SessionContext);
    const { setInstagramPageSelectModalOpened } = useContext(CalendarActiveSocialNetworkContext);
    const [ pages, setPages ] = useState([]);
    const [ pageID, setPageID ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ isSubmitting, setIsSubmitting ] = useState(false);

    const instagramSocialNetwork = companySocials?.filter(e => e.type == 'instagram')?.[0];

    const handleOnRequestClose = () => {
        setIsLoading(true);
        Api.delete(`/socialNetworks/${instagramSocialNetwork?.id}`).then(() => {
            updateUserData();
            updateSocials();
            setInstagramPageSelectModalOpened(false);
        });
    }

    const handleSubmit = () => {
        setIsSubmitting(true);
        Api.put(`/socialNetworks/${instagramSocialNetwork?.id}`, {}, { page_id: String(pageID) }).then(() => {
            setIsLoading(true);
            updateUserData();
            updateSocials();
            setInstagramPageSelectModalOpened(false);
            toast.push(<Notification title="Instagram integrated successfully" type="success" />);
        }).catch((err) => {
            setIsLoading(false);
            setIsSubmitting(false);
            toast.push(<Notification title={err.message} type="danger" />);
        });
    }

    useEffect(() => {
        if (instagramSocialNetwork && !pages?.length) {
            setIsLoading(true);
            Api.get(`/socialNetworks/${instagramSocialNetwork?.id}/pages?limit=100`).then(({ data: pages }) => {
                setPages(pages);
                // if (pages?.length == 1) {
                //     setPageID(pages[0]?.id);
                // } else {
                    setIsLoading(false);
                // }
            }).catch((err) => {
                handleOnRequestClose();
                toast.push(<Notification title="An error ocurred. Please, try again later." type="danger" />);
            });
        }
    }, [companySocials]);

    return (
        <Dialog
            isOpen={true}
            closable={true}
            onRequestClose={handleOnRequestClose}
            overlayClassName="flex w-full h-full items-center justify-center"
            width={650}
        >
            {isLoading
                ? <>
                    <div className='flex flex-col items-center justify-center min-h-[300px]'>
                        <Loading type={'element'} /> 
                    </div>
                </> : <>
                    <img src="/img/others/instagram-banner-3.jpg" className='object-fit object-cover w-[120%] max-w-none h-[200px] -m-6 !mb-8' />
                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col gap-5'>
                            <h3 className='text-gray-900 mega-title'><Trans i18nKey={`instagramIntegration.title`}>Instagram Integration</Trans></h3>
                            <p className='text-gray-700 font-normal !text-sm'>
                                <Trans i18nKey={`instagramIntegration.dueToLimitations`}>Due to instagram limitations, you can only connect Instagram Business Accounts that are connected to a Facebook page.</Trans> 
                                <Trans i18nKey={`instagramIntegration.forMoreInfo`}>For more information on how to do this</Trans> <a href="https://help.followr.ai" target="_blank" className="text-primary-600"><Trans i18nKey={`instagramIntegration.clickHere`}>click here</Trans></a>
                                <br/><br/>
                                <Trans i18nKey={`instagramIntegration.selectInstagramAccount`}>Select the Instagram account you'd like to connect to Followr.</Trans>
                            </p>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <h6><Trans i18nKey={`instagramIntegration.instagramAccounts`}>Instagram Business Accounts</Trans> ({pages?.length})</h6>
                            <div className="max-h-[275px] overflow-y-auto flex flex-col gap-2">
                                {pages && pages.map((item, key) => (
                                    <div onClick={() => setPageID(item?.id)} key={key} className={`cursor-pointer flex justify-between items-center gap-4 rounded-2xl border border-gray-200 dark:border-gray-600 border-solid py-2.5 px-4 transition-all hover:bg-primary-600/5 ${pageID == item?.id ? `!bg-primary-600/20 border-2 !border-primary-600 dark:!border-primary-600` : ``}`}>
                                        <div className='flex items-center gap-4'>
                                            <div>
                                                <div className='w-10 h-10 bg-primary-600/30 border-4 border-primary-600/10 flex items-center justify-center rounded-full overflow-hidden'>
                                                    <img src={item?.picture?.data?.url} className="w-full h-full" />
                                                </div>
                                            </div>
                                            <div className='flex flex-col gap-0 text-gray-800 dark:text-gray-100'>
                                                <div className='font-bold !text-sm'>{item?.name}</div>
                                            </div>
                                        </div>
                                        <div className='text-xl'>
                                            {pageID == item?.id
                                                ? <BsCheckCircleFill className='text-primary-600' />
                                                : <BsCircle className='text-gray-200 dark:text-gray-600' />
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-end gap-2 p-4 -m-6 w-auto mt-10 border-t border-gray-200 dark:border-gray-700'>
                        <Button onClick={handleOnRequestClose}><Trans i18nKey={`instagramIntegration.cancel`}>Cancel</Trans></Button>
                        <Button loading={isSubmitting} disabled={!pageID} variant="solid" onClick={handleSubmit} icon={<img src="/img/socials/instagram.svg" className="!h-4 !w-4 shrink-0" />}>&nbsp; <Trans i18nKey={`instagramIntegration.connectInstagramAccount`}>Connect Instagram Account</Trans></Button>
                    </div>
                </>
            }
        </Dialog>
    )
}

export default InstagramPageSelectModal

import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTrendingNews } from "utils/hooks/useTrendingNews";
import { CompanyContext } from "./CompanyContext";
import { SessionContext } from "./SessionContext";

const TrendingNewsContext = React.createContext({
    trendingNews: [],
    isLoadingTrendingNews: false
});

const TrendingNewsProvider = ({ children }) => {
    
    const { company } = useContext(CompanyContext);
    const { getTrendingNews } = useTrendingNews();
    const [ trendingNews, setTrendingNews ] = useState([]);
    const [ isLoadingTrendingNews, setIsLoadingTrendingNews ] = useState(true);
    
    // useEffect(() => {
    //     if (company) {
    //         if (
    //             localStorage.getItem(`trendingNews${company?.id}${new Date().toDateString()}`) && 
    //             localStorage.getItem(`trendingNews${company?.id}${new Date().toDateString()}`)?.length > 2
    //         ) {
    //             setTrendingNews(JSON.parse(localStorage.getItem(`trendingNews${company?.id}${new Date().toDateString()}`)));
    //             setIsLoadingTrendingNews(false);
    //         } else {
    //             getTrendingNews().then((articles) => {
    //                 setTrendingNews(articles);
    //                 setIsLoadingTrendingNews(false);
    //             })
    //         }
    //     }
    // }, [company?.id]);

    // useEffect(() => {
    //     if (trendingNews && company?.id) {
    //         Object.entries(localStorage).map(x => x[0]).filter(x => x.includes(`trendingNews${company?.id}`)).map(
    //             x => localStorage.removeItem(x)
    //         );
    //         localStorage.setItem(`trendingNews${company?.id}${new Date().toDateString()}`, JSON.stringify(trendingNews));
    //     }
    // }, [trendingNews, company?.id]);
    
    return (
        <TrendingNewsContext.Provider value={{ 
            trendingNews: trendingNews,
            isLoadingTrendingNews: isLoadingTrendingNews
        }}>
            {children}
        </TrendingNewsContext.Provider>
    );
};

export { TrendingNewsContext, TrendingNewsProvider };
import { CompanyContext } from "contexts/CompanyContext";
import { MediaContext } from "contexts/MediaContext";
import { SessionContext } from "contexts/SessionContext"
import { UploadContext } from "contexts/UploadContext";
import { useContext } from "react"
import Api from "services/Api";

export const useEditorCallback = () => {

  const { user } = useContext(SessionContext);
  const { company } = useContext(CompanyContext);
  const { addFilesAsURLUploadModal } = useContext(UploadContext);

  const onPublish = async (data, asset) => {
    addFilesAsURLUploadModal(data?.url, data?.id, asset);
  }

  return { onPublish }
}

export default useEditorCallback;
import { checkIfIsExpired } from "./checkIfIsExpired";
import { checkIfIsIntegrated } from "./checkIfIsIntegrated"

export const getSocialNetworkIntegration = (network, companySocials) => {
    let integration = checkIfIsIntegrated(network, companySocials);
    if (integration) {
        if (!checkIfIsExpired(integration)) {
            return integration;
        }
    }
    return null;
}
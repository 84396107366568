import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'; // Import the Trans component for i18n translations

import { socialNetworks } from 'views/calendar/SocialNetworkSidePanel';
import { components } from 'react-select'
import capitalize from 'components/ui/utils/capitalize';
import { Field, Formik, Form } from 'formik';
import { CompanyContext } from 'contexts/CompanyContext';
import acronym from 'utils/acronym';
import { Avatar, Button, Drawer, Select } from 'components/ui';
import DatePickerRange from 'components/ui/DatePicker/DatePickerRange';
import InputModern from 'components/ui/Input/InputModern';
import SwitcherModern from 'components/ui/SwitcherModern';
import { MediaContext } from 'contexts/MediaContext';
import { HiCheck, HiOutlineFilter } from 'react-icons/hi';

const { Control, MultiValueLabel } = components

const CustomSelectOption = ({ innerProps, label, data, isSelected, test }) => {
  return (
    <div
      className={`flex items-center justify-between p-2 cursor-pointer ${
        isSelected
          ? 'bg-gray-100 dark:bg-gray-500'
          : 'hover:bg-gray-50 dark:hover:bg-gray-600'
      }`}
      {...innerProps}
    >
      <div className="flex items-center">
        {data?.image && <img src={data.image} className='w-[20px]' />}
        {data?.emoji && <span className='text-xl'>{data.emoji}</span>}
        <span className="ml-3 rtl:mr-2">{data.label}</span>
      </div>
      {isSelected && <HiCheck className="text-emerald-500 text-xl" />}
    </div>
  )
}

export const CustomSelectOptionUser = ({ innerProps, label, data, isSelected, test }) => {
  return (
    <div
      className={`flex items-center justify-between p-2 cursor-pointer ${
        isSelected
          ? 'bg-gray-100 dark:bg-gray-500'
          : 'hover:bg-gray-50 dark:hover:bg-gray-600'
      }`}
      {...innerProps}
    >
      <div className="flex items-center">
        <Avatar
          className={`${data?.image ? '' : `bg-primary-800`}`}
          src={data?.image}
          shape="circle"
        >
          {acronym(data?.label)}
        </Avatar>
        <span className="ml-3 rtl:mr-2">{data.label}</span>
      </div>
      {isSelected && <HiCheck className="text-emerald-500 text-xl" />}
    </div>
  )
}

export const CustomControlMulti = ({ children, data, ...props }) => {
  return (
    <MultiValueLabel {...props}>
      <div className="inline-flex items-center">
        {data?.image && <img src={data?.image} className='w-[20px]' />}
        {data?.emoji && <span className='text-xl'>{data?.emoji}</span>}
        <span className='ml-2 capitalize'>
          {data?.value}
        </span>
        {children}
      </div>
    </MultiValueLabel>
  )
}

const MediaFilters = () => {

  const { filtersMyMedia, setFiltersMyMedia, filtersMyMediaDrawerOpened, setFiltersMyMediaDrawerOpened } = useContext(MediaContext);
  const { companyUsers } = useContext(CompanyContext);

  const { t } = useTranslation();

  const socialNetworksOptions = socialNetworks.map(e => { return { value: e, label: capitalize(e), image: `/img/socials/${e}.svg` }; });
  const companyUsersOptions = companyUsers?.map(e => { return { value: e.id, label: capitalize(e.name), image: e?.image?.url }; });
  const mediaTypesOptions = [{ value: 'image', label: 'Images' }, { value: 'video', label: 'Videos' }];

  return (
    <Formik
      initialValues={filtersMyMedia}
      enableReinitialize
      onSubmit={(values) => {
        if (values?.unused_assets == false) delete values.unused_assets;
        setFiltersMyMedia(values);
        setFiltersMyMediaDrawerOpened(false);
      }}
    >
      {({ values, touched, errors, isSubmitting, setSubmitting, resetForm, submitForm }) => {
        return (
          <Drawer
            title={<Trans i18nKey="mediaFilters.title">🗂&nbsp; Filter Media</Trans>}
            isOpen={filtersMyMediaDrawerOpened}
            onClose={() => setFiltersMyMediaDrawerOpened(false)}
            onRequestClose={() => setFiltersMyMediaDrawerOpened(false)}
            overlayClassName='z-[100000000]'
            footer={
              <div className='flex justify-between items-center gap-4 w-full'>
                <Button type="button" className="flex-1 !px-5" onClick={() => { setFiltersMyMedia({}); setFiltersMyMediaDrawerOpened(false) }}>
                  <Trans i18nKey="mediaFilters.resetFilters">Reset filters</Trans>
                </Button>
                <Button type="submit" onClick={() => submitForm()} className="flex-1 !px-5" variant="solid" icon={<HiOutlineFilter />}>&nbsp;
                  <Trans i18nKey="mediaFilters.filter">Filter</Trans>
                </Button>
              </div>
            }
          >
            <Form>
              <div className='flex flex-col gap-8 flex-1'>
                <div className='w-auto mb-0 flex gap-0 items-center overflow-hidden rounded-xl relative'>
                  <Field name="query">
                    {({ field, form }) => (
                      <InputModern
                        label={<Trans i18nKey="mediaFilters.searchLabel">Search</Trans>}
                        size="sm"
                        value={values?.query}
                        placeholder={t("mediaFilters.searchPlaceholder")}
                        onChange={(e) => {
                          form.setFieldValue(
                            field.name,
                            e.target.value
                          );
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className=''>
                  <Field name="create_date_range">
                    {({ field, form }) => (
                      <DatePickerRange
                        field={field}
                        form={form}
                        label={<Trans i18nKey="mediaFilters.creationDateLabel">Creation Date</Trans>}
                        placeholder={t("mediaFilters.selectDates")}
                        value={values?.create_date_range}
                        onChange={(option) => {
                          form.setFieldValue(
                            field.name,
                            option.map(item => item)
                          );
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className=''>
                  <Field name="unused_assets">
                    {({ field, form }) => (
                      <SwitcherModern
                        field={field}
                        form={form}
                        label={<Trans i18nKey="mediaFilters.unusedAssetsLabel">Unsed Assets</Trans>}
                        onChange={(value) => {
                          form.setFieldValue(
                            field.name,
                            value
                          );
                        }}
                        value={true}
                        checked={values?.unused_assets}
                        description={<Trans i18nKey="mediaFilters.onlyUnusedAssets">Only unused assets</Trans>}
                      />
                    )}
                  </Field>
                </div>

                <div className=''>
                  <Field name="social_networks">
                    {({ field, form }) => (
                      <Select
                        isMulti
                        field={field}
                        form={form}
                        label={<Trans i18nKey="mediaFilters.socialNetworksLabel">Social Networks</Trans>}
                        options={socialNetworksOptions}
                        components={{
                          Option: CustomSelectOption,
                          Control: CustomControlMulti,
                        }}
                        onChange={(option) => {
                          form.setFieldValue(
                            field.name,
                            option.map(item => item)
                          );
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className=''>
                  <Field name="users">
                    {({ field, form }) => (
                      <Select
                        isMulti
                        field={field}
                        form={form}
                        label={<Trans i18nKey="mediaFilters.usersLabel">Users</Trans>}
                        options={companyUsersOptions}
                        components={{
                          Option: CustomSelectOptionUser,
                          Control: CustomControlMulti,
                        }}
                        onChange={(option) => {
                          form.setFieldValue(
                            field.name,
                            option.map(item => item)
                          );
                        }}
                      />
                    )}
                  </Field>
                </div>

                <div className=''>
                  <Field name="media_types">
                    {({ field, form }) => (
                      <Select
                        isMulti
                        field={field}
                        form={form}
                        label={<Trans i18nKey="mediaFilters.mediaTypesLabel">Media Types</Trans>}
                        options={mediaTypesOptions}
                        components={{
                          Option: CustomSelectOption,
                          Control: CustomControlMulti,
                        }}
                        onChange={(option) => {
                          form.setFieldValue(
                            field.name,
                            option.map(item => item)
                          );
                        }}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </Form>
          </Drawer>
        )
      }}
    </Formik>
  )
}

export default MediaFilters;

import React from 'react'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'

const BYTE = 1000
export const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({ children }) => {
    return <span className="text-4xl">{children}</span>
}

const FileItem = (props) => {
    const { file, children } = props
    const { type, name, size } = file

    const renderThumbnail = () => {
        const isImageFile = type.split('/')[0] === 'image'

        if (isImageFile) {
            return (
                <img
                    className="upload-file-image aspect-square object-cover w-full h-full"
                    src={URL.createObjectURL(file)}
                    alt={`file preview ${name}`}
                />
            )
        }

        if (type === 'application/zip') {
            return (
                <FileIcon>
                    <VscFileZip />
                </FileIcon>
            )
        }

        if (type === 'application/pdf') {
            return (
                <FileIcon>
                    <VscFilePdf />
                </FileIcon>
            )
        }

        return (
            <FileIcon>
                <VscFile />
            </FileIcon>
        )
    }

    return (
        <div className="upload-file shadow !border-0 flex items-center w-full gap-4 bg-gray-50 dark:bg-gray-700 overflow-hidden">
            <div className="flex flex-col flex-1 overflow-hidden w-full">
                <div className="relative upload-file-thumbnail shrink-0 w-full flex items-center justify-center bg-gray-200 dark:bg-gray-700 !h-auto !aspect-square !p-0">
                    {renderThumbnail()}
                    <div className='bg-gradient-to-b from-transparent via-transparent to-[#00000044] absolute left-0 top-0 w-full h-full pointer-events-none p-2 flex items-end justify-end'>
                        {children}
                    </div>
                </div>
                <div className="upload-file-info !gap-0 w-full overflow-hidden">
                    <h6 className="w-full upload-file-name !text-sm overflow-hidden ellipsis text-ellipsis text-overflow-ellipsis overflow-ellipsis !text-xs">
                        {name}
                    </h6>
                    <span className="upload-file-size !text-xs text-gray-600 dark:text-gray-400 !font-normal">{getKB(size)} kb</span>
                </div>
            </div>
        </div>
    )
}

export default FileItem

import React, { forwardRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from '../Spinner'
import { useConfig } from '../ConfigProvider'

const SwitcherModern = forwardRef((props, ref) => {
    const {
        checked,
        checkedContent,
        className,
        color,
        defaultChecked,
        disabled,
        isLoading,
        labelRef,
        name,
        onChange,
        readOnly,
        unCheckedContent,
        field,
        label,
        description,
        ...rest
    } = props

    const { themeColor, primaryColorLevel } = useConfig()

    const [switcherChecked, setSwitcherChecked] = useState(
        defaultChecked || checked
    )

    useEffect(() => {
        if (typeof checked !== 'undefined') {
            setSwitcherChecked(checked)
        }
    }, [checked])

    const getControlProps = () => {
        let checkedValue = switcherChecked

        let checked = { value: checkedValue }

        if (field) {
            checkedValue =
                typeof field.value === 'boolean' ? field.value : defaultChecked
            checked = { value: checkedValue, checked: checkedValue }
        }

        if (defaultChecked) {
            checked.defaultChecked = defaultChecked
        }
        return checked
    }

    const controlProps = getControlProps()

    const handleChange = (e) => {
        const nextChecked = !switcherChecked

        if (disabled || readOnly || isLoading) {
            return
        }

        if (typeof checked === 'undefined') {
            setSwitcherChecked(nextChecked)
            onChange?.(nextChecked, e)
        } else {
            onChange?.(switcherChecked, e)
        }
    }

    const switcherColor = color || `primary-600`

    const switcherClass = classNames(
        'switcher',
        (switcherChecked || controlProps.checked) &&
            `switcher-checked bg-${switcherColor} dark:bg-${switcherColor}`,
        disabled && 'switcher-disabled',
        className
    )

    return ( 
        <fieldset className={`relative w-full rounded-2xl border border-gray/20 dark:border-gray-600 outline-none transition focus-within:!border-primary-600 transition-all py-4 !pt-3`} onClick={handleChange}>
            {label && <legend className='text-xs ml-6 lg:text-xs px-2 font-semibold text-gray/20 dark:text-gray-400 capitalize dark:text-white'>{label}</legend>}
            <div className='flex w-full items-center gap-3 px-4'>

                <label ref={labelRef} className={switcherClass}>
                    <input
                        ref={ref}
                        type="checkbox"
                        disabled={disabled}
                        readOnly={readOnly}
                        onChange={handleChange}
                        name={name}
                        {...controlProps}
                        {...field}
                        {...rest}
                    />
                    {isLoading ? (
                        <Spinner
                            className={classNames(
                                'switcher-toggle-loading',
                                switcherChecked
                                    ? 'switcher-checked-loading'
                                    : 'switcher-uncheck-loading'
                            )}
                        />
                    ) : (
                        <div className="switcher-toggle" />
                    )}
                    <span className="switcher-content">
                        {switcherChecked ? checkedContent : unCheckedContent}
                    </span>
                </label>
                <span className='text-sm !text-gray-400 cursor-pointer'>{description}</span>
            </div>
        </fieldset>
    )
})

SwitcherModern.defaultProps = {
    isLoading: false,
}

SwitcherModern.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    isLoading: PropTypes.bool,
    color: PropTypes.string,
    onChange: PropTypes.func,
    checkedContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    unCheckedContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    labelRef: PropTypes.string,
}

export default SwitcherModern

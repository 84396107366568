import React, { ReactNode, useContext, useEffect, useState } from "react";
import { CompanyContext } from "./CompanyContext";
import { useSocialNetworks } from "utils/hooks/socials/useSocialNetworks";
import { CalendarActiveSocialNetworkContext } from "./CalendarActiveSocialNetworkContext";

const PostContext = React.createContext({
    posts: [],
    isLoadingPosts: false,
    updatePosts: () => void 0
});

const PostProvider = ({ children }) => {

    const { company, companySocials } = useContext(CompanyContext);
    const { calendarActiveSocialNetwork } = useContext(CalendarActiveSocialNetworkContext);
    const { getPosts } = useSocialNetworks();
    const [ posts, setPosts ] = useState();
    const [ isLoadingPosts, setIsLoadingPosts ] = useState();
    
    const updatePosts = async () => {
        if (company && companySocials && companySocials?.length && company?.id == companySocials?.[0]?.company_id) {
            setIsLoadingPosts(true);
            try {
                let _posts = await getPosts().then((_posts) => { return _posts });
                _posts = _posts.sort((a,b) => new Date(b.created_at) - new Date(a.created_at));
                setPosts(_posts);
            } catch (err) {
                console.error(err);
            }
            setIsLoadingPosts(false);
        } else {
            setPosts([]);
        }
    }

    useEffect(() => {
        updatePosts();
    }, [companySocials?.[0]?.id]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         updatePosts();
    //     }, 2 * 60 * 1000);
    //     return () => clearInterval(interval)
    // }, [companySocials?.[0]?.id]);

    return (
        <PostContext.Provider value={{ 
            posts,
            isLoadingPosts,
            updatePosts
        }}>
            {children}
        </PostContext.Provider>
    );
};

export { PostContext, PostProvider };
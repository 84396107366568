import React from 'react'
import { HiOutlineX, HiX } from 'react-icons/hi'
import classNames from 'classnames'
import Tooltip from '../Tooltip'

const CloseButton = React.forwardRef((props, ref) => {
    const { absolute, className, defaultStyle, svgClass, ...rest } = props
    const closeButtonAbsoluteClass = 'absolute z-10'

    const closeButtonClass = classNames(
        'close-btn',
        defaultStyle && 'close-btn-default',
        absolute && closeButtonAbsoluteClass,
        className
    )

    return (
        <span className={closeButtonClass} role="button" {...rest} ref={ref}>
            <Tooltip title="Close dialog" placement='left' className='z-[1000000]' wrapperClass='relative z-[1000000000] font-[200]'>×</Tooltip>
        </span>
    )
})

CloseButton.defaultProps = {
    defaultStyle: true,
}

export default CloseButton

import { PLACEMENT } from "components/ui/utils/constant";
import { HiCheckCircle } from "react-icons/hi";
import { aiImageStyles } from "views/company_settings/components/AIImages";

const { Dropdown, Tooltip, Checkbox } = require("components/ui");

const AIImageDropdownStyles = ({
    style,
    setStyle,
    className = "",
    parentClassName = ""
}) => {

    return (
        <Dropdown
            menuClass="max-h-[290px] w-[240px] overflow-y-auto !rounded-2xl !border-0 !p-0"
            placement={PLACEMENT.BOTTOM_START}
            parentClassName={parentClassName}
            renderTitle={
                <div className={`cursor-pointer !pl-5 overflow-hidden whitespace-nowrap !px-10 !py-3 !h-auto bg-gray-50 dark:bg-gray-600 rounded-2xl flex items-center font-semibold ${className}`}>
                    {aiImageStyles?.find(e => e.style == style)?.style || `Any Image Style`}
                </div>
            }
        >
            <Dropdown.Item className="w-full grid grid-cols-2 gap-2.5 p-2.5">
                {aiImageStyles?.map((item) => (
                    <div key={item?.style} onClick={() => setStyle(item?.style)} className="cursor-pointer flex flex-col gap-1 items-center justify-center">
                        <div className={`group relative overflow-hidden rounded-2xl transition-all shadow-sm hover:shadow-2xl ${style == item?.style && `outline outline-4 outline-primary-600`}`}>
                            <img 
                                src={item?.image}
                                className="w-full transition-all"
                            />
                        </div>
                        <Tooltip wrapperClass="w-full" title={item?.description}>
                            <span className="font-normal text-gray-900 dark:text-gray-100 text-ellipsis whitespace-nowrap block overflow-hidden w-full text-center text-[10px]">{item?.style}</span>
                        </Tooltip>
                    </div>
                ))}
            </Dropdown.Item>
        </Dropdown>
    );
}

export default AIImageDropdownStyles;
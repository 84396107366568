"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterBrowserEventsHandler = void 0;
const register_events_handler_base_1 = require("./register-events-handler.base");
class RegisterBrowserEventsHandler extends register_events_handler_base_1.RegisterEventsHandlerBase {
    constructor(commands, window) {
        super(commands, window);
        this.commands = commands;
        this.window = window;
    }
    init() {
        if (!this.window.document.addEventListener) {
            return;
        }
        const options = this.supportsPassiveEvents() ?
            { passive: true, capture: true } :
            true;
        this.window.addEventListener('load', () => {
            this.addJsEventLogEntry({
                event: 'PageLoaded',
                performance: this.window.performance
            });
        }, options);
        this.window.addEventListener('DOMContentLoaded', () => {
            this.addJsEventLogEntry({
                event: 'DOMContentLoaded',
            });
        }, options);
        this.window.addEventListener('popstate', (e) => {
            this.addJsEventLogEntry({
                event: 'popstate',
                location: this.window.location.href,
                state: JSON.stringify(e.state),
            });
        }, options);
        this.window.addEventListener('hashchange', () => {
            this.addJsEventLogEntry({
                event: 'hashchange',
                hash: this.window.location.hash,
            });
        }, options);
        const historyOverrideFnFactory = (originalFn) => {
            const self = this;
            return function (...args) {
                const url = args.length > 2 ? args[2] : undefined;
                if (url) {
                    self.addJsEventLogEntry({
                        event: 'pushstate',
                        to: String(url),
                        state: args.length > 1 ? JSON.stringify(args[1]) : null
                    });
                }
                return originalFn.apply(this, args);
            };
        };
        this.override(this.window.history, 'pushState', historyOverrideFnFactory);
        this.override(this.window.history, 'replaceState', historyOverrideFnFactory);
    }
    override(object, method, overrideFactory) {
        if (!(method in object)) {
            return;
        }
        const original = object[method];
        const wrapped = overrideFactory(original);
        object[method] = wrapped;
    }
}
exports.RegisterBrowserEventsHandler = RegisterBrowserEventsHandler;

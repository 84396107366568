import Api from "services/Api";

import React, { useContext } from 'react'
import { CompanyContext } from "contexts/CompanyContext";

export default function useDbCreativeTemplates() {

    const { company } = useContext(CompanyContext);

    const getRandomTemplate = async (orientations = ["square"]) => {
        const vista_template_ids = company?.vistaTemplates?.map(e => e.id);
        let template = await Api.get(`/vistaTemplates?filter[orientation]=${orientations.join(",")}&filter[active]=1&sort=random${vista_template_ids?.length ? `&filter[id]=${vista_template_ids.join(",")}` : ``}`).then(({ data }) => data?.[0]);
        return template;
    }
    
    const getTemplateById = async (external_id = "") => {
        const template = await Api.get(`/vistaTemplates?filter[external_id]=${external_id}`).then(({ data }) => data[0]);
        return template;
    }
    
    const getTemplates = async () => {
        const template = await Api.get(`/vistaTemplates?filter[active]=1`).then(({ data }) => data);
        return template;
    }

    return {
        getRandomTemplate,
        getTemplateById,
        getTemplates
    } 
}




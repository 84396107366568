"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreInitProvider = void 0;
const browser_1 = require("../browser");
const call_info_factory_1 = require("../call-info-factory");
const commands_queue_1 = require("../commands-queue");
const api_feature_detection_1 = require("../feature-detection/api-feature-detection");
const operation_modes_1 = require("../feature-detection/operation-modes");
const sdk_options_validator_1 = require("../sdk-options-validator");
const timer_1 = require("../timer");
const user_feedback_1 = require("../user-feedback");
const utils_1 = require("../utils");
const uuid_factory_1 = require("../uuid-factory");
const cache = new Map();
const Cached = (0, utils_1.CacheDecoratorFactory)(cache);
class PreInitProvider {
    constructor(window) {
        this.window = window;
    }
    provideAPIFeatureDetection() {
        return new api_feature_detection_1.APIFeatureDetection(this.window.indexedDB, this.window.localStorage, this.window.sessionStorage, this.window.BroadcastChannel, this.window.Worker);
    }
    provideBrowser() {
        return new browser_1.Browser(this.window);
    }
    provideCommandsQueue() {
        return new commands_queue_1.CommandsQueue(new call_info_factory_1.CallInfoFactory(), new timer_1.Timer(), this.provideUUIDFactory());
    }
    provideOperationModes() {
        return new operation_modes_1.OperationModes(this.provideAPIFeatureDetection());
    }
    provideSDKOptionsValidator() {
        return new sdk_options_validator_1.SDKOptionsValidator(this.provideBrowser());
    }
    provideUserFeedback() {
        return new user_feedback_1.UserFeedback(this.provideCommandsQueue());
    }
    provideUUIDFactory() {
        return new uuid_factory_1.UUIDFactory();
    }
    provideWindow() {
        return this.window;
    }
}
__decorate([
    Cached(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", api_feature_detection_1.APIFeatureDetection)
], PreInitProvider.prototype, "provideAPIFeatureDetection", null);
__decorate([
    Cached(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Object)
], PreInitProvider.prototype, "provideBrowser", null);
__decorate([
    Cached(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", commands_queue_1.CommandsQueue)
], PreInitProvider.prototype, "provideCommandsQueue", null);
__decorate([
    Cached(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", operation_modes_1.OperationModes)
], PreInitProvider.prototype, "provideOperationModes", null);
__decorate([
    Cached(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", sdk_options_validator_1.SDKOptionsValidator)
], PreInitProvider.prototype, "provideSDKOptionsValidator", null);
__decorate([
    Cached(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", user_feedback_1.UserFeedback)
], PreInitProvider.prototype, "provideUserFeedback", null);
__decorate([
    Cached(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Object)
], PreInitProvider.prototype, "provideUUIDFactory", null);
exports.PreInitProvider = PreInitProvider;

import React, { ReactNode, useContext, useEffect, useState } from "react";
import { CompanyContext } from "./CompanyContext";
import { useSocialNetworks } from "utils/hooks/socials/useSocialNetworks";
import { CalendarActiveSocialNetworkContext } from "./CalendarActiveSocialNetworkContext";
import Api from "services/Api";
import { Notification, toast } from "components/ui";

const ScheduledPostContext = React.createContext({
    scheduledPosts: [],
    isLoadingScheduledPosts: false,
    triggerUpdatePostGroups: undefined,
    triggerUpdate: () => void 0,
    updateScheduledPosts: () => void 0,
    getScheduledPostGroup: (post_group_id) => void 0,
    modifyScheduledPost: (post_id, obj) => void 0,
    findScheduledPostByID: (post_id, postGroups) => void 0,
    getScheduledPostGroups: (filters = {}, sort = "-publish_at", getAll = false, returnLinks = false) => void 0,
    setScheduledPostGroupFieldValue: (post_group_id, field, value) => void 0
});

const ScheduledPostProvider = ({ children }) => {

    const { company } = useContext(CompanyContext);
    const { calendarActiveSocialNetwork } = useContext(CalendarActiveSocialNetworkContext);
    const [ scheduledPosts, setScheduledPosts ] = useState();
    const [ isLoadingScheduledPosts, setIsLoadingScheduledPosts ] = useState();
    const [ triggerUpdatePostGroups, setTriggerUpdatePostGroups ] = useState();

    const setScheduledPostGroupFieldValue = (post_group_id, field, value) => {
        let _scheduledPosts = [...(scheduledPosts || [])];
        let _index = _scheduledPosts.findIndex(e => e?.id == post_group_id);
        if (_index >= 0) {
            _scheduledPosts[_index] = {
                ..._scheduledPosts[_index],
                [field]: value
            }
            setScheduledPosts(_scheduledPosts);
        }
    }

    const getScheduledPostGroup = async (post_group_id) => {
        return await Api.get(`/postGroups/${post_group_id}?include=ruleGroup,tags,posts,posts.assets,posts.assets.image,posts.assets.image.thumbnail,posts.assets.video,posts.assets.video.thumbnail,posts.cover,user,user.image&sort=-publish_at`, {}).then(({ data: post_group }) => {
            return post_group
        });
    }

    const getScheduledPostGroups = async (filters = {}, sort = "-publish_at", getAll = false, returnLinks = false) => {
        let filterArray = [];
        Object.keys(filters).map((key) => {
            filterArray.push(`filter[${key}]=${filters?.[key]}`);
        });
        let postGroups = [];
        let links;
        do {
            let { _postGroups, _links } = await Api.get(links?.next || `/companies/${company?.id}/postGroups?${getAll ? `paginate=false&` : ``}include=ruleGroup,tags,posts,posts.assets,posts.assets.image,posts.assets.image.thumbnail,posts.assets.video,posts.assets.video.thumbnail,posts.cover,user,user.image&sort=${sort}&${filterArray.join("&")}`, {}).then(({ data, links }) => { return { _postGroups: data, _links: links } });
            links = _links;
            postGroups = [...postGroups, ..._postGroups];
        } while (getAll ? !!links?.next : false);

        if (returnLinks) {
            return { postGroups, links }
        }

        return postGroups;
    }
    
    const triggerUpdate = () => {
        setTriggerUpdatePostGroups(Math.random());
    }
    
    const updateScheduledPosts = async () => {
        if (company?.id) {
            setIsLoadingScheduledPosts(true);
            try {
                await getScheduledPostGroups(undefined, undefined, true).then((_posts) => {
                    // if (JSON.stringify(_posts?.map((e) => { return {...e, user: { ...e.user, image: undefined }} })) != JSON.stringify(scheduledPosts?.map((e) => { return {...e, user: { ...e.user, image: undefined }} }))) {
                    //     sendStatusChangeNotifications(scheduledPosts, _posts);
                    // }
                    setScheduledPosts(_posts);
                });
            } catch (err) {}
            setIsLoadingScheduledPosts(false);
        }
    }

    const findScheduledPostByID = (post_id, _posts = scheduledPosts) => {
        let returnPost;
        _posts.map((postGroup) => {
            postGroup?.posts?.map((post) => {
                if (post?.id == post_id) {
                    returnPost = post;
                }
            });
        });
        return returnPost;
    }

    const modifyScheduledPost = (post_id, obj) => {
        const _scheduledPosts = scheduledPosts.map((postGroup) => {
            return postGroup?.posts?.map((post) => {
                if (post?.id == post_id) {
                    post = {...post, ...obj};
                }
                return post;
            })
        });
        setScheduledPosts(_scheduledPosts);
        return _scheduledPosts;
    }

    const sendStatusChangeNotifications = (oldPosts, newPosts) => {
        oldPosts?.map((oldPostGroup) => {
            oldPostGroup?.posts?.map((_oldPost) => {
                const _newPost = findScheduledPostByID(_oldPost?.id, newPosts);
                if (_oldPost?.status != "error" && _newPost?.status == "error") {
                    toast.push(<Notification type="danger" title={`There was an error while publishing the post: ${_newPost.status_message}`} />);
                }
                if (_oldPost?.status != "published" && _newPost?.status == "published") {
                    toast.push(<Notification type="success" title={`The post was published successfully`} />);
                }
            })
        })
    }

    useEffect(() => {
        updateScheduledPosts();
    }, [company?.id, triggerUpdatePostGroups]);

    return (
        <ScheduledPostContext.Provider value={{ 
            scheduledPosts: scheduledPosts,
            isLoadingScheduledPosts: isLoadingScheduledPosts,
            updateScheduledPosts: updateScheduledPosts,
            getScheduledPostGroup: getScheduledPostGroup,
            modifyScheduledPost: modifyScheduledPost,
            findScheduledPostByID: findScheduledPostByID,
            getScheduledPostGroups: getScheduledPostGroups,
            triggerUpdatePostGroups: triggerUpdatePostGroups, 
            setScheduledPostGroupFieldValue: setScheduledPostGroupFieldValue,
            triggerUpdate: triggerUpdate
        }}>
            {children}
        </ScheduledPostContext.Provider>
    );
};

export { ScheduledPostContext, ScheduledPostProvider };
import React, { useState, useContext, useEffect } from 'react'
import { CompanyContext } from 'contexts/CompanyContext'
import { SessionContext } from 'contexts/SessionContext'
import FormDesription from './FormDesription';
import { Trans } from 'react-i18next';
import { socialNetworks } from 'views/calendar/SocialNetworkSidePanel';
import { Button } from 'components/ui';
import { LuCog, LuPlusCircle } from 'react-icons/lu';
import PromptItem from './PromptItem';
import Api from 'services/Api';
import { ModalContext } from 'contexts/ModalContext';
import { TbSettings } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { CompanyPromptsContext } from 'contexts/CompanyPromptsContext';
import { postTypesOptions } from 'utils/hooks/useConfigPanel';

export const newPromptStart = `Write a...`

const Prompts = ({
    network = undefined,
    selectable = false,
    onSelect = (prompt) => void 0,
    selected = [],
    isModal = false
}) => {

    const { company, setCompany } = useContext(CompanyContext);
    const { updateUserData } = useContext(SessionContext);
    const { prompts: _prompts } = useContext(CompanyPromptsContext);
    const [ prompts, setPrompts ] = useState(_prompts);
    const { closeModal } = useContext(ModalContext);
    const navigate = useNavigate();

    const handleOnSave = async (prompts) => {
        let _company = company;
        _company = {..._company, social_network_prompts: prompts };
        setCompany(_company);
        await Api.put(`/companies/${company?.id}`, {}, { social_network_prompts: prompts });
    }

    const onSetDefault = (network, index) => {
        let _prompts = prompts;
        _prompts[index].default = !!!_prompts[index].default;
        setPrompts(_prompts);
        handleOnSave(_prompts);
    }

    const onChange = (value, index) => {
        let _prompts = prompts;
        if (_prompts[index].prompt == value) return;
        _prompts[index].prompt = value;
        setPrompts(_prompts);
        handleOnSave(_prompts);
    }
    
    const onDelete = (index) => {
        let _prompts = prompts.filter((e,i) => i != index);
        setPrompts(_prompts);
        handleOnSave(_prompts);
    }

    const onAddPrompt = (network) => {
        setPrompts([...prompts, {
            type: network,
            default: false,
            prompt: newPromptStart
        }])
    }
    
    useEffect(() => {
        setPrompts(_prompts);
    }, [_prompts]);

    return (<>
        <div className="w-full flex flex-col gap-12">
            {!selectable && <FormDesription
                className="flex-1"
                title={<Trans i18nKey={`prompts.prompts`}>Social Network Prompts</Trans>}
                desc={<Trans i18nKey={`prompts.promptsDescription`}>Here you can add, edit and delete the prompts that you will use in the post generator tools. This way you can fully customize the results as per {company?.name}'s needs.</Trans>}
            />}
            {postTypesOptions.map(({ value: socialNetworkType }) => (
                <div className={`flex flex-col gap-4 ${network !== undefined && socialNetworkType != network && `hidden`}`}>
                    <div className='flex justify-between items-center !pl-2'>
                        <div className='flex items-center gap-3'>
                            <img src={`/img/socials/${socialNetworkType?.split("_")?.[0]}.svg`} className='w-7 shrink-0' />
                            <h6 className='capitalize shrink-0'>{socialNetworkType?.replaceAll("_", " ")}</h6>
                        </div>
                        <Button 
                            className="!px-4 !py-2 !h-auto !rounded-xl"
                            variant="solid"
                            icon={<LuPlusCircle />}
                            onClick={() => onAddPrompt(socialNetworkType)}
                        >
                            New prompt
                        </Button>
                    </div>
                    <div className='flex flex-col-reverse gap-4'>
                        {prompts?.map((e, i) => {
                            if (e?.type != socialNetworkType) return <></>;
                            return <PromptItem 
                                promptObj={e} 
                                prompts={prompts}
                                onChange={(value) => onChange(value, i)}
                                onSetDefault={() => onSetDefault(socialNetworkType, i)}
                                onDelete={() => onDelete(i)}
                                selectable={selectable}
                                selected={!!selected.includes(i)}
                                onSelect={() => onSelect(i)}
                            />
                        })}
                    </div>
                </div>
            ))}
            {isModal && <>
                <div className='text-left text-xs -mt-7 px-2'>
                    Click on the prompts to select or unselect them. You can choose one prompt or multiple prompts for a wide variety of results. You can also create any prompts you like. If no prompts are selected, Followr will randomly pick one for you.
                </div>
                <div className='flex justify-between -mt-6'>
                    <Button
                        onClick={() => { closeModal(); navigate(`/company-settings/prompts`) }}
                        icon={<TbSettings />}
                    >
                        See all Prompt Settings
                    </Button>
                    <Button
                        variant="twoTone"
                        onClick={() => closeModal()}
                    >
                        Confirm
                    </Button>
                </div>
            </>}
        </div>
    </>)
}

export default Prompts

import { Loading } from "components/shared";
import { Button } from "components/ui";
import InputModern from "components/ui/Input/InputModern";
import { AIContext } from "contexts/AIContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { HiOutlineCpuChip } from "react-icons/hi2";


import usePrompts from "utils/hooks/usePrompts";

const FixSpellingOrGrammar = ({ value, fullText, selectedText, selectionStartEnd, setValue, setMenuOpened }) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const { getByQueryChat } = useContext(AIContext);
    let chunkLength = 0;

    useEffect(() => {
        setIsLoading(true);
        const query = `
            Fix the spelling or grammar of this text: ${selectedText ? selectedText : fullText}\n
            If it's correct, then anwer the same text.

            Corrected text: 
        `;
        getByQueryChat(query).then((response) => {
            const initialLeftPart = selectedText ? String(value)?.substring(0, selectionStartEnd.start) : "";
            const initialRightPart = selectedText ? String(value)?.substring(selectionStartEnd.finish, String(value)?.length) : "";       
            setValue(`${initialLeftPart}${response}${initialRightPart}`);
        }).finally(() => {
            setIsLoading(false);
            setMenuOpened(false);
        })
    }, []);

    return (
        <div className="flex items-center justify-center p-4 gap-2">
            <Loading type={'element'} /> 
        </div>
    )
}

export default FixSpellingOrGrammar;
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getElByXPath = exports.getXPath = exports.DebounceByElement = exports.UnreachableCaseError = exports.CacheDecoratorFactory = exports.createDeferred = void 0;
function createDeferred() {
    const deferred = {
        isFulfilled: false,
        isRejected: false,
        isResolved: false,
    };
    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = (value) => {
            const resolved = deferred;
            resolved.isFulfilled = true;
            resolved.isRejected = false;
            resolved.isResolved = true;
            resolved.value = value;
            resolve(value);
        };
        deferred.reject = (reason) => {
            const rejected = deferred;
            rejected.isFulfilled = true;
            rejected.isRejected = true;
            rejected.isResolved = false;
            rejected.err = reason;
            reject(reason);
        };
    });
    return deferred;
}
exports.createDeferred = createDeferred;
function CacheDecoratorFactory(cache) {
    return function CacheDecorator() {
        return function (_, propertyKey, descriptor) {
            const method = descriptor.value;
            descriptor.value = function () {
                if (!cache.has(propertyKey)) {
                    cache.set(propertyKey, method.apply(this));
                }
                return cache.get(propertyKey);
            };
        };
    };
}
exports.CacheDecoratorFactory = CacheDecoratorFactory;
class UnreachableCaseError extends Error {
    constructor(val) {
        super(`Unreachable case: ${JSON.stringify(val)}`);
    }
}
exports.UnreachableCaseError = UnreachableCaseError;
class DebounceByElement {
    constructor(wait) {
        this.wait = wait;
        this.timers = new Map();
    }
    run(el, cb) {
        if (this.timers.has(el)) {
            clearTimeout(this.timers.get(el));
        }
        this.timers.set(el, setTimeout(() => {
            cb();
            this.timers.delete(el);
        }, this.wait));
    }
}
exports.DebounceByElement = DebounceByElement;
function getXPath(el) {
    let nodeElem = el;
    if (nodeElem instanceof Element && nodeElem.id) {
        return `//*[@id="` + nodeElem.id + `"]`;
    }
    const parts = [];
    while (nodeElem && nodeElem instanceof Element) {
        const currEl = nodeElem;
        let nbOfPreviousSiblings = 0;
        let hasNextSiblings = false;
        let sibling = currEl.previousSibling;
        while (sibling) {
            if (sibling.nodeType !== Node.DOCUMENT_TYPE_NODE &&
                sibling.nodeName === currEl.nodeName) {
                nbOfPreviousSiblings++;
            }
            sibling = sibling.previousSibling;
        }
        sibling = currEl.nextSibling;
        while (sibling) {
            if (sibling.nodeName === currEl.nodeName) {
                hasNextSiblings = true;
                break;
            }
            sibling = sibling.nextSibling;
        }
        const prefix = currEl.prefix ? currEl.prefix + ':' : '';
        const nth = nbOfPreviousSiblings || hasNextSiblings
            ? `[${nbOfPreviousSiblings + 1}]`
            : '';
        parts.push(prefix + currEl.localName + nth);
        nodeElem = currEl.parentNode;
    }
    return parts.length ? '/' + parts.reverse().join('/') : '';
}
exports.getXPath = getXPath;
function getElByXPath(xpath) {
    var _a;
    return (_a = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue) !== null && _a !== void 0 ? _a : undefined;
}
exports.getElByXPath = getElByXPath;

import { AppSumoContext } from "contexts/AppSumoContext";
import { SessionContext } from "contexts/SessionContext";
import { useContext } from "react";

const useImageGenerationQuantity = () => {

    const { isSumoling } = useContext(AppSumoContext);  
    const { user } = useContext(SessionContext);

    let qty = 1;

    if (user?.subscription?.items?.[0]?.product?.name == "essential") qty = 1;
    if (user?.subscription?.items?.[0]?.product?.name == "pro") qty = 4;
    if (user?.subscription?.items?.[0]?.product?.name == "team") qty = 4;
    if (user?.subscription?.items?.[0]?.product?.name == "agency") qty = 4;

    return 4;
}

export default useImageGenerationQuantity;
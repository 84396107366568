import { Button, Input } from "components/ui";
import InputModern from "components/ui/Input/InputModern";
import { CompanyContext } from "contexts/CompanyContext";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineAdjustments, HiOutlinePhotograph, HiOutlineSearch, HiOutlineVideoCamera } from "react-icons/hi";

import usePrompts from "utils/hooks/usePrompts";
import FilterBadge from "./FilterBadge";
import MediaElement from "./MediaElement";
import { Loading } from "components/shared";
import { AIContext } from "contexts/AIContext";

const FollowrExploreCopy = ({ mediaContainerRef }) => {

    const perPage = 1;
    
    const [isLoading, setIsLoading] = useState(false);
    const [media, setMedia] = useState(localStorage.getItem(`exploreCopy`) ? JSON.parse(localStorage.getItem(`exploreCopy`)) : []);
    const [query, setQuery] = useState("");
    const [firstRender, setFirstRender] = useState(true);
    const [previousMessages, setPreviousMessages] = useState([]);
    const {company} = useContext(CompanyContext);
    const { getPrompts } = usePrompts();
    const { getByQueryChat } = useContext(AIContext);
    
    const get = async (replace, isFree = false) => {
        if (replace) {
            setMedia([]);
            setPreviousMessages([]);
        }
        setIsLoading(true);

        const prompts = await getPrompts(query, isFree ? 1 : perPage);

        Promise.all(
            prompts.map((prompt, key) => getByQueryChat(prompt.prompt, !isFree))
        ).then((responses) => {
            const newMedia = responses.filter(e => e != "").map((item, key) => { return { text: item, ...prompts[key], isFree: isFree }; });
            if (newMedia?.length > 0) {
                setMedia(replace ? newMedia : [...media, ...newMedia]);
            }
            if (isFree) {
                localStorage.setItem('exploreCopyLastTimestampFree', new Date().getTime());
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (
            firstRender && 
            media && 
            media?.length > 0 && 
            localStorage.getItem('exploreCopy')
        ) {
            setFirstRender(false);
            return;
        }
        if (parseInt(localStorage.getItem('exploreCopyLastTimestampFree')) + 24 * 3600 * 1000 > new Date().getTime()) {
            return;
        }
        get(true, true);
    }, []);

    useEffect(() => {
        if (media && media?.length > 0) {
            localStorage.setItem('exploreCopy', JSON.stringify(media))
        }
    }, [media])
    
    return (
        <>
            <div className='w-auto mb-0 flex flex-col gap-2 items-center overflow-hidden'>
                <InputModern label="Write a topic" textArea value={query} onKeyDown={(e) => e.key == 'Enter' ? get(true) : void(0)} onChange={(e) => setQuery(e.target.value)} size="sm" placeholder="What do you want to write about?" className="focus-within:!ring-0 !rounded-xl !text-sm !h-[80px] !min-h-[80px]" />
                <div className="flex items-center gap-2 w-full">
                    <Button
                        variant="twoTone"
                        icon={<i className="las la-redo-alt" />}
                        className="!w-full !h-10 !text-xs !py-0 !rounded-xl !px-0 flex-1 flex-grow grow"
                        onClick={() => get(true)}
                        disabled={isLoading}
                    >
                        {isLoading ? <Loading type={`element`} /> : <>&nbsp;Refresh</>}
                    </Button>
                    <Button
                        variant="solid"
                        icon={<i className="las la-robot" />}
                        className="bg-primary-600 !text-gray-100 dark:!bg-primary-700 dark:!text-gray-200 !w-full !h-10 !text-xs !py-0 !rounded-xl !px-0 flex-1 flex-grow grow"
                        onClick={() => get(true)}
                        disabled={isLoading}
                    >
                        {isLoading ? <Loading type={`element`} /> : <>&nbsp;Generate</>}
                    </Button>
                </div>
            </div>
            {isLoading && 
                <>
                    <Loading type={`element`} />
                    <p className="-mt-2 text-xs text-center">Wait until we finish casting the spell.</p>
                </>
            }
            {media?.length > 0 && 
                <div className='grid grid-cols-1 gap-3'>
                    {media.map((item, key) => (
                        <MediaElement key={key} text={item.text} networks={item.networks} isFree={item.isFree} isDraggable={true} isStock={true} />
                    ))}
                    <Button onClick={() => get(false)} className="mt-4" variant="twoTone" disabled={isLoading}>{isLoading ? <Loading type={`element`} /> : `Generate more`}</Button>
                    <a href="#" className="text-center -mt-1 !text-xs" onClick={() => mediaContainerRef.current.scroll({top: 0, left: 0, behavior: 'smooth' })}>Back to top</a>
                </div>
            }
            {media?.length == 0 && !isLoading && (
                <p className='!text-xs text-gray-400 dark:text-gray-600 text-center'>No items for this search.<br />Try a different query.</p>
            )}
        </>
    )
}

export default FollowrExploreCopy;
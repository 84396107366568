import React, { useContext, useEffect, useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { Button, Dialog } from "components/ui";
import ParticleBackground from 'react-particle-backgrounds'
import { particleBackgroundSettings } from 'configs/particle-background.config';
import { HiChevronRight, HiOutlineCheckCircle } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { SessionContext } from "contexts/SessionContext";
import { CompanyContext } from "contexts/CompanyContext";

import { useSelector } from "react-redux";
import { PriorityModalContext } from "contexts/PriorityModalContext";
import { AIContext } from "contexts/AIContext";
import { Trans } from "react-i18next";
import { TbCircleCheckFilled } from "react-icons/tb";
import { ModalContext } from "contexts/ModalContext";

const CardDialog = ({ 
    cardDialogOpened, 
    setCardDialogOpened, 
    handleSubmitStripe, 
    isSubmittingCard, 
    subscriptionCreated, 
    className = "", 
    isPlus = false,
    handleStripeCheckout = undefined 
}) => {

    const freeTrialdEndsAt = new Date().getTime() + 1000 * 3600 * 24 * parseInt(process.env.REACT_APP_FREE_TRIAL_DAYS);
    const { user } = useContext(SessionContext);
    const { company } = useContext(CompanyContext);
    const [ congratulationsMessage, setCongratulationsMessage ] = useState("");
    const { getByQueryChat } = useContext(AIContext);
    const { closeModal } = useContext(ModalContext);
    const { closeModalPriority } = useContext(PriorityModalContext);
    const locale = useSelector((state) => state.locale.currentLang);

    useEffect(() => {
        if (cardDialogOpened && !localStorage.getItem(`subscriptionCongratulationMessage${locale}${company?.id}`)) {
            getByQueryChat(
                `Followr a social media management platform that uses AI technology to schedule, analyze, and generate social media posts.\n\n Write a congratulations message to ${user?.name}, a ${company?.name} employee, for successfully suscribing to Followr${isPlus ? ` Plus: a Followr product to enhance content generation` : ``}. Write as if you were the system. Use a humorous style. ${company?.description}. It must have less than 20 words. No emojis. Write in language: ${locale}`,
                false
            ).then((response) => {
                localStorage.setItem(`subscriptionCongratulationMessage${locale}${company?.id}`, response);
                setCongratulationsMessage(response);
            })
        } else {
            setCongratulationsMessage(localStorage.getItem(`subscriptionCongratulationMessage${locale}${company?.id}`))
        }
    }, [cardDialogOpened, locale, company, user])

    return (
        <Dialog
            isOpen={cardDialogOpened}
            onClose={() => setCardDialogOpened(false)}
            onRequestClose={() => setCardDialogOpened(false)}
            overlayClassName={`flex items-center justify-center w-full ${className} z-[20000000]`}
            contentClassName='relative !overflow-visible !overflow-y-visible px-6 lg:px-8'
            width={600}
        >
            <h4 className="mb-2 mega-title text-3xl mt-2">You are only one step away from your social media growth 🚀</h4>
            <h6 className="text-gray-500 dark:text-gray-300 mb-6 font-normal">Add a credit or debit card.</h6>
            {!user?.subscription && !subscriptionCreated && <p className='mt-2 w-full block !text-sm mb-2'>
                {!!parseInt(process.env.REACT_APP_FREE_TRIAL_DAYS) && <>You won't be charged until {new Date(freeTrialdEndsAt).toLocaleDateString()}.<br /></>}
                You can cancel at any time.
            </p>}
            <form onSubmit={handleSubmitStripe}>
                <div className='py-8 mb-4 mt-4 px-4 rounded-2xl shadow-[0_0_30px_#0000002a]'>
                    <CardElement options={{ 
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            }
                        }
                    }} />
                </div>
                {!!handleStripeCheckout && !subscriptionCreated &&
                    <div className="flex flex-col items-center justify-center mb-6 gap-2.5">
                        <span className="font-semibold text-gray-600">Or</span>
                        <div
                            className="!bg-[#6B71E3] hover:!brightness-[1.1] !text-white inline-block text-sm !p-6 !py-3 rounded-2xl cursor-pointer flex items-center justify-center gap-2"
                            onClick={handleStripeCheckout}
                        >
                            <img src="/img/others/stripe-s.webp" className="h-4 invert" />
                            <span>Checkout with <b>Stripe</b></span>
                        </div>
                    </div>
                }
                <hr className="-mx-6 lg:-mx-8" />
                <div className='flex flex-col lg:flex-row items-center justify-between mt-4 gap-8 -mb-2'>
                    <img src="/img/others/powered-by-stripe.png" className='h-7' />
                    <div className="flex gap-2 items-center">
                        <Button variant="plain" onClick={() => setCardDialogOpened(false)}>Close</Button>
                        <Button loading={isSubmittingCard} type="submit" variant="solid" size="md" className="flex justify-between items-center !px-4 gap-3 w-auto !text-sm">
                            {!!parseInt(process.env.REACT_APP_FREE_TRIAL_DAYS) && !user?.subscription 
                                ? <span><Trans i18nKey={`subscription.startFreeTrial`}>Start {{ days: process.env.REACT_APP_FREE_TRIAL_DAYS }}-Day Free Trial</Trans></span>
                                : <span>Confirm</span>
                            }
                            <HiChevronRight />
                        </Button>
                    </div>
                </div>
                {/* {!subscriptionCreated && <img src="/img/others/behind-wall-top.png" className='absolute hidden lg:inline-block top-0 translate-y-[-72%] -left-[7.5%] w-[45%] drop-shadow-xl' />} */}
            </form>

            <div className={`absolute left-0 top-0 w-full h-full bg-gradient-primary z-10 rounded-2xl px-6 transition-all duration-500 pointer-events-none opacity-0 ${subscriptionCreated && `!opacity-100 !pointer-events-auto`}`}>
                <ParticleBackground settings={particleBackgroundSettings} className="absolute pointer-events-none -ml-6" />
                <div className='relative z-[1] w-full h-full flex flex-col gap-8 items-center justify-center'>
                    <TbCircleCheckFilled className='text-8xl !text-green-600' />
                    <h4 className='text-white text-center'>{congratulationsMessage}</h4>
                    <Link to={`/home`} onClick={() => { closeModalPriority(); closeModal() }}>
                        <Button variant="twoTone">Start Creating</Button>
                    </Link>
                </div>
                <img src="/img/others/behind-wall-top.png" className='absolute top-0 translate-y-[-71.5%] left-[10%] w-[55%] drop-shadow-xl' />
            </div>
        </Dialog>
    )
}

export default CardDialog;
import { CompanyContext } from "contexts/CompanyContext";
import { SessionContext } from "contexts/SessionContext"
import { useContext } from "react"
import getImageDimensions from "utils/getImageDimensions";
import useEditorCallback from "./useEditorCallback";
import axios from "axios";
import { dispatch } from "use-bus";
import { UploadContext } from "contexts/UploadContext";
import { VISTA_CREATE_API_KEY } from "components/ui/utils/constant";

export const VISTA_CDN = "https://cdn.create.vista.com";

export const designTypes = [
  {
    designType: "socialMediaSM",
    type: "image",
    name: "Square Post",
    size: "800px x 800px",
    width: 800,
    height: 800,
    network: ""
  }, {
    designType: "animatedPostAN",
    type: "video",
    name: "Square Video Post",
    size: "1080px x 1080px",
    width: 1080,
    height: 1080,
    network: ""
  }, {
    designType: "facebookSM",
    type: "image",
    name: "Facebook Post",
    size: "940px x 788px",
    width: 940,
    height: 788,
    network: "facebook"
  }, {
    designType: "facebookReelAN",
    fixedDesignType: "tikTokVideoAN", // Since there are no templates for this media type, we enter
    type: "video",
    name: "Facebook Video Reel", 
    size: "1080px x 1920px",
    width: 1080,
    height: 1920,
    network: "facebook"
  }, {
    designType: "instagramSM",
    type: "image",
    name: "Instagram Square Post",
    size: "1080px x 1080px",
    width: 1080,
    height: 1080,
    network: "instagram"
  }, {
    designType: "instagramPostVerticalSM",
    type: "image",
    name: "Instagram Vertical Post",
    size: "1080px x 1350px",
    width: 1080,
    height: 1350,
    network: "instagram"
  }, {
    designType: "instagramStorySM",
    type: "image",
    name: "Instagram Story",
    size: "1080px x 1920px",
    width: 1080,
    height: 1920,
    network: "instagram"
  }, {
    designType: "instagramReelAN",
    fixedDesignType: "tikTokVideoAN", // Since there are no templates for this media type, we enter
    type: "video",
    name: "Instagram Reel",
    size: "1079px x 1920px",
    width: 1079,
    height: 1920,
    network: "instagram"
  }, {
    designType: "linkedInPostSM",
    type: "image",
    name: "LinkedIn Post",
    size: "1200px x 1200px",
    width: 1200,
    height: 1200,
    network: "linkedin"
  }, {
    designType: "pinterestSM",
    type: "image",
    name: "Pinterest Post",
    size: "1000px x 1500px",
    width: 1000,
    height: 1500,
    network: "pinterest"
  }, {
    designType: "twitterSM",
    type: "image",
    name: "Twitter Post",
    size: "1020px x 512px",
    width: 1020,
    height: 512,
    network: "twitter"
  }, {
    designType: "tikTokVideoAN",
    type: "video",
    name: "TikTok Video",
    size: "1080px x 1920px",
    width: 1080,
    height: 1920,
    network: "tiktok"
  },
];

export const designTypesPerNetwork = {
  "facebook": "facebookSM",
  "instagram": "instagramSM",
  "instagram_story": "instagramStorySM",
  "linkedin": "linkedInPostSM",
  "pinterest": "pinterestSM",
  "twitter": "twitterSM",
  "tiktok": "instagramStorySM",
}

export const designTypePerAspect = {
  "square": "socialMediaSM",
  "horizontal": "twitterSM",
  "vertical": "instagramPostVerticalSM"
}

const _getDesignTypePerAspect = async (image) => {
  let { width, height } = await getImageDimensions(image);

  const aspectRatio = width / height;

  let closestDesignType = designTypes[0];
  let closestDifference = Math.abs((designTypes[0].width / designTypes[0].height) - aspectRatio);

  designTypes.forEach((designType) => {
    const designAspectRatio = designType.width / designType.height;
    const difference = Math.abs(designAspectRatio - aspectRatio);

    if (difference < closestDifference) {
      closestDesignType = designType;
      closestDifference = difference;
    }
  });

  return closestDesignType.designType;
}

export const useVistaCreate = () => {

  const { user } = useContext(SessionContext);
  const { company } = useContext(CompanyContext);
  const { urlToBase64 } = useContext(UploadContext);
  const { onPublish } = useEditorCallback();
  
  const _formatExportResponse = async (exportResponse) => {
    // try {
    //   const base64 = await urlToBase64(exportResponse?.url);
    //   if (base64) {
    //     exportResponse.url = base64;
    //   }
    // } catch (err) {
    //   console.error(err);
    // }
    return exportResponse;
  }

  const initEditor = async (
    asset = undefined, 
    uri = undefined, 
    uriThumbnail = undefined, 
    handleOnPublish = undefined,
    designType = undefined,
    network = undefined,
    templateId = undefined,
    setIsLoading = undefined
  ) => {

    if (!!user?.balance?.postGroups_allowed && user?.balance?.postGroups_allowed <= user?.balance?.postGroups_spent) {
      dispatch({ type: 'subscriptionAddOnModal', payload: { entity: "designs" } });
      return null;
    }

    const image = asset?.image?.url || uri;
    const thumbnail = asset?.image?.thumbnail?.url || uriThumbnail;
    let designId = asset?.vista_project_id;

    if (designId == "undefined") {
      designId = undefined;
    }

    if (!!image && !designType && !designId) {
      designType = await _getDesignTypePerAspect(image || thumbnail);
    }

    if (!designType && network) {
      designType = designTypesPerNetwork?.[network];
    }

    const api = await window.CrelloEditor.init({
      apiKey: VISTA_CREATE_API_KEY,
      designType: designType ? designType : undefined,
      designId: designId ? designId : undefined,
      templateId: templateId ? templateId : undefined,
      downloadFormat: designTypes?.find(dt => dt?.designType == designType)?.type == "video" ? "mp4" : "jpg",
      lockPages: true,
      onPublishAction: async (data) => {
        setIsLoading && setIsLoading?.(true);
        data = await _formatExportResponse(data);
        handleOnPublish ? handleOnPublish(data) : onPublish(data, asset)
      },
      user: { externalUserId: `${company?.id}` }
    });

    if (image && !designId) {
      await api.addBackgroundImage({ image: image });
    }
  }

  const replaceAndExport = async (
    customTemplateId,
    textReplacements = undefined,
    imageReplacements = undefined,
    replaceBackgroundColor = undefined
  ) => {

    const api = await window.CrelloEditor.init({
      apiKey: VISTA_CREATE_API_KEY,
      customTemplateId: customTemplateId,
      hideEditorOnInit: true,
      user: { externalUserId: `${company?.id}` },
      exportQuality: 1
    });

    if (!!textReplacements?.length) {
      await api.textReplace({
        replacements: textReplacements
      })
    } 

    if (!!imageReplacements?.length) {
      await api.imageReplace({
        replacements: imageReplacements
      })
    } 
    
    if (company?.palettes?.[0] && replaceBackgroundColor) {
      await api.setBackgroundColor({
        colors: [{
          value: company?.palettes?.[0] || "#000000",
          page: 1
        }]
      })
    } 

    const exportDesignResult = await api.exportDesign({
      format: "jpg"
    });

    try {
      api.close();
    } catch (err) {}

    return await _formatExportResponse(exportDesignResult);
  }

  const resizeByNetwork = async (
    network,
    asset
  ) => {

    // if (!!user?.balance?.postGroups_allowed && user?.balance?.postGroups_allowed <= user?.balance?.postGroups_spent) {
    //   dispatch({ type: 'subscriptionAddOnModal', payload: { entity: "designs" } });
    //   return null;
    // }

    let designId = asset?.vista_project_id;
    if (designId == "undefined") {
      designId = undefined;
    }

    const api = await window.CrelloEditor.init({
      apiKey: VISTA_CREATE_API_KEY,
      designType: designTypesPerNetwork?.[network],
      designIdToCopy: designId || undefined,
      hideEditorOnInit: true,
      user: { externalUserId: `${company?.id}` }
    });

    if (!designId) {
      await api.addBackgroundImage({
        image: asset?.image?.url
      });
    }

    const exportDesignResult = await api.exportDesign({
      format: "jpg"
    });

    return await _formatExportResponse(exportDesignResult);
  }

  const resizeByDesignType = async (
    designType,
    asset
  ) => {

    const api = await window.CrelloEditor.init({
      apiKey: VISTA_CREATE_API_KEY,
      designType: designType,
      hideEditorOnInit: true,
      user: { externalUserId: `${company?.id}` }
    });

    await api.addBackgroundImage({
      image: asset?.image?.url
    });

    const exportDesignResult = await api.exportDesign({
      format: "jpg"
    });

    return await _formatExportResponse(exportDesignResult);
  }

  const duplicateByDesignId = async (
    vistaProjectId
  ) => {

    if (vistaProjectId == "undefined") {
      vistaProjectId = undefined;
    }

    const api = await window.CrelloEditor.init({
      apiKey: VISTA_CREATE_API_KEY,
      designIdToCopy: vistaProjectId,
      hideEditorOnInit: true,
      user: { externalUserId: `${company?.id}` }
    });

    const exportDesignResult = await api.exportDesign({
      format: "jpg"
    });

    return await _formatExportResponse(exportDesignResult);
  }

  const getMediaTemplates = async (designType = "", skip = 0) => {
    designType = designTypes.find(e => e.designType == designType)?.fixedDesignType || designType || undefined;
    return await axios.get(`https://create.vista.com/api/partner-api/templates?partnerTemplateType=template&apiKey=${VISTA_CREATE_API_KEY}${designType ? `&formatKey=${designType}` : ``}${skip ? `&skip=${skip}` : ``}`).then(({data}) => data);
  }

  const getCustomMediaTemplates = async (designType = "", limit = 100, skip = 0) => {
    return await axios.get(`https://create.vista.com/api/partner-api/templates?partnerTemplateType=customTemplate&apiKey=${VISTA_CREATE_API_KEY}${designType ? `&formatKey=${designType}` : ``}${skip ? `&skip=${skip}` : ``}${limit ? `&limit=${limit}` : ``}`).then(({data}) => data);
  }

  return { 
    replaceAndExport, 
    resizeByNetwork, 
    initEditor, 
    duplicateByDesignId, 
    resizeByDesignType,
    getMediaTemplates,
    getCustomMediaTemplates,
  }
}

export default useVistaCreate;